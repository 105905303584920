import React from 'react';
import ChildLabour from './ChildLabour';
import VulnerabilityToChildLabor from './vulnerabilityToChildLabor';
import VulnerabilityDimensions from './vulnerabilityDimensions';

import ComplementaryFactors from './ComplementaryFactors';
import { useLanguage } from '../../../../context/Languages';
import { useHistory, useLocation } from "react-router-dom";


import { Redirect, Link } from 'react-router-dom';

const CostaRica = () => {
	let location = useLocation();
	const { language } = useLanguage();
	let history = useHistory();
	// const [tab, setTab] = useState('');

	const clickHandler = (path) => {
		history.push(`/costa_rica/${path}`);
	}
	return (
		<div className="country">
			<div className=" row nav container">
					<div className="col-12 col-md-2 col-sm-4 text-dark left">
						<Link to="/" className="back-button">
							<i className="fas fa-arrow-left"></i> &nbsp;
							{language?.text.nav.back_main}
						</Link>
					</div>
					<div className="col-12 col-md-10 right mt-3 mt-md-0">
						<h3 className="title text-left text-md-right">
							{language?.text.model.IVTI}
						</h3>
					</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-12 tabs">
						<div className="row">
							<div className="col-sm-12 col-md-12 col-lg-2 mb-md-2 tab-left text-center text-md-left">
								<span className="title">Costa Rica</span>
							</div>
							<div className="col-sm-12 col-lg-10 tab-right">
								<ul
									className="d-none d-sm-flex nav nav-tabs"
									id="myTab"
									role="tablist"
								>
									{/* <li className="nav-item">
										<a
											onClick={ ()=>clickHandler('child-labour')}
											className={[
												'nav-link',
												location.pathname.split("/")[2] === "child-labour" ? 'active' : '',
											].join(' ')}
											id="child_labour_tab"
											data-toggle="tab"
											href="#child_labour"
											role="tab"
											aria-controls="child_labour"
											aria-selected="false"
										>
											{language?.text.tab.mexico.country[0]}
										</a>
									</li> */}
									<li className="nav-item">
										<a
											onClick={ ()=>clickHandler('vulnerability-child-labour')}
											className={[
												'nav-link',
												location.pathname.split("/")[2] === "vulnerability-child-labour" ? 'active' : '',
											].join(' ')}
											id="vulnerability-child-labour-tab"
											data-toggle="tab"
											href="#pro-trabajo-infantil"
											role="tab"
											aria-controls="vulnerability-child-labour"
											aria-selected="true"
										>
											{language?.text.tab.costa_rica.country[1]}
										</a>
									</li>
									{/* <li className="nav-item">
										<a
											onClick={ ()=>clickHandler('vulnerability-dimension')}
											className={[
												'nav-link',
												location.pathname.split("/")[2] === "vulnerability-dimension" ? 'active' : ''
											].join(' ')}
											id="vulnerability-dimension-tab"
											data-toggle="tab"
											href="#vulnerability-dimension"
											role="tab"
											aria-controls="vulnerability-dimension"
											aria-selected="false"
										>
											{language?.text.tab.costa_rica.country[2]}
										</a>
									</li> */}
									{/* <li className="nav-item">
										<a
											onClick={ ()=>clickHandler('complementary-factors')}
											className={[
												'nav-link',
												location.pathname.split("/")[2] === "complementary-factors" ? 'active' : '',
											].join(' ')}
											id="complementary-factors-tab"
											data-toggle="tab"
											href="#complementary-factors"
											role="tab"
											aria-controls="complementary-factors"
											aria-selected="false"
										>
											{language?.text.tab.costa_rica.country[3]}
										</a>
									</li> */}
								</ul>
								<div className="d-block d-sm-none btn-group btn-group-oit">
									<button
										type="button"
										className="btn btn-secondary dropdown-toggle w-100"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										Opciones
									</button>
									<div className="dropdown-menu">
										{/* <a
											onClick={ ()=>clickHandler('child-labour')}
											className={["dropdown-item",location.pathname.split("/")[2] === "child-labour" ? 'active' : '' ].join(" ")}
											id="child_labour_tab"
											data-toggle="tab"
											href="#child_labour"
											role="tab"
											aria-controls="child_labour"
											aria-selected="false"
										>
											{language?.text.tab.costa_rica.country[0]}
										</a> */}
										<a	onClick={ ()=>clickHandler('vulnerability-child-labour')}
											className={["dropdown-item",location.pathname.split("/")[2] === "vulnerability-child-labour" ? 'active' : '' ].join(" ")}
											id="vulnerability-child-labour-tab"
											data-toggle="tab"
											href="#vulnerability-child-labour-tab"
											role="tab"
											aria-controls="vulnerability-child-labour"
											aria-selected="true"
										>
											{language?.text.tab.costa_rica.country[1]}
										</a>
										<a	
											onClick={ ()=>clickHandler('vulnerability-dimension')}
											className={["dropdown-item",location.pathname.split("/")[2] === "vulnerability-dimension" ? 'active' : '' ].join(" ")}
											id="vulnerability-dimension-tab"
											data-toggle="tab"
											href="#factores-riesgo"
											role="tab"
											aria-controls="vulnerability-dimension"
											aria-selected="false"
										>
											{language?.text.tab.costa_rica.country[2]}
										</a>
										{/* <a
											onClick={ ()=>clickHandler('complementary-factors')}
											className={["dropdown-item",location.pathname.split("/")[2] === "complementary-factors" ? 'active' : '' ].join(" ")}
											id="complementary-factors-tab"
											data-toggle="tab"
											href="#complementary-factors"
											role="tab"
											aria-controls="complementary-factors"
											aria-selected="false"
										>
											{language?.text.tab.costa_rica.country[3]}
										</a> */}
									
									</div>
								</div>
							</div>
						</div>

						<div className="tab-content content p-3 mt-5" id="myTabContent">
							{/* <div
								className={[
									'tab-pane fade',
									location.pathname.split("/")[2] === "child-labour" ? 'active show' : '',
								].join(' ')}
								id="child_labour"
								role=" tabpanel"
								aria-labelledby="child_labour_tab"
							>
								<ChildLabour />
							</div> */}
							<div
								className={[
									'tab-pane fade',
									location.pathname.split("/")[2] === "vulnerability-child-labour" ? 'active show' : '',
								].join(' ')}
								id="vulnerability-child-labour"
								role="tabpanel"
								aria-labelledby="vulnerability-child-labour-tab"
							>
								<VulnerabilityToChildLabor />
							</div>
							{/* <div
								className={[
									'tab-pane fade',
									location.pathname.split("/")[2] === "vulnerability-dimension" ? 'active show' : '',
								].join(' ')}
								id="vulnerability-dimension"
								role="tabpanel"
								aria-labelledby="vulnerability-dimension-tab"
							>
								<VulnerabilityDimensions />
							</div> */}
							{/* <div
								className={[
									'tab-pane fade',
									location.pathname.split("/")[2] === "complementary-factors" ? 'active show' : '',
								].join(' ')}
								id="complementary-factors"
								role="tabpanel"
								aria-labelledby="complementary-factors-tab"
							>
								<ComplementaryFactors />
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CostaRica;
