import React from 'react'
import styles from './loaderScreen.module.css'

export const LoaderScreen = () => {
    return (
        <div class={styles.LoaderContainer}>
            <div class={styles.Loader}>Cargando...</div>
        </div>
    )
}
