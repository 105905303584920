import { useState, useEffect } from 'react';
import client from '../api/client';

export const useHttp = (
	url,
	params,
	fetchMap = false,
	dependencies,
	isState = false
) => {
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState(null);

	useEffect(() => {
		setIsLoading(true);
		const fetchData = async () => {
			let responseMap;
			let map;
			try {
				const response = await client.get(url, {
					params: { ...params },
					headers: { 'Content-Type': 'application/json' },
				});
				// if no maps for request
				if (response && !fetchMap) {
					setIsLoading(false);
					setData(response.data);
					return;
				}
					// console.log("response", response)
				if (isState) {
					if (response.data[0].state) {
						responseMap = await fetch(
							response.data[0].state[0].map
						);
					} else if (response.data[0].province) {
						responseMap = await fetch(
							response.data[0].province[0].map
						);
					} else if (response.data[0].department) {
						responseMap = await fetch(
							response.data[0].department[0].map
						);
					} else if (response.data[0].region) {
						responseMap = await fetch(
							response.data[0].region[0].map
						);
					} else if (response.data[0].parish) {
						responseMap = await fetch(
							response.data[0].parish[0].map
						);
					}
					else if (response.data[0].department) {
						responseMap = await fetch(
							response.data[0].department[0].map
						);
					}
					else if (response.data[0].zones) {
						responseMap = await fetch(
							response.data[0].zones[0].map
						);
					}
				} else {
					if (response.data.map) {
						responseMap = await fetch(response.data.map);
					} else {
						responseMap = await fetch(
							response.data.config.tabs[params.tabcode].map
						);
					}
				}

				map = await responseMap.json();
				// set data
				if (map) setIsLoading(false);

				setData({ ...response.data, map: map });
			} catch (error) {
				setIsLoading(false);
			}
		};

		fetchData();
	}, dependencies);

	return [isLoading, data];
};
