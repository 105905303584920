import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useLanguage } from '../../../../context/Languages';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

// custon hooks
import { useHttp } from '../../../../hooks/http';

// helpers
import {
	rangeHelper,
	groupBullet,
} from '../../../mapsHelpers/costaRicaTooltipHelper/costaRicaTooltiphelper';

import SharedButtons from '../../../mapsHelpers/sharedbuttons/sharedButtons';

// css module
import styles from './costarica.module.css';
import MapsSpinner from '../../../mapsHelpers/mapsSpinner/mapsSpinner';
import MapsTextSkeleton from '../../../mapsHelpers/mapsTextSkeleton/mapsTextSkeleton';
import DownloadList from '../../../mapsHelpers/downloadList/downloadList';
import GroupList from '../../../mapsHelpers/groupList/groupList';

const ChildLabourLikelihood = () => {
	const { language } = useLanguage();
	let location = useLocation();
	const history = useHistory();
	const [isLoading, data] = useHttp(
		'get_tab_costa_rica/',
		{tabcode:'child_labour_vulnerable', lang:language?.lang},
		true,
		[language?.lang]
	);
	const [states, setStates] = useState([]);
	const [hoverStateId, setHoverStateId] = useState(null);
	// map ref
	const mapRef = useRef(null);
	// tooltip ref
	const tooltipRef = useRef(null);

	// match svg location with backend
	const setLocation = () => {
		let realLocations = [];
		if (data) {
			let states = data.structure.provinces;
			let cantones = data.structure.cantons;
			let { groups } = data.config.tabs.child_labour_vulnerable;
			let [
				provinceConfig,
				cantonConfig,
			] = data.config.tabs.child_labour_vulnerable.config;

			data.map.locations.map(location => {
				// find state
				let targetState = states.find(
					state => state.node === location.id
				);

				location['g'][0]['g'].map((cantonLocation, i) => {
					// find Canton
					let targetCanton = cantones.find(
						canton => canton.node === cantonLocation.id
					);
					if (targetCanton) {
						// set color
						location['g'][0]['g'][i].fill =
							groups[
								targetCanton.data.child_labour_vulnerable
									.group - 1
							].color;
					}
				});

				let color;
				// if macth
				if (targetState) {
					//info for province node
					let provinceInfoGroup =
						targetState.data.vulnerability_dimensions;

					// set color from db match
					//color = svgColors[group - 1].color;
					realLocations.push({
						...location,
						fill: '#fff',
						provinceInfoGroup,
						name: targetState.name,
						provinceConfig,
						cantonConfig,
					});
				}

				setStates(realLocations);
			});
		}
	};

	// set mouse event for tooltip, always tracking the mouse
	useEffect(() => {
		const mouseMove = mouse => {
			const tooltip = tooltipRef.current;
			const mapContainerPsition = mapRef.current.getBoundingClientRect();
			const { pageX, pageY } = mouse;
			if (!tooltip) {
				return;
			}
			let scrollTop =
				window.pageYOffset !== undefined
					? window.pageYOffset
					: (
							document.documentElement ||
							document.body.parentNode ||
							document.body
					  ).scrollTop;
			tooltip.style.top = `${
				pageY - mapContainerPsition.top - 180 - scrollTop
			}px`;
			tooltip.style.left = `${pageX - mapContainerPsition.left - 10}px`;
		};
		// for track the cursor
		document.addEventListener('mousemove', mouseMove);
		return () => {
			// remove cursdor event
			document.removeEventListener('mousemove', mouseMove);
		};
	}, []);

	const svhHoverHandler = (e, info) => {
		setHoverStateId([e.currentTarget.id, info]);
	};

	const svhblurHandler = e => {
		setHoverStateId(null);
	};
	//
	useEffect(() => {
		if (data) {
			setLocation();
		}
	}, [data]);

	// click
	const svgClickHandler = e => {
		history.push(
			`/costa_rica/${e.currentTarget.id}/vulnerability-child-labour`
		);
	};

	return (
		<div className="row">
			<div className="col-sm-7 left">
				<div ref={mapRef} className="mb-3">
					<TransitionGroup>
						{hoverStateId && (
							<CSSTransition
								in={hoverStateId}
								timeout={200}
								classNames="my-node"
							>
								<div
									className={styles.Tooltip}
									ref={tooltipRef}
								>
									<h4 className="mb-2">
										<strong>{hoverStateId[1].name}</strong>
									</h4>
									<p style={{ margin: '2px' }}>
										<small className="mb-2">
											{groupBullet(
												data?.config.tabs
													.child_labour_vulnerable
													.groups[0]
											)}{" "}
											{data?.config.tabs
													.child_labour_vulnerable
													.groups[0].text}
											{rangeHelper(
												hoverStateId[1].info[0].range
											)}
										</small>
									</p>
									<p style={{ margin: '2px' }}>
										<small className="mb-2">
											{groupBullet(
												data?.config.tabs
													.child_labour_vulnerable
													.groups[1]
											)} {" "}
											 {data?.config.tabs
													.child_labour_vulnerable
													.groups[1].text}
											{rangeHelper(
												hoverStateId[1].info[1].range
											)}
										</small>
									</p>
									<p style={{ margin: '2px' }}>
										<small className="mb-2">
											{groupBullet(
												data?.config.tabs
													.child_labour_vulnerable
													.groups[2]
											)}
											{" "}
											{
												data?.config.tabs
													.child_labour_vulnerable
													.groups[2].text
											}{rangeHelper(
												hoverStateId[1].info[0].range
											)}
										</small>
									</p>
								</div>
							</CSSTransition>
						)}
					</TransitionGroup>

					{isLoading ? (
						<MapsSpinner />
					) : (
						data?.map.viewBox && (
							<svg
								className={styles.FadeIn}
								xmlns="http://www.w3.org/2000/svg"
								viewBox={data.map.viewBox}
							>
								{states.map(location => {
									return (
										<g
											id={location.id}
											fill="white"
											key={location.id}
											style={{ cursor: 'pointer' }}
											onMouseLeave={svhblurHandler}
											onMouseEnter={e =>
												svhHoverHandler(e, {
													info:
														location.provinceInfoGroup,
													name: location.name,
												})
											}
											onClick={svgClickHandler}
										>
											<g id={location.g[1].id}>
												{location.g[1].paths.map(
													contornoProvincia => {
														return (
															<path
																key={
																	contornoProvincia.id
																}
																d={
																	contornoProvincia.d
																}
																stroke={
																	location
																		.provinceConfig
																		.stroke
																}
																strokeWidth={
																	location
																		.provinceConfig
																		.stroke_width
																}
																fill={'white'}
															/>
														);
													}
												)}
											</g>

											<g id={location.g[0].id}>
												{location.g[0].g.map(group => {
													return (
														<g
															key={group.id}
															id={group.id}
														>
															{group.paths?.map(
																path => {
																	return (
																		<path
																			key={
																				path.id
																			}
																			id={
																				path.id
																			}
																			d={
																				path.d
																			}
																			fill={
																				group.fill
																					? group.fill
																					: 'white'
																			}
																			stroke={
																				location
																					.cantonConfig
																					.stroke
																			}
																			strokeWidth={
																				location
																					.cantonConfig
																					.stroke_width
																			}
																		/>
																	);
																}
															)}
														</g>
													);
												})}
											</g>
										</g>
									);
								})}
							</svg>
						)
					)}
				</div>
			</div>
			<div className="col-sm-5 right">
				{isLoading ? (
					<MapsTextSkeleton title={true} count={6} />
				) : (
					<React.Fragment>
						<div
							className="mb-5 text-justify"
							dangerouslySetInnerHTML={{
								__html:
									data?.config.tabs.child_labour_vulnerable
										.description[0]
							}}
						></div>
						<GroupList
							groups={
								data?.config.tabs.child_labour_vulnerable.groups
							}
						/>
						<div
							className="mb-5 text-justify"
							dangerouslySetInnerHTML={{
								__html:
									data?.config.tabs.child_labour_vulnerable
										.description[1]
							}}
						></div>
						<div
							className="mb-5 text-justify"
							dangerouslySetInnerHTML={{
								__html:
									data?.config.tabs.child_labour_vulnerable
										.description[2]
							}}
						></div>

						<div className="more-detail">
							<div className="row w-100">
								<div className="col-12 p-0">
									<div class="d-table h-100">
										<div class="d-table-cell">
											<div class="share h-100">
												<p class="text">
													{
														language?.text.body
															.share_information
													}
												</p>
												<div class="oit-icon-group oit-icon-group-small icons-green">
													<SharedButtons
														url={`${location.pathname}`}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<DownloadList
							links={
								data?.config.tabs.child_labour_vulnerable
									.more_descriptions.links
							}
						/>
					</React.Fragment>
				)}
			</div>
		</div>
	);
};

export default ChildLabourLikelihood;
