import React from 'react';

function LikehoodMap({
	data,
	svhHoverHandler,
	svhblurHandler,
	svgClickHandler,
	states,
}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox={data.map.viewBox}>
			{states.map(location => {
				return (
					<g
						id={location.id}
						fill="white"
						key={location.id}
						// style={{ cursor: 'pointer' }}
						// onMouseLeave={svhblurHandler}
						// onMouseEnter={e =>
						// 	svhHoverHandler(e, {
						// 		info: location.provinceInfoGroup,
						// 		name: location.name,
						// 	})
						// }
						// onClick={svgClickHandler}
					>
						<g id={location.g[1].id}>
							{location.g[1].paths.map((contornoProvincia, i) => {
								return (
									<path
										key={i}
										d={contornoProvincia.d}
										stroke={location.provinceConfig.stroke}
										strokeWidth={
											location.provinceConfig.stroke_width
										}
										fill={'white'}
									/>
								);
							})}
						</g>

						<g id={location.g[0].id}>
							{location.g[0].g &&
								location.g[0].g.map((group, i) => {
									return (
										<g key={group.id + i} id={group.id + i}>
											{group.paths?.map((path, i) => {
												return (
													<path
														key={path.id + i}
														id={path.id}
														d={path.d}
														fill={
															group.fill
																? group.fill
																: 'white'
														}
														stroke={
															location
																.cantonConfig
																.stroke
														}
														strokeWidth={
															location
																.cantonConfig
																.stroke_width
														}
													/>
												);
											})}
										</g>
									);
								})}
						</g>
					</g>
				);
			})}
		</svg>
	);
}

export default React.memo(LikehoodMap, (propsPrev, nextProps) => {
	if (propsPrev.states.length === nextProps.states.length) {
		return true;
	}

	return false;
});
