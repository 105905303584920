import {useState, useEffect} from 'react'

export default function useStatematch(data, tabcode) {
    const [isLoading, setisLoading] = useState(false);
    const [positions, setpositions] = useState([]);
    useEffect(() => {

        setisLoading(true);

        const setLocation = () => {
            let realLocations = []; 
            if(data && !data[0].departments[0].data[tabcode]){
                return 
            }
            if (data && tabcode) {
                
                let municipalities = data[0].departments;
                const {
                    hover,
                    stroke,
                    stroke_width,
                } = data[0].province[0].tabs[tabcode];
                // colors from the data object
                let svgColors =
                    data[0].province[0].tabs[tabcode].groups;
                data.map.locations.map(location => {
                    let targetState = municipalities.find(
                        state => state.node === location.id
                    );
                    let color;
                    // if macth
                    if (targetState) {
                        //item information
                        
                        let {
                            group,
                            range,
                        } = targetState.data[tabcode];

                        if(targetState.data[tabcode].province){
                            group = targetState.data[tabcode].province.group;
                            range = targetState.data[tabcode].province.range;
                        }
    
                        // set color from db match
                        if (group) {
                            color = svgColors[group - 1].color;
                        }
                        realLocations.push({
                            ...location,
                            name: targetState.name,
                            hover,
                            range,
                            stroke,
                            stroke_width,
                            _id: targetState.id,
                            fill: color ? color : 'transparent',
                        });
                    } 
                    
                    setisLoading(false);
                    setpositions(realLocations);
                });
            }
        };
        
            setLocation()
            
    }, [data])
    return (positions);
}
