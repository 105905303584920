import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from './../../../../../context/Languages';
import SharedButtons from '../../../../mapsHelpers/sharedbuttons/sharedButtons';

// custon hooks
import { useHttp } from './../../../../../hooks/http';

//maps helpers
import DownloadList from '../../../../mapsHelpers/downloadList/downloadList';
import MapsTextSkeleton from '../../../../mapsHelpers/mapsTextSkeleton/mapsTextSkeleton';
import MapsSpinner from '../../../../mapsHelpers/mapsSpinner/mapsSpinner';

import MexicoFactorsGroups from './mexicoFactorsGroup';
// css module
import styles from './../mexico.module.css';
import './../../../../../index.css';

import './states.css';
import FactorsGroups from '../../../../mapsHelpers/complementaryFactorsGroups/FactorsGroups';

export default function ComplementaryFactors({ state }) {
	let location = useLocation();
	const { language } = useLanguage();
	const [municipalities, setMunicipalities] = useState([]);
	const [imageLoaded, setImageLoaded] = useState(false);
	const [isLoadingChildLabourLikehood, dataChildLabourLikehood] = useHttp(
		`get_tab_mexico/${state}`,
		{ tabcode: 'child_labour_likehood', lang: language?.lang },
		true,
		[state, language?.lang],
		true
	);
	const [isLoading, data] = useHttp(
		`get_tab_mexico/${state}`,
		{ tabcode: 'complementary_factors', lang: language?.lang },
		false,
		[state, language?.lang],
		true
	);

	// match svg location with backend
	const setLocation = () => {
		let realLocations = [];
		if (data) {
			let municipalities = dataChildLabourLikehood[0].municipalities;
			const { hover, stroke, stroke_width } =
				dataChildLabourLikehood[0].state[0].tabs
					.child_labour_likelihood;
			// colors from the data object
			let svgColors =
				dataChildLabourLikehood[0].state[0].tabs.child_labour_likelihood
					.groups;
			dataChildLabourLikehood.map.locations.map(location => {
				let targetState = municipalities.find(
					state => state.node === location.id
				);
				let color;
				// if macth
				if (targetState) {
					//item information
					const { group, range } =
						targetState.data.child_labour_likelihood;

					// set color from db match
					if (group) {
						color = svgColors[group - 1].color;
					}
					realLocations.push({
						...location,
						name: targetState.name,
						hover,
						range,
						stroke,
						stroke_width,
						_id: targetState.id,
						fill: color ? color : 'transparent',
					});
				}

				setMunicipalities(realLocations);
			});
		}
	};

	useEffect(() => {
		if (data) {
			setLocation();
		}
	}, [dataChildLabourLikehood]);

	return (
		<React.Fragment>
			<div class="row">
				{isLoading ? (
					<MapsTextSkeleton title={true} count={3} />
				) : (
					<div
						class="col-12 text-justify"
						dangerouslySetInnerHTML={{
							__html:
								data &&
								data[0].state[0].tabs.complementary_factors
									.description[0],
						}}
					></div>
				)}
			</div>
			<div className="row">
				<div className="col-sm-12 col-md-12 col-lg-6">
					<div
						className="map-card text-center"
						style={{
							height: '400px',
							maxWidth: '400px',
							margin: 'auto',
						}}
					>
						{!imageLoaded ? (
							<div className="mt-5 pt-2">
								<MapsSpinner />
							</div>
						) : (
							imageLoaded && (
								<p className="text-center">
									<strong>
										{data &&
											data[0].state[0].tabs
												.complementary_factors.title}
									</strong>
								</p>
							)
						)}
						<img
							onLoad={() => setImageLoaded(true)}
							style={{
								display: `${imageLoaded ? 'initial' : 'none'}`,
								paddingLeft: '11%',
								paddingRight: '11%',
								marginBottom: '35px',
							}}
							src={
								data &&
								data[0].state[0].tabs.complementary_factors
									.images[0]
							}
							className="w-100"
							alt="map"
						/>

						{imageLoaded && (
							<MexicoFactorsGroups
								groups={
									data &&
									data[0].state[0].tabs.complementary_factors
										.groups
								}
							/>
						)}
					</div>
				</div>
				<div className="col-sm-12 col-md-12 col-lg-6">
					<div
						className="map-card "
						style={{
							maxHeight: '400px',
							maxWidth: '400px',
							margin: 'auto',
							position: 'relative',
						}}
					>
						{isLoadingChildLabourLikehood ? (
							<div className="mt-5 pt-2">
								<MapsSpinner />
							</div>
						) : (
							<React.Fragment>
								<p className="text-center">
									{console.log('language', language)}
									<strong>
										{data &&
											data[0].state[0].tabs
												.complementary_factors
												.probability_title}
									</strong>
								</p>
								{dataChildLabourLikehood?.map.viewBox && (
									<svg
										className={styles.FadeIn}
										xmlns="http://www.w3.org/2000/svg"
										viewBox="-100 -50 1202.782 903"
									>
										{municipalities.map(location => {
											return (
												<g
													id={location.id}
													key={location.id}
													transform={
														location.transform
													}
												>
													{location.paths.map(
														(path, i) => (
															<path
																key={
																	path.id + i
																}
																id={path.id}
																stroke={
																	location.stroke
																}
																strokeWidth={
																	location.stroke_width
																}
																fill={
																	location.fill
																}
																d={path.d}
																transform={
																	path.transform
																}
															/>
														)
													)}
												</g>
											);
										})}
									</svg>
								)}
							</React.Fragment>
						)}
						<FactorsGroups
							groups={
								dataChildLabourLikehood &&
								dataChildLabourLikehood[0].state[0].tabs
									.child_labour_likelihood.groups
							}
						/>
					</div>
				</div>
			</div>

			<div class="row mt-3">
				<div class="col-12 col-lg-7">
					<div class="table-responsive">
						<table class="table oit-table">
							<thead>
								<tr>
									{data &&
										data[0].state[0].tabs.complementary_factors.table.headers.map(
											(header, index) => {
												return (
													<th
														key={
															header.description +
															index
														}
														style={{
															'--w': `${
																index === 0
																	? '80%'
																	: ''
															}`,
														}}
													>
														{header.description}{' '}
														<br />{' '}
														{header.value &&
															`(${header.value})`}
													</th>
												);
											}
										)}
								</tr>
							</thead>
							<tbody>
								{data &&
									data[0].state[0].tabs.complementary_factors.table.data.map(
										(dataField, index) => {
											return (
												<tr
													key={
														dataField.description +
														index
													}
												>
													<td>
														{dataField.description}
													</td>
													{dataField.values.map(
														(field, index) => {
															let number = Number(
																field.$numberDecimal,
																10
															);
															return (
																<td
																	key={
																		field.$numberDecimal +
																		index
																	}
																>
																	{Number.isInteger(
																		number
																	)
																		? field.$numberDecimal
																		: number.toFixed(
																				1
																		  )}
																</td>
															);
														}
													)}
												</tr>
											);
										}
									)}
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-12 col-lg-5">
					<div
						className="text-justify"
						dangerouslySetInnerHTML={{
							__html:
								data &&
								data[0].state[0].tabs.complementary_factors
									.description[1],
						}}
					></div>
					<div class="more-detail">
						<div class="row w-100">
							<div class="col-6 p-0"></div>
							<div class="col-6 p-0">
								<div class="d-table ml-auto h-100">
									<div class="d-table-cell">
										<div class="share h-100">
											<p class="text">
												{
													language?.text.body
														.share_information
												}
											</p>
											<div class="oit-icon-group oit-icon-group-small icons-green">
												<SharedButtons
													url={`${location.pathname}`}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<DownloadList
							links={
								data &&
								data[0].state[0].tabs.complementary_factors
									.more_descriptions.links
							}
							url={null}
						/>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
