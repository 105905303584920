import React, { useEffect } from 'react';
import { useGroups } from '../../../../context/Groups';

import {
	Switch,
	Route,
	useRouteMatch,
	useLocation,
	Redirect,
} from 'react-router-dom';
import Chile from '../chile';
import State from '../chile/cantones';

const RoutesCostaRica = () => {
	const { setCountry, selectedContinent } = useGroups();

	let { path } = useRouteMatch();
	let location = useLocation();

	useEffect(() => {
		setCountry(path.split('/')[1]);
	}, [selectedContinent]);

	useEffect(() => {
		return () => {
			setCountry(null);
		};
	}, []);

	return (
		<Switch>
			<Route path={`${path}/child-labour`}>
				<Chile />
			</Route>

			<Route path={`${path}/vulnerability-child-labour`}>
				<Chile />
			</Route>

			<Route path={`${path}/vulnerability-dimension`}>
				<Chile />
			</Route>

			<Route path={`${path}/complementary-factors`}>
				<Chile />
			</Route>

			<Route path={`${path}/:state/vulnerability-child-labour`}>
				<State />
			</Route>
			<Route path={`${path}/:state/vulnerability-dimension`}>
				<State />
			</Route>
			<Route path={`${path}/:state/complementary-factors`}>
				<State />
			</Route>
			<Route path={`${path}/:state`}>
				<Redirect
					to={`${path}/${
						location.pathname.split('/')[2]
					}/vulnerability-child-labour`}
				/>
			</Route>

			<Route path={`${path}`}>
				<Redirect to={`${path}/vulnerability-child-labour`} />
			</Route>

			<Route path="*">
				<h1>404</h1>
			</Route>
		</Switch>
	);
};

export default RoutesCostaRica;
