import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../../../../../context/Languages';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

// custon hooks
import { useHttp } from '../../../../../hooks/http';

import SharedButtons from '../../../../mapsHelpers/sharedbuttons/sharedButtons';
import MapsTextSkeleton from '../../../../mapsHelpers/mapsTextSkeleton/mapsTextSkeleton';

// css module
import styles from '../costarica.module.css';
import MapsSpinner from '../../../../mapsHelpers/mapsSpinner/mapsSpinner';
import DownloadList from '../../../../mapsHelpers/downloadList/downloadList';
import { rangeHelper } from '../../../../mapsHelpers/costaRicaTooltipHelper/costaRicaTooltiphelper';

const VulnerabilityToChildLabor = ({ state }) => {

	const { language } = useLanguage();
	let location = useLocation();
	const [isLoading, data] = useHttp(
		`get_tab_costa_rica/${state}`,
		{tabcode:'child_labour_vulnerable', lang:language?.lang},
		true,
		[state, language?.lang],
		true
	);
	const [municipalities, setMunicipalities] = useState([]);
	const [hoverStateId, setHoverStateId] = useState(null);

	// map ref
	const mapRef = useRef(null);
	// tooltip ref
	const tooltipRef = useRef(null);

	// match svg location with backend
	const setLocation = () => {
		let realLocations = [];
		if (data) {
			let municipalities = data[0].cantons;
			const {
				stroke,
				stroke_width,
			} = data[0].province[0].tabs.child_labour_vulnerable;
			// colors from the data object
			let svgColors =
				data[0].province[0].tabs.child_labour_vulnerable.groups;
			data.map.locations.map(location => {
				let targetState = municipalities.find(
					state => state.node === location.id
				);
				let color;
				// set element color
				if (targetState) {
					const {
						group,
						range,
					} = targetState.data.child_labour_vulnerable;

					// set color from db match
					color = svgColors[group - 1].color;
					realLocations.push({
						...location,
						name: targetState.name,
						hover: color,
						range,
						group,
						stroke,
						stroke_width,
						_id: targetState.id,
						fill: '#fff',
					});
				}

				setMunicipalities(realLocations);
			});
		}
	};

	// set mouse event for tooltip, always tracking the mouse
	useEffect(() => {
		const mouseMove = mouse => {
			const tooltip = tooltipRef.current;
			const mapContainerPsition = mapRef.current.getBoundingClientRect();
			const { pageX, pageY } = mouse;
			if (!tooltip) {
				return;
			}
			let scrollTop =
				window.pageYOffset !== undefined
					? window.pageYOffset
					: (
							document.documentElement ||
							document.body.parentNode ||
							document.body
					  ).scrollTop;
			tooltip.style.top = `${
				pageY - mapContainerPsition.top - 100 - scrollTop
			}px`;
			tooltip.style.left = `${pageX - mapContainerPsition.left - 10}px`;
		};
		// for track the cursor
		document.addEventListener('mousemove', mouseMove);
		return () => {
			// remove cursdor event
			document.removeEventListener('mousemove', mouseMove);
		};
	}, []);

	const svhHoverHandler = (e, info) => {
		setHoverStateId([e.currentTarget.id, info]);
	};

	const svhblurHandler = e => {
		setHoverStateId(null);
	};

	useEffect(() => {
		if (data) {
			setLocation();
		}
	}, [data]);

	return (
		<div class="row">
			<div class="col-sm-7 left">
				<div ref={mapRef}>
					<TransitionGroup>
						{hoverStateId && (
							<CSSTransition
								in={hoverStateId}
								timeout={200}
								classNames="my-node"
							>
								<div
									className={styles.Tooltip}
									ref={tooltipRef}
								>
									<h4 className="mb-0">
										<span
											className={styles.Indicador}
											style={{
												backgroundColor: `${hoverStateId[1].color}`,
											}}
										></span>
										{hoverStateId[1]?.name}
									</h4>
									{hoverStateId[1].range && (
										<p className="ml-3 mb-0">
											<small className="d-block ">
												{`${data[0].province[0].tabs.child_labour_vulnerable.groups[hoverStateId[1].group - 1].text} ${rangeHelper(
														hoverStateId[1].range
													)}`}
											</small>
										</p>
									)}
								</div>
							</CSSTransition>
						)}
					</TransitionGroup>
					{isLoading ? (
						<MapsSpinner />
					) : (
						data?.map.viewBox && (
							<svg
								className={styles.FadeIn}
								xmlns="http://www.w3.org/2000/svg"
								viewBox={data.map.viewBox}
							>
								{municipalities.map((location, i) => {
									return (
										<g
											id={location.id}
											key={location.id}
											style={{ cursor: 'pointer' }}
											transform={location.transform}
											onMouseLeave={svhblurHandler}
											onMouseEnter={e =>
												svhHoverHandler(e, {
													name: location.name,
													range: location.range,
													color: location.hover,
													group: location.group,
												})
											}
										>
											{location.paths.map((path, i) => (
												<path
													id={path.id + i}
													stroke={location.stroke}
													strokeWidth={
														location.stroke_width
													}
													fill={location.hover}
													d={path.d}
													transform={path.transform}
												/>
											))}
										</g>
									);
								})}
							</svg>
						)
					)}
				</div>
			</div>
			<div class="col-sm-5 right">
				{isLoading ? (
					<MapsTextSkeleton title={true} count={6} />
				) : (
					data && (
						<React.Fragment>
							<div
								className="mb-5"
								dangerouslySetInnerHTML={{
									__html:
										data[0].province[0].tabs
											.child_labour_vulnerable
											.description[0],
								}}
							></div>

							<ul>
								{data[0].province[0].tabs.child_labour_vulnerable.groups.map(
									(group, i) => {
										return (
											<li
												key={
													group.amount +
													i +
													group.color
												}
												style={{
													'--color': group.color,
												}}
											>
												{group.text} {group.amount}
											</li>
										);
									}
								)}
							</ul>
							<div
								className="mb-5"
								dangerouslySetInnerHTML={{
									__html:
										data[0].province[0].tabs
											.child_labour_vulnerable
											.description[1],
								}}
							></div>
							<div
								className="mb-5"
								dangerouslySetInnerHTML={{
									__html:
										data[0].province[0].tabs
											.child_labour_vulnerable
											.description[2],
								}}
							></div>

							<div className="more-detail">
								<div className="row w-100">
									<div className="col-6 ml-auto p-0">
										<div class="d-table  h-100">
											<div class="d-table-cell">
												<div class="share h-100">
													<p class="text">
														{
															language?.text.body
																.share_information
														}
													</p>
													<div class="oit-icon-group oit-icon-group-small icons-green">
														<SharedButtons
															url={`${location.pathname}`}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<DownloadList
								links={
									data &&
									data[0].province[0].tabs
										.child_labour_vulnerable
										.more_descriptions.links
								}
							/>
						</React.Fragment>
					)
				)}
			</div>
		</div>
	);
};

export default VulnerabilityToChildLabor;
