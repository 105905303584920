
import React from 'react'
import { Facebook, Twitter } from 'react-sharingbuttons';
import './sharedButtons.css'
import { useLanguage } from '../../../context/Languages';
import environment from '../../../environment';


 function sharedButtons({url}) {
   const {language} = useLanguage;
   
    return (
    <div className="react-sharing-button__container">
        <Facebook url={`${environment.apiUrl}share/${url}`} />
        <Twitter url={`${environment.apiUrl}share/${url}`} />
      </div>
    )
}

export default sharedButtons;
