import React, { useEffect, useState } from 'react';

export default function UseSvgMatch(data) {
	const [states, setStates] = useState([]);
	const setLocation = () => {
		let realLocations = [];
		if (data) {
			let states = data.structure.departments;
			let {
				hover,
				stroke,
				stroke_width,
			} = data.config.tabs.child_labour_likelihood;
			// colors from the data object
			let svgColors = data.config.tabs.child_labour_likelihood.groups;
			data.map.locations.map(location => {
				let targetState = states.find(
					state => state.node === location.id
				);
				let color;
				// if macth
				if (targetState) {
					//item information
					const {
						group,
						range,
					} = targetState.data.child_labour_likelihood;

					// set color from db match
					color = svgColors[group - 1]?.color;
					realLocations.push({
						...location,
						fill: color ? color : 'white',
						hover,
						range,
						stroke,
						stroke_width,
						name: targetState.name,
						status: targetState.status,
					});
				} else {
				}

				setStates(realLocations);
			});
		}
	};
	useEffect(() => {
		if (data) {
			setLocation();
		}
	}, [data]);

	return states;
}
