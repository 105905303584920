import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from './../../../../../context/Languages';
import SharedButtons from '../../../../mapsHelpers/sharedbuttons/sharedButtons';
// custon hooks
import { useHttp } from './../../../../../hooks/http';

//maps helpers
import DownloadList from '../../../../mapsHelpers/downloadList/downloadList';

// css module
import styles from './../mexico.module.css';
import './../../../../../index.css';

import './states.css';

export default function ComplementaryFactors({ state }) {
	const [imageLoaded, setImageLoaded] = useState(false);
	let location = useLocation();
	const { language } = useLanguage();
	const [isLoading, data] = useHttp(
		`get_tab_guatemala/${state}`,
		{tabcode:'complementary_factors', lang:language?.lang},
		false,
		[state, language?.lang],
		true
	);
// console.log("state data", data)
	return (
		<React.Fragment>
			<div className="row">
					<div
						className="col-12 text-justify"
						dangerouslySetInnerHTML={{
							__html:
								data &&
								data[0].region[0].tabs.complementary_factors
									.description[0],
						}}
					></div>
			</div>

			<div className="row mt-3">
				<div className="col-12 ">
					<div className="table-responsive">
						<table className="table oit-table">
							<thead>
								<tr>
									{data &&
										data[0].region[0].tabs.complementary_factors.table.headers.map(
											(header, index) => {
												
												return (
													<th
														key={
															header.description +
															index
														}
														colSpan={index === 0 ? 2 : 1}
													>
														{header.description}
														<br />
														{header.value &&
															`(${header.value})`}
													</th>
												);
											}
										)}
								</tr>
							</thead>
							<tbody>
							{ data &&
										data[0].region[0].tabs.complementary_factors.table.data.map(
									(dataField, index) => {
										return dataField.rows.map(
											(row, rowIndex) => {
												return (
													<tr
														key={`${dataField._id}-${rowIndex}`}
													>
														{rowIndex == 0 ? (
															<td
																style={{
																	verticalAlign:
																		'middle',
																}}
																rowSpan={
																	dataField
																		.rows
																		.length
																}
															>
																{
																	dataField.category
																}
															</td>
														) : null}
														<td style={{textAlign:'left'}}>
															{row.description}
														</td>
														
														{row.values.map(
															(field, i) => {
																console.log("field", field)
																return (
																	<td
																		key={`${rowIndex}-${i}`}
																	>
																		{field && parseFloat(field.$numberDecimal).toFixed(2)}
																	</td>
																);
															}
														)}
													</tr>
												);
											}
										);
									}
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div className="row">
					<div
						className="col-12 text-justify"
						dangerouslySetInnerHTML={{
							__html:
								data &&
								data[0].region[0].tabs.complementary_factors
									.description[2],
						}}
					></div>
			</div>
			<div className='row'>
			<div className="col-12 text-justify">
					<div className="more-detail">
						<div className="row w-100">
							<div className="col-6 p-0"></div>
							<div className="col-6 p-0">
								<div className="d-table ml-auto h-100">
									<div className="d-table-cell">
										<div className="share h-100">
											<p className="text">
												{
													language?.text.body
														.share_information
												}
											</p>
											<div className="oit-icon-group oit-icon-group-small icons-green">
												<SharedButtons
													url={`${location.pathname}`}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<DownloadList
							links={
								data &&
								data[0].region[0].tabs.complementary_factors
									.more_descriptions.links
							}
							url={null}
						/>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
