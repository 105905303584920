import React, { useState, useEffect } from 'react';

export default function useSvgMatch(data) {
	const [locations, setLocations] = useState([]);

	const setLocation = () => {
		let realLocations = [];
		if (data) {
			let states = data.structure.regions;
			let cantones = data.structure.communes;
			let { groups } = data.config.tabs.child_labour_vulnerable;
			let [provinceConfig, cantonConfig] =
				data.config.tabs.child_labour_vulnerable.config;

			data.map.locations.map(location => {
				// find state
				let targetState = states.find(
					state =>
						state.node === location.id ||
						state.node === location['data-name']
				);

				location['g'][0]['g'].map((cantonLocation, i) => {
					// find Canton
					let targetCanton = cantones.find(
						canton =>
							canton.node === cantonLocation.id ||
							canton.node === cantonLocation['data-name']
					);
					if (targetCanton) {
						// set color
						if (targetCanton.data.national_vulnerable.category) {
							location['g'][0]['g'][i].fill =
								groups[
									targetCanton.data.national_vulnerable
										.category - 1
								].color;
						} else {
							location['g'][0]['g'][i].fill = 'white';
						}
					}
				});

				let color;
				// if macth

				if (targetState) {
					//info for province node
					let provinceInfoGroup =
						targetState.data.child_labour_vulnerable;
					console.log('provinceInfoGroup', provinceInfoGroup);
					// set color from db match
					//color = svgColors[group - 1].color;
					realLocations.push({
						...location,
						fill: '#fff',
						name: targetState.name,
						provinceConfig,
						provinceInfoGroup,
						cantonConfig,
					});
				}

				setLocations(realLocations);
			});
		}
	};

	useEffect(() => {
		if (data) {
			setLocation();
		}
	}, [data]);

	return locations;
}
