import React, { Component } from 'react';
import { Link, useRouteMatch, NavLink } from 'react-router-dom';
//import LanguagesContext from '../../context/Languages';
import { useGroups } from '../../context/Groups';
import { useLanguage } from '../../context/Languages';
import { useParams, useLocation } from 'react-router-dom';

//const disabledContries = ['60ff43fc6ed7a2460abe375d', '60ff43fe6ed7a2460abe3d21'];
const disabledContries = [];

const Breadcrumb = () => {
	let location = useLocation();
	let country = location.pathname.split('/')[1];
	const {
		groups,
		countrystates,
		selectedCountry,
		selectedState,
		selectedContinent,
	} = useGroups();
	const { language } = useLanguage();

	const countrydata = groups?.rows[0].groups[0].countries.find(
		country => country.node === selectedCountry
	);

	return (
		<div className="">
			<div className=" col-12 breadcrumb-content">
				<button
					className="navbar-toggler d-block d-sm-none"
					type="button"
					data-toggle="collapse"
					data-target="#oit_breadcrumb"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon">
						<i className="fas fa-sliders-h"></i>
					</span>
				</button>

				<nav
					className="breadcrumb  pl-0 pr-0 collapse "
					id="oit_breadcrumb"
				>
					<ol className="breadcrumb pl-0 pr-0 pt-1 pb-1 bg-whitesmoke2">
						<li
							className={[
								'breadcrumb-item oit',
								selectedContinent ? 'active' : '',
							].join(' ')}
						>
							<div className="dropdown no-dropdown oit show d-flex justify-content-end">
								<Link
									to="/"
									className="btn btn-primary dropdown-toggle oit no-dropdown no-hover"
									style={{ textTransform: 'inherit' }}
								>
									{groups?.rows[0].groups[0].name}
								</Link>
							</div>
						</li>
						{/* pais */}
						{selectedContinent ? (
							<li
								className={[
									'breadcrumb-item oit',
									selectedCountry ? 'active' : '',
								].join(' ')}
							>
								<div className="dropdown oit show d-flex justify-content-end">
									<a
										className="btn btn-primary dropdown-toggle oit no-hover"
										href="#"
										role="button"
										id="dropdownCountry"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
										style={{ textTransform: 'inherit' }}
									>
										{selectedCountry
											? countrydata?.name
											: language?.text.breadcrumb
													.select_countries}
									</a>
									<div
										className="dropdown-menu oit"
										aria-labelledby="dropdownCountry"
									>
										{language?.lang &&
											groups?.rows.map(continent => {
												if (
													continent.status !==
													'ACTIVE'
												) {
													return null;
												}

												// latinoamerica paises
												return continent.groups.map(
													group => {
														return group.countries
															.filter(
																element =>
																	element.status !==
																	'INACTIVE'
															)
															.sort(
																(
																	paisA,
																	paisB
																) =>
																	paisA?.name.localeCompare(
																		paisB?.name
																	)
															)
															.map(
																(
																	country,
																	i
																) => {
																	return (
																		<Link
																			to={`/${country.node}`}
																			key={
																				country._id
																			}
																			className="no-hover btn btn-primary dropdown-item oit border-0"
																		>
																			{
																				country.name
																			}
																		</Link>
																	);
																}
															);
													}
												);
											})}
									</div>
								</div>
							</li>
						) : null}
						{/* {console.log("country id", countrydata)} */}
						{ !disabledContries.includes(countrydata?._id) && selectedCountry && selectedContinent ? (
							<li
								className={[
									'breadcrumb-item oit',
									selectedState ? 'active' : '',
								].join(' ')}
							>
								<div className="dropdown oit  show d-flex justify-content-end">
									<a
										className="btn btn-primary dropdown-toggle oit"
										href="#"
										role="button"
										id="dropdownState"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
										style={{ textTransform: 'inherit' }}
									>
										{selectedState
											? selectedState
											: countrydata?.choose}
									</a>
									<div
										className="dropdown-menu oit custom-dropdown-oit"
										aria-labelledby="dropdownState"
									>
										{countrystates
											?.sort((stateA, stateB) =>
												stateA.name.localeCompare(
													stateB.name
												)
											)
											.map(state => {
												return (
													<Link
														to={`/${country}/${state.node}`}
														key={state._id}
														className="btn btn-primary dropdown-item oit border-0"
													>
														{state.name}
													</Link>
												);
											})}
									</div>
								</div>
							</li>
						) : null}
					</ol>
				</nav>
			</div>
		</div>
	);
};

export default Breadcrumb;
