import React from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from './../../../../../context/Languages';

// custon hooks
import { useHttp } from './../../../../../hooks/http';
import DownloadList from '../../../../mapsHelpers/downloadList/downloadList';
import SharedButtons from '../../../../mapsHelpers/sharedbuttons/sharedButtons';

// components
import RiskFactorTable from '../../../../mapsHelpers/riskFactorTable/riskFactorTable';

const RiskFactors = ({ state }) => {
	const { language } = useLanguage();
	let location = useLocation();
	const [isLoading, data] = useHttp(
		`get_tab_mexico/${state}`,
		{tabcode:`risk_factors`, lang:language?.lang},
		false,
		[state, language?.lang],
		true
	);

	return (
		<div className="content">
			<div className="row">
				<div className="col-12 col-lg-7">
					<RiskFactorTable
						table={data && data[0].state[0].tabs.risk_factors.table}
					/>

					<div className="row">
						<div
							className="col-12 text-justify"
							dangerouslySetInnerHTML={{
								__html:
									data &&
									data[0].state[0].tabs.risk_factors
										.description[1],
							}}
						></div>
					</div>
				</div>
				<div className="col-12 col-lg-5">
					<div className="row">
						<div
							className="col-12 text-justify"
							dangerouslySetInnerHTML={{
								__html:
									data &&
									data[0].state[0].tabs.risk_factors
										.description[0],
							}}
						></div>
					</div>
					<div className="more-detail">
						<div className="row w-100">
							<div className="col-12 p-0">
								<div className="d-table ml-auto h-100">
									<div className="d-table-cell">
										<div className="share h-100">
											<p className="text">
												{
													language?.text.body
														.share_information
												}
											</p>
											<div className="oit-icon-group oit-icon-group-small icons-green">
												<SharedButtons
													url={`${location.pathname}`}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<DownloadList
							links={
								data &&
								data[0].state[0].tabs.risk_factors
									.more_descriptions.links
							}
							url={null}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RiskFactors;
