import React, { useEffect } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useRouteMatch,
	Redirect,
} from 'react-router-dom';
import { useGroups } from './../../context/Groups';
import Latinoamerica from './latinoamerica/';
import Mexico from './latinoamerica/mexico/Routes';
import CostaRica from './latinoamerica/costaRica/Routes';
import Argentina from './latinoamerica/argentina/Routes';
import Peru from './latinoamerica/peru/Routes';
import Colombia from './latinoamerica/colombia/Routes';
import Chile from './latinoamerica/chile/Routes';
import Brasil from './latinoamerica/brasil/Routes';
import Jamaica from './latinoamerica/jamaica/Routes';
import Paraguay from './latinoamerica/paraguay/Routes';
import Guatemala from './latinoamerica/guatemala/Routes';
import Ecuador from './latinoamerica/ecuador/Routes';

const America = () => {
	const { setContinent } = useGroups();
	let { path, url } = useRouteMatch();

	useEffect(() => {
		setContinent('Latinoamérica y Caribe');
	}, [path]);

	return (
		<Switch>
			<Route path="/" exact>
				<Latinoamerica />
			</Route>
			<Route path="/mexico">
				<Mexico />
			</Route>
			<Route path="/costa_rica">
				<CostaRica />
			</Route>
			<Route path="/argentina">
				<Argentina />
			</Route>
			<Route path="/peru">
				<Peru /> 

			</Route>
			<Route path="/ecuador">
				<Ecuador /> 

			</Route>
			<Route path="/guatemala">
				<Guatemala />
			</Route>
			<Route path="/colombia">
				<Colombia />
			</Route>
			<Route path="/paraguay">
				<Paraguay />
			</Route>
			<Route path="/chile">
				<Chile />
			</Route>
			<Route path="/brasil">
				 <Brasil /> 
			</Route>
			<Route path="/jamaica">
				<Jamaica />
			</Route>
			<Route path="*">
				<Redirect to="/"/>
			</Route>
		</Switch>
	);
};

export default React.memo(America);
