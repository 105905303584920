import React from 'react';

function FactorsGroups({ groups }) {
	return (
		<ul
			className=""
			style={{
				justifyContent: 'space-around',
				listStyle: 'none',
				display: 'flex',
				padding: '0',
				margin: 'none',
			}}
		>
			{groups?.map((group, i) => {
				if (i === 3 && group.range === null) return null;
				if (i === 3 && group.amount === 0) return null;
				let dosPuntos = group?.text ?  group.text.indexOf(':') : null ;
				if (dosPuntos && dosPuntos >= 0) {
					group.text = group.text.slice(0, dosPuntos + 1);
				}
				return (
					<li
						key={group._id}
						style={{
							fontSize: '12px',
							margin: '5px',
							textAlign: 'center',
						}}
					>
						<span
							style={{
								backgroundColor: group.color,
								borderRadius: '50%',
								display: 'inline-block',
								width: '10px',
								height: '10px',
								marginRight: '5px',
							}}
						></span>
						<strong>
							{group.text} <br></br>
							{` ${
								group.amount
									? group.amount
									: group.range
									? group.range
									: 0
							}`}
						</strong>
					</li>
				);
			})}
		</ul>
	);
}

export default React.memo(FactorsGroups);
