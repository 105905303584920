import React, {
	createContext,
	useContext,
	useState,
	useMemo,
	useEffect,
} from 'react';

import { useLocation
  } from "react-router-dom";

import client from '../api/client';

const LanguageContext = createContext();

function useQuery() {
	return new URLSearchParams(useLocation().search);
  }

export function LanguageProvider(props) {
	const [data, setdata] = useState(null);
	const [languageIsLoading, setLanguageIsLoading] = useState(true);

	let query = useQuery();
	const [language, setlanguage] = useState(()=> query.get("lang") ? query.get("lang") : 'es');

	// set groups for continents
	useEffect(() => {
		const getGroups = async () => {
			const response = await client.get(`/languages/${language}`
			);

			setdata(response.data);
			setLanguageIsLoading(false)
			console.log(response.data)
		};

		getGroups();
		setLanguageIsLoading(true)
	}, [language]);

	//change language

	const changeLanguage = language => {
		setlanguage(language);
	};

	const value = useMemo(() => {
		return {
			language: data,
			changeLanguage,
			languageIsLoading
		};
	}, [data, languageIsLoading]);

	return <LanguageContext.Provider value={value} {...props} />;
}

export function useLanguage() {
	const context = React.useContext(LanguageContext);
	if (!context) {
		throw new Error(
			'useLanguage debe estar dentro del proveedor GroupsContext'
		);
	}

	return context;
}
