import React, { useState, useEffect } from 'react';

export default function useLikehoohSvgMatch(data) {
	const [locations, setLocations] = useState([]);

	const setLocation = () => {
		let realLocations = [];
		if (data) {
			let states = data.structure.states;
			let cantones = data.structure.municipalities;
			let { groups } = data.config.tabs.child_labour_likelihood;
			let [
				provinceConfig,
				cantonConfig,
			] = data.config.tabs.child_labour_likelihood.config;

			data.map.locations[0].g.map(location => {
				// find state
				let targetState = states.find(
					state =>
						state.node === location.id ||
						state.node === location['data-name']
				);

				if (location['g'][0]['g']) {
					location['g'][0]['g'].map((cantonLocation, i) => {
						// find Canton

						let targetCanton = cantones.find(
							canton =>
								canton.node === cantonLocation.id ||
								canton.node === cantonLocation['data-name']
						);
						if (targetCanton) {
							// set color
							if (targetCanton) {
								if (
									groups[
										targetCanton.data.national_vulnerable
											.category - 1
									]
								) {
									location['g'][0]['g'][i].fill =
										groups[
											targetCanton.data
												.national_vulnerable.category -
												1
										].color;
								}
							} else {
								location['g'][0]['g'][i].fill = 'white';
							}
						} 
					});
				} 

				let color;
				// if macth

				if (targetState) {
					//info for province node
					// let provinceInfoGroup =
					// 	targetState.data.vulnerability_dimensions;

					// set color from db match
					//color = svgColors[group - 1].color;
					realLocations.push({
						...location,
						fill: '#fff',
						name: targetState.name,
						provinceConfig,
						cantonConfig,
					});
				} 
				setLocations(realLocations);
			});
		}
	};

	useEffect(() => {
		if (data) {
			setLocation();
		}
	}, [data]);

	return locations;
}
