import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from './../../../../../context/Languages';
import SharedButtons from '../../../../mapsHelpers/sharedbuttons/sharedButtons';
import gearMap from '../../../../../assets/images/team-work-2.png';
// custon hooks
import { useHttp } from './../../../../../hooks/http';

//maps helpers
import DownloadList from '../../../../mapsHelpers/downloadList/downloadList';
import MapsTextSkeleton from '../../../../mapsHelpers/mapsTextSkeleton/mapsTextSkeleton';
import MapsSpinner from '../../../../mapsHelpers/mapsSpinner/mapsSpinner';

// css module
import styles from './../mexico.module.css';
import './../../../../../index.css';

import './states.css';
import FactorsGroups from '../../../../mapsHelpers/complementaryFactorsGroups/FactorsGroups';

export default function ComplementaryFactors({ state }) {
	const [imageLoaded, setImageLoaded] = useState(false);
	let location = useLocation();
	const { language } = useLanguage();
	const [municipalities, setMunicipalities] = useState([]);
	const [isLoadingChildLabourLikehood, dataChildLabourLikehood] = useHttp(
		`get_tab_colombia/${state}`,
		{tabcode:'child_labour_likehood', lang:language?.lang},

		true,
		[state, language?.lang],
		true
	);
	const [isLoading, data] = useHttp(
		`get_tab_colombia/${state}`,
		{tabcode:'complementary_factors', lang:language?.lang},
		false,
		[state],
		true
	);

	// match svg location with backend
	const setLocation = () => {
		let realLocations = [];
		if (data) {
			let municipalities = dataChildLabourLikehood[0].municipalities;
			const {
				hover,
				stroke,
				stroke_width,
			} = dataChildLabourLikehood[0].department[0].tabs.child_labour_likelihood;
			// colors from the data object
			let svgColors =
				dataChildLabourLikehood[0].department[0].tabs
					.child_labour_likelihood.groups;
			dataChildLabourLikehood.map.locations.map(location => {
				let targetState = municipalities.find(
					state => state.node === location.id
				);
				let color;
				// if macth
				if (targetState) {
					//item information
					const {
						group,
						range,
					} = targetState.data.child_labour_likelihood;

					// set color from db match
					if (group) {
						color = svgColors[group - 1].color;
					}
					realLocations.push({
						...location,
						name: targetState.name,
						hover,
						range,
						stroke,
						stroke_width,
						_id: targetState.id,
						fill: color ? color : 'transparent',
					});
				}

				setMunicipalities(realLocations);
			});
		}
	};

	useEffect(() => {
		if (data) {
			setLocation();
		}
	}, [dataChildLabourLikehood]);

	return (
		<React.Fragment>
			<div className="row">
				{isLoading ? (
					<MapsTextSkeleton title={true} count={3} />
				) : (
					<div
						className="col-12 text-justify"
						dangerouslySetInnerHTML={{
							__html:
								data &&
								data[0].department[0].tabs.complementary_factors
									.description[0],
						}}
					></div>
				)}
			</div>
			<div className="row">
				<div className="col-sm-12 col-md-6">
					<div
						className="map-card"
						style={{
							minHeight: '400px',
							maxHeight: '400px',
							maxWidth: '400px',
							margin: 'auto',
						}}
					>
						{!imageLoaded && (
							<div className="mt-5 pt-2">
								<MapsSpinner />
							</div>
						)}

						<img
							style={{ filter: 'opacity(0.3) grayscale(.3)' }}
							onLoad={() => setImageLoaded(true)}
							src={gearMap}
							className="w-100 "
							alt="map"
						/>
						{imageLoaded && (
							<p className="text-center">
								<strong>Mapa en construcción</strong>
							</p>
						)}
					</div>
				</div>

				<div className="col-sm-12 col-md-6">
					<div
						className="map-card "
						style={{
							maxHeight: '400px',
							maxWidth: '400px',
							margin: 'auto',
							position: 'relative',
						}}
					>
						{isLoadingChildLabourLikehood ? (
							<div className="mt-5 pt-2">
								<MapsSpinner />
							</div>
						) : (
							<React.Fragment>
								<p className="text-center">
									<strong>
										Probabilidad de trabajo infantil por
										municipios
									</strong>
								</p>

								{dataChildLabourLikehood?.map.viewBox && (
									<svg
										className={styles.FadeIn}
										xmlns="http://www.w3.org/2000/svg"
										viewBox="-150 -50 1200 903"
									>
										{municipalities.map(location => {
											return (
												<g
													id={location.id}
													key={location.id}
													transform={
														location.transform
													}
												>
													{location.paths.map(
														(path, i) => (
															<path
																key={
																	path.id + i
																}
																id={path.id}
																stroke={
																	location.stroke
																}
																strokeWidth={
																	location.stroke_width
																}
																fill={
																	location.fill
																}
																d={path.d}
																transform={
																	path.transform
																}
															/>
														)
													)}
												</g>
											);
										})}
									</svg>
								)}
							</React.Fragment>
						)}
						<FactorsGroups
							groups={
								dataChildLabourLikehood &&
								dataChildLabourLikehood[0].department[0].tabs
									.child_labour_likelihood.groups
							}
						/>
					</div>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-12 col-lg-7">
					<div className="table-responsive">
						<table className="table oit-table">
							<thead>
								<tr>
									{data &&
										data[0].department[0].tabs.complementary_factors.table.headers.map(
											(header, index) => {
												return (
													<th
														key={
															header.description +
															index
														}
														style={{
															'--w': `${
																index === 0
																	? '80%'
																	: ''
															}`,
														}}
													>
														{header.description}{' '}
														<br />{' '}
														{header.value &&
															`(${header.value})`}
													</th>
												);
											}
										)}
								</tr>
							</thead>
							<tbody>
								{data &&
									data[0].department[0].tabs.complementary_factors.table.data.map(
										(dataField, index) => {
											return (
												<tr
													key={
														dataField.description +
														index
													}
												>
													<td>
														{dataField.description}
													</td>
													{dataField.values.map(
														(field, index) => {
															if (!field)
																return (
																	<td></td>
																);
															return (
																<td
																	key={
																		field.$numberDecimal +
																		index
																	}
																>
																	{Number.isInteger(
																		Number(
																			field?.$numberDecimal
																		)
																	)
																		? field?.$numberDecimal
																		: parseFloat(
																				field?.$numberDecimal
																		  ).toFixed(
																				2
																		  )}
																</td>
															);
														}
													)}
												</tr>
											);
										}
									)}
							</tbody>
						</table>
					</div>
				</div>
				<div className="col-12 col-lg-5 text-justify">
					<div
						dangerouslySetInnerHTML={{
							__html:
								data &&
								data[0].department[0].tabs.complementary_factors
									.description[1],
						}}
					></div>
					<div className="more-detail">
						<div className="row w-100">
							<div className="col-6 p-0"></div>
							<div className="col-6 p-0">
								<div className="d-table ml-auto h-100">
									<div className="d-table-cell">
										<div className="share h-100">
											<p className="text">
												{
													language?.text.body
														.share_information
												}
											</p>
											<div className="oit-icon-group oit-icon-group-small icons-green">
												<SharedButtons
													url={`${location.pathname}`}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<DownloadList
							links={
								data &&
								data[0].department[0].tabs.complementary_factors
									.more_descriptions.links
							}
							url={null}
						/>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
