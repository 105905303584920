import React from 'react';
import Header from './Header';
import Breadcrumb from './Breadcrumb';
import Footer from './Footer';
import { useGroups } from '../../context/Groups';
import { useLanguage } from '../../context/Languages';
import { LoaderScreen } from '../../components/loaderScreen/loaderScreen';

const Layout = ({ children }) => {
	const {groupIsLoading} = useGroups();
	const {languageIsLoading} = useLanguage();

	return (
		<div className="container">
		{groupIsLoading || languageIsLoading && <LoaderScreen />}
			<Header />
			<section className="body">
				<Breadcrumb />
			</section>
			<div className="tab-content" id="myTabContent">
				{children}
			</div>
			<Footer />
		</div>
	);
};

export default Layout;
