import React, { useEffect, useState } from 'react';

export default function UseSvgMatch(data) {
	const [states, setStates] = useState([]);
	const setLocation = () => {
		let realLocations = [];
		if (data) {
			let constituencies = data.structure.constituency;
			let { hover, stroke, stroke_width } =
				data.config.tabs.child_labour_likelihood.config[0]
			// colors from the data object
			let svgColors = data.config.tabs.child_labour_likelihood.groups;
			data.map.locations.forEach(location => {
				let targetConstituency = constituencies.find(constituency => {
					return constituency.node === location.id;
				});
				let color;
				// if macth
				if (targetConstituency) {
					//item information
					const { group, range } =
						targetConstituency.data.child_labour_likelihood;

					// set color from db match
					color = svgColors[group - 1]?.color;
					realLocations.push({
						...location,
						fill: color ? color : 'white',
						hover,
						range,
						stroke,
						group,
						stroke_width,
						name: targetConstituency.name,
						status: targetConstituency.status,
					});
				} else {
				}

				setStates(realLocations);
			});
		}
	};
	useEffect(() => {
		if (data) {
			setLocation();
		}
	}, [data]);

	return states;
}
