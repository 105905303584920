import React, { useEffect, useState } from 'react';

export default function UseSvgMatch(data) {
	const [states, setStates] = useState([]);
	const setLocation = () => {
		let realLocations = [];
		if (data) {
			let { district, department } = data.structure;
			let { groups } = data.config.tabs.child_labour_likelihood;
			let [districtConfig, departmentConfig] =
				data.config.tabs.child_labour_likelihood.config;

			data.map.locations.forEach(location => {
				let targetDepartment = department.find(department => {
					return department.node === location.id;
				});

				location['g'][0]['g'].map((districLocation, i) => {
					// find Canton
					let targetDistric = district.find(
						d => d.node === districLocation.id
					);
					if (targetDistric) {
						// set color
						location['g'][0]['g'][i].fill =
							groups[
								targetDistric.data.child_labour_likelihood
									.national.group - 1
							].color;
					}
				});
				if (targetDepartment) {
					let departmentInfoGroup =
						targetDepartment.data.child_labour_likelihood;
						
					realLocations.push({
						...location,
						fill: '#fff',
						name: targetDepartment.name,
						departmentInfoGroup,
						departmentConfig,
						districtConfig
					});
				}
				// if macth
				setStates(realLocations);
			});
		}
	};
	useEffect(() => {
		if (data) {
			setLocation();
		}
	}, [data]);

	return states;
}
