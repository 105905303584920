import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../../../../../context/Languages';
import SharedButtons from '../../../../mapsHelpers/sharedbuttons/sharedButtons';

// custon hooks
import { useHttp } from '../../../../../hooks/http';

import './states.css';
import DownloadList from '../../../../mapsHelpers/downloadList/downloadList';

export default function ComplementaryFactors({ state }) {
	const { language } = useLanguage();
	const [isLoading, data] = useHttp(
		`get_tab_costa_rica/${state}`,
		{tabcode:'complementary_factors', lang:language?.lang},
		true,
		[state, language?.lang],
		true
	);
	let location = useLocation();

	return (
		<React.Fragment>
			<div class="row">
				<div class="col-lg-7">
					<div class="table-responsive">
						<table class="table oit-table">
							<thead>
								<tr>
									{data &&
										data[0].province[0].tabs.complementary_factors.table.headers.map(
											(header, index) => {
												console.log("header", header)
												return (
													<th
														key={header._id}
														style={{
															'--w': `${
																index === 0
																	? '30%'
																	: '120px'
															}`,
															color: `${header.color}`,
														}}
													>
														{header.description }
														<br/>
														{header.value !== null ? <span>{`(${header.value})`}</span> : null}
														
													</th>
												);
											}
										)}
								</tr>
							</thead>
							<tbody>
								{data &&
									data[0].province[0].tabs.complementary_factors.table.data.map(
										dataField => {
											return (
												<tr>
													<td>
														<strong>
															{
																dataField.description
															}
														</strong>
													</td>
													{dataField.values.map(
														(field, i) => {
															if (!field)
																return (
																	<td key={i}>
																		{' '}
																	</td>
																);
															let number = Number(
																field.$numberDecimal,
																10
															);

															// console.log(
															// 	Number.isInteger(
															// 		isDecimal
															// 	)
															// );
															return (
																<td
																	key={
																		field._id
																	}
																>
																	{Number.isInteger(
																		number
																	)
																		? field.$numberDecimal
																		: number.toFixed(
																				1
																		  )}
																</td>
															);
														}
													)}
												</tr>
											);
										}
									)}
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-lg-5">
					<div
					className="text-justify"

						dangerouslySetInnerHTML={{
							__html:
								data &&
								data[0].province[0].tabs.complementary_factors
									.description[0],
						}}
					></div>
					<div class="more-detail">
						<div class="row w-100">
							<div class="col-6 p-0"></div>
							<div class="col-6 p-0">
								<div class="d-table ml-auto h-100">
									<div class="d-table-cell">
										<div class="share h-100">
											<p class="text">
												{
													language?.text.body
														.share_information
												}
											</p>
											<div class="oit-icon-group oit-icon-group-small icons-green">
												<SharedButtons
													url={`${location.pathname}`}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<DownloadList
							links={
								data &&
								data[0].province[0].tabs.complementary_factors
									.more_descriptions.links
							}
						/>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
