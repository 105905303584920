import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../../../../../context/Languages';
import SharedButtons from '../../../../mapsHelpers/sharedbuttons/sharedButtons';

// custon hooks
import { useHttp } from '../../../../../hooks/http';

import './states.css';
import DownloadList from '../../../../mapsHelpers/downloadList/downloadList';

export default function ComplementaryFactors({ state }) {
	let location = useLocation();
	const { language } = useLanguage();
	const [isLoading, data] = useHttp(
		`get_tab_chile/${state}`,
		{ tabcode: 'complementary_factors', lang: language?.lang },

		false,
		[state, language?.lang],
		true
	);
	console.log(data);
	return (
		<React.Fragment>
			<div className="row">
				<div className="col-lg-7">
					<div className="table-responsive">
						<table className="table oit-table">
							<thead>
								<tr>
									{data &&
										data[0].region[0].tabs.complementary_factors.table.headers.map(
											(header, index) => {
												return (
													<th
														key={header._id}
														style={{
															'--w': `${
																index === 0
																	? '80%'
																	: ''
															}`,
															color: `${header.color}`,
														}}
													>
														{header.description}
														<br />{' '}
														{header.value &&
															`(${header.value})`}
													</th>
												);
											}
										)}
								</tr>
							</thead>
							<tbody>
								{data &&
									data[0].region[0].tabs.complementary_factors.table.data.map(
										dataField => {
											return (
												<tr key={dataField._id}>
													<td>
														<strong>
															{
																dataField.description
															}
														</strong>
													</td>
													{dataField.values.map(
														(field, i) => {
															if (!field)
																return (
																	<td key={i}>
																		{' '}
																	</td>
																);
															let number = Number(
																field.$numberDecimal,
																10
															);

															// console.log(
															// 	Number.isInteger(
															// 		isDecimal
															// 	)
															// );
															return (
																<td
																	key={
																		dataField._id +
																		i
																	}
																>
																	{Number.isInteger(
																		number
																	)
																		? field.$numberDecimal
																		: number.toFixed(
																				1
																		  )}
																</td>
															);
														}
													)}
												</tr>
											);
										}
									)}
							</tbody>
						</table>
					</div>
				</div>
				<div className="col-lg-5 text-justify">
					<div
						dangerouslySetInnerHTML={{
							__html:
								data &&
								data[0].region[0].tabs.complementary_factors
									.description[0],
						}}
					></div>
					<div className="more-detail">
						<div className="row w-100">
							<div className="col-6 p-0"></div>
							<div className="col-6 p-0">
								<div className="d-table ml-auto h-100">
									<div className="d-table-cell">
										<div className="share h-100">
											<p className="text">
												{
													language?.text.body
														.share_information
												}
											</p>
											<div className="oit-icon-group oit-icon-group-small icons-green">
												<SharedButtons
													url={`${location.pathname}`}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<DownloadList
							links={
								data &&
								data[0].region[0].tabs.complementary_factors
									.more_descriptions.links
							}
						/>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
