import React, {
	createContext,
	useContext,
	useState,
	useMemo,
	useEffect,
} from 'react';

import client from '../api/client';
import { useLanguage } from './Languages';

const GroupsContext = createContext();

export function GroupsProvider(props) {
	const {language} = useLanguage()
	const [groups, setGroups] = useState(null);
	const [selectedContinent, setSelectedContinent] = useState(null);
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [selectedState, setSelectedState] = useState(null);
	const [groupsIsLoading, setGroupsIsLoading] = useState(true);

	const [countrystates, setCountryStates] = useState(null);

	// set selected Continent
	const setContinent = continent => {
		setSelectedContinent(continent);
	};
	// set selected contry
	const setCountry = country => {
		setSelectedCountry(country);
	};
	// set selected state
	const setAstate = s => {
		// if(!selectedCountry){
		// 	console.log("no hay pais")
		// 	return null
		// }
		if (!s || !selectedCountry) {
			setSelectedState(null);
			return null;
		}
		console.log("countrystates", countrystates)
		let stateList = [...countrystates];
		let filterState = stateList.filter(st => st.node == s);
		setSelectedState(filterState[0].name);
	};

	// set groups for continents
	useEffect(() => {
		const getGroups = async () => {
			const response = await client.get(`groupByContinents?lang=${language.lang}`);

			// set continents
			setGroups(response.data);
			setGroupsIsLoading(false)
		};

		if (language?.lang) {
			setGroupsIsLoading(true)
			getGroups();
			return;
		}
	}, [language?.lang]);

	// fetch States when the contrie change
	useEffect(() => {
		const getTerritories = async () => {
			let target = groups.rows[0].groups[0].countries.find(
				country =>
					country.node === selectedCountry ||
					country.name === selectedCountry
			);
			try {
				const response = await client.get(
					`get_${target.node}_territories?lang=${language.lang}`
				);

				setCountryStates(response.data.rows);
			} catch (error) {
				console.log(error);
			}
		};
		if (groups && selectedCountry) {
			getTerritories();
		}
	}, [selectedCountry, groups]);

	const value = useMemo(() => {
		return {
			groups,
			countrystates,
			setCountry,
			selectedCountry,
			selectedContinent,
			setContinent,
			setAstate,
			selectedState,
			groupsIsLoading
		};
	}, [
		groups,
		selectedCountry,
		selectedContinent,
		selectedState,
		countrystates,
		groupsIsLoading
	]);

	return <GroupsContext.Provider value={value} {...props} />;
}

export function useGroups() {
	const context = React.useContext(GroupsContext);
	if (!context) {
		throw new Error(
			'useGroups debe estar dentro del proveedor GroupsContext'
		);
	}

	return context;
}
