import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from './../../../../../context/Languages';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// custon hooks
import { useHttp } from './../../../../../hooks/http';

//maps helpers
import SharedButtons from '../../../../mapsHelpers/sharedbuttons/sharedButtons';
import MapsSpinner from '../../../../mapsHelpers/mapsSpinner/mapsSpinner';
import DownloadList from '../../../../mapsHelpers/downloadList/downloadList';

// css module
import styles from '../peru.module.css';
import './../../../../../index.css';
import MapsTextSkeleton from '../../../../mapsHelpers/mapsTextSkeleton/mapsTextSkeleton';

import GroupList from '../../../../mapsHelpers/groupList/groupList';
import { TooltipRange } from '../../../../mapsHelpers/costaRicaTooltipHelper/costaRicaTooltiphelper';

const ChildLabourLikelihood = ({ state }) => {
	let location = useLocation();
	const { language } = useLanguage();
	const [municipalities, setMunicipalities] = useState([]);
	const [isLoading, data] = useHttp(
		`get_tab_ecuador/${state}`,
		{ tabcode: 'child_labour_likehood', lang: language?.lang },
		true,
		[state, language?.lang],
		true
	);
	const [hoverStateId, setHoverStateId] = useState(null);
	// map ref
	const mapRef = useRef(null);
	// tooltip ref
	const tooltipRef = useRef(null);

	// match svg location with backend
	const setLocation = () => {
		let realLocations = [];
		if (data) {
			let provinces = data[0].provinces;
			let departments = data[0].canton;
			/* let { groups } = data[0].zones[0].tabs.child_labour_likelihood; */
			let groups = {};
			data[0].zones[0].tabs.child_labour_likelihood.groups.forEach(
				group => {
					groups[group.group] = group;
				}
			);
			let [cantonConfig, provinceConfig] =
				data[0].zones[0].tabs.child_labour_likelihood.config;
			data.map.locations[0].g.map(location => {
				let targetProvince = provinces.find(province => {
					return province.node === `${location.id}`;
				});
				console.log('GRUPOS: ', groups);
				location['g'][0].g.map((cantonLocation, i) => {
					// find Canton
					let targetCanton = departments.find(canton => {
						return canton.node === `cantones_${cantonLocation.id}`;
					});
					if (targetCanton) {
						console.log('targetCanton: ', targetCanton);
						location['g'][0].g[i].fill =
							groups[
								targetCanton.data.child_labour_likelihood.group
							].color;
						location['g'][0].g[i].name = targetCanton.name;
						location['g'][0].g[i].group =
							targetCanton.data.child_labour_likelihood.group;
						location['g'][0].g[i].range =
							targetCanton.data.child_labour_likelihood.range;
					} else {
						console.log(
							'no match canton',
							`cantones_${cantonLocation.id}`
						);
					}
				});

				let color;
				// if macth
				if (targetProvince) {
					// info for province node
					// let provinceInfoGroup =
					// 	targetProvince.tabs.child_labour_likelihood.ranges;

					// set color from db match
					// color = svgColors[group - 1].color;
					realLocations.push({
						...location,
						name: targetProvince.name,
						provinceConfig,
						cantonConfig,
						//  provinceInfoGroup
					});
				} else {
				}
			});
			setMunicipalities([...realLocations]);
		}
	};

	// set mouse event for tooltip, always tracking the mouse
	useEffect(() => {
		const mouseMove = mouse => {
			const tooltip = tooltipRef.current;
			const mapContainerPsition = mapRef.current.getBoundingClientRect();
			const { pageX, pageY } = mouse;
			if (!tooltip) {
				return;
			}
			let scrollTop =
				window.pageYOffset !== undefined
					? window.pageYOffset
					: (
							document.documentElement ||
							document.body.parentNode ||
							document.body
					  ).scrollTop;
			tooltip.style.top = `${
				pageY - mapContainerPsition.top - 95 - scrollTop
			}px`;
			tooltip.style.left = `${pageX - mapContainerPsition.left - 10}px`;
		};
		// for track the cursor
		document.addEventListener('mousemove', mouseMove);
		return () => {
			// remove cursdor event
			document.removeEventListener('mousemove', mouseMove);
		};
	}, []);

	const svhHoverHandler = (e, info) => {
		setHoverStateId([e.currentTarget.id, info]);
	};

	const svhblurHandler = e => {
		setHoverStateId(null);
	};

	useEffect(() => {
		if (data) {
			setLocation();
		}
	}, [data]);

	return (
		<div className="row">
			<div className="col-sm-7 left">
				<TransitionGroup>
					{hoverStateId && (
						<CSSTransition
							in={hoverStateId}
							timeout={200}
							classNames="my-node"
						>
							<div className={styles.Tooltip} ref={tooltipRef}>
								<h4 className="mb-0">
									<span
										className={styles.Indicador}
										style={{
											backgroundColor: `${hoverStateId[1].color}`,
										}}
									></span>
									{hoverStateId[1]?.name}
								</h4>

								{/* {hoverStateId[1]?.range && (
									<TooltipRange
										group={
											data[0].zones[0].tabs
												.child_labour_likelihood.groups[
												hoverStateId[1]?.group - 1
											].text
										}
									/>
								)} */}
							</div>
						</CSSTransition>
					)}
				</TransitionGroup>
				<div ref={mapRef}>
					{isLoading ? (
						<MapsSpinner />
					) : (
						data?.map && (
							<svg
								className={styles.FadeIn}
								xmlns="http://www.w3.org/2000/svg"
								viewBox={data.map.viewBox}
							>
								{municipalities.map(location => {
									return (
										<g
											id={location.id}
											key={location.id}
											style={{ cursor: 'pointer' }}
										>
											{location.g[0].g.map(group => {
												return (
													<g
														onMouseLeave={
															svhblurHandler
														}
														onMouseEnter={e =>
															svhHoverHandler(e, {
																name: group.name,
																color: group.fill,
																group: group.group,
																range: group.range,
															})
														}
														key={group.id}
														id={group.id}
													>
														{group.paths?.map(
															path => {
																return (
																	<path
																		key={
																			group.id +
																			path.id
																		}
																		id={
																			path.id
																		}
																		d={
																			path.d
																		}
																		fill={
																			group.fill
																		}
																		stroke={
																			location
																				.cantonConfig
																				.stroke
																		}
																		strokeWidth={
																			location
																				.cantonConfig
																				.stroke_width
																		}
																	/>
																);
															}
														)}
													</g>
												);
											})}
											{location.g[1].paths.map(path => {
												return (
												
														<path
															key={
																path.id +
																path.id
															}
															id={path.id}
															d={path.d}
															stroke={
																location
																	.provinceConfig
																	.stroke
															}
															strokeWidth={
																location
																	.provinceConfig
																	.stroke_width
															}
														/>
												);
											})}
										</g>
									);
								})}
							</svg>
						)
					)}
				</div>
			</div>
			<div class="col-sm-5 right">
				{isLoading ? (
					<MapsTextSkeleton title={true} count={5} />
				) : (
					<React.Fragment>
						<div
							className={[styles.FadeIn, 'text-justify'].join(
								' '
							)}
							dangerouslySetInnerHTML={{
								__html:
									data &&
									data[0].zones[0].tabs
										.child_labour_likelihood.description[0],
							}}
						></div>
						<GroupList
							groups={
								data &&
								data[0].zones[0].tabs.child_labour_likelihood
									.groups
							}
						/>

						<div
							className={[styles.FadeIn, 'text-justify'].join(
								' '
							)}
							dangerouslySetInnerHTML={{
								__html:
									data &&
									data[0].zones[0].tabs
										.child_labour_likelihood.description[1],
							}}
						></div>
						<div
							className={styles.FadeIn}
							dangerouslySetInnerHTML={{
								__html:
									data &&
									data[0].zones[0].tabs
										.child_labour_likelihood.description[2],
							}}
						></div>
						<div class="more-detail">
							<div class="row">
								<div class="col-6"></div>
								<div class="col-6">
									<div class="d-table h-100">
										<div class="d-table-cell">
											<div class="share h-100">
												<p class="text">
													{
														language?.text.body
															.share_information
													}
												</p>
												<div class="oit-icon-group oit-icon-group-small icons-green">
													<SharedButtons
														url={`${location.pathname}`}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<DownloadList
							links={
								data &&
								data[0].zones[0].tabs.child_labour_likelihood
									.more_descriptions.links
							}
							url={`get_mexico_pdf/national_table/${state}`}
						/>
					</React.Fragment>
				)}
			</div>
		</div>
	);
};

export default ChildLabourLikelihood;
