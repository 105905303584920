import React, {useState, useEffect} from 'react';


export default function DownloadList({links, url}) {
	const [somethingToShow, setSomethingToShow] = useState(false)

	useEffect(() => {
		
		if(links){
			links.forEach(link => {
			if(somethingToShow) return ;
			if(link.url ){
				setSomethingToShow(true);
			}
	});
		}
	}, [links])

	
    if(!links || links.length === 0 || !somethingToShow) return null;
	return (
		<div className="downloads">
			<ul className="downloads-list">
				{links.map(
					(link, index, array) => {
						if(!link.url) return null;
						return (
							<div
								key={link._id}
								className={
									index === array.length - 1 ? '' : 'mb-3'
								}
							>
								<a
									href={link.url}
									key={link._id}
									target="_blank"
								>
									<li className="downloads-list-item">
										<h3 className="downloads-list-item-title">
											{link.title}
										</h3>
									</li>
								</a>
							</div>
						);
					}
				)}
			</ul>
		</div>
	);
}
