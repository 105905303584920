import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useLanguage } from './../../../../context/Languages';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// custon hooks
import { useHttp } from '../../../../hooks/http';

// helpers
import SharedButtons from '../../../mapsHelpers/sharedbuttons/sharedButtons';
import DownloadList from '../../../mapsHelpers/downloadList/downloadList';
import MapsSpinner from '../../../mapsHelpers/mapsSpinner/mapsSpinner';
import Cluster from './clusterMap';

// css module
import styles from './mexico.module.css';
import './../../../../index.css';

import MapsTextSkeleton from '../../../mapsHelpers/mapsTextSkeleton/mapsTextSkeleton';
import LikehoodGroups from '../../../../components/likehoodGroups';
import UseSvgMatch from './hooks/useSvgMatch';
import LikehoodMap from '../guatemala/likehoodMap';

const groupRanges = [
	"Riesgo bajo", "Riesgo medio", "Riesgo alto"
]

const ChildLabourLikelihood = () => {
	const { language } = useLanguage();
	let location = useLocation();
	const history = useHistory();
	const [isLoading, data] = useHttp(
		'get_tab_guatemala',
		{ tabcode: 'child_labour_likelihood', lang: language?.lang },

		true,
		[language?.lang]
	);
	console.log("guatemala data", data)
	const states = UseSvgMatch(data);
	const [hoverStateId, setHoverStateId] = useState(null);
	// map ref
	const mapRef = useRef();
	// tooltip ref
	const tooltipRef = useRef();

	// set mouse event for tooltip, always tracking the mouse
	useEffect(() => {
		const mouseMove = mouse => {
			const tooltip = tooltipRef.current;
			const mapContainerPsition = mapRef.current.getBoundingClientRect();
			const { pageX, pageY } = mouse;
			if (!tooltip) {
				return;
			}
			let scrollTop =
				window.pageYOffset !== undefined
					? window.pageYOffset
					: (
							document.documentElement ||
							document.body.parentNode ||
							document.body
					  ).scrollTop;
			tooltip.style.top = `${
				pageY - mapContainerPsition.top - 80 - scrollTop
			}px`;
			tooltip.style.left = `${pageX - mapContainerPsition.left - 10}px`;
		};
		// for track the cursor
		document.addEventListener('mousemove', mouseMove);
		return () => {
			// remove cursdor event
			document.removeEventListener('mousemove', mouseMove);
		};
	}, []);

	// hover handler

	const svgHoverHandler = (e, info) => {
		setHoverStateId([e.currentTarget.id, info]);
	};

	const svgblurHandler = e => {
		setHoverStateId(null);
	};
	// click handler
	const svgClickHandler = e => {
		console.log('e', e.currentTarget.id);
			history.push(
				`/guatemala/${e.currentTarget.id}/child-labour-likelihood`
			);
	};

	return (
		<div className="row">
			<div className="col-sm-7 left">
				<div ref={mapRef} className="mb-4">
					<TransitionGroup>
						{hoverStateId && (
							<CSSTransition
								in={hoverStateId}
								timeout={200}
								classNames="my-node"
							>
								<div
									className={styles.Tooltip}
									ref={tooltipRef}
								>
									<h4 className="mb-0">
										{hoverStateId[1]?.name}
									</h4>
									{hoverStateId[1].group && (
										<small>
											<span className="ml-3">
												{groupRanges[hoverStateId[1].group  - 1]}
											</span>
										</small>
									)}
									{/* {console.log('hoverStateId[1]?.group', hoverStateId[1]?.group)}
									<TooltipRange
										range={hoverStateId[1]?.range}
										group={hoverStateId[1]?.group}
									/> */}
								</div>
							</CSSTransition>
						)}
					</TransitionGroup>

					{isLoading ? (
						<MapsSpinner />
					) : (<LikehoodMap locations={states}
						viewBox={data?.map.viewBox}
						svhHoverHandler={svgHoverHandler}
						svhblurHandler={svgblurHandler}
						svgClickHandler={svgClickHandler} />
					)}
				</div>
			</div>

			<div className="col-sm-5 right">
				{isLoading ? (
					<MapsTextSkeleton title={true} count={5} />
				) : (
					data && (
						<div className={styles.FadeIn}>
							<LikehoodGroups
								child_labour_likelihood={
									data?.config.tabs.child_labour_likelihood
								}
							/>

							<div className="more-detail">
								<div className="row w-100">
									<div className="col-12 p-0">
										<div className="d-table h-100">
											<div className="d-table-cell">
												<div className="share h-100">
													<p className="text">
														{
															language?.text.body
																.share_information
														}
													</p>
													<div className="oit-icon-group oit-icon-group-small icons-green">
														<SharedButtons
															url={`${location.pathname}`}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<DownloadList
								links={
									data?.config.tabs.child_labour_likelihood
										.more_descriptions.links
								}
								url="get_colombia_pdf/national_table"
							/>
						</div>
					)
				)}
			</div>
		</div>
	);
};

export default ChildLabourLikelihood;
