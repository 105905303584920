import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useGroups } from '../../../context/Groups';
import { useLanguage } from '../../../context/Languages';
import './../../../index.css';
import SharedButtons from '../../mapsHelpers/sharedbuttons/sharedButtons';
import MoreDetails from './moreDetails';
import MapsSpinner from './../../mapsHelpers/mapsSpinner/mapsSpinner';
import MapsTextSkeleton from '../../mapsHelpers/mapsTextSkeleton/mapsTextSkeleton';
import { SvgLatamMap } from './svgLatamMap';

const Latinoamerica = () => {
	let location = useLocation();
	const history = useHistory();
	const { groups } = useGroups();
	const { language } = useLanguage();
	const [locations, setLocations] = useState([]);
	const [data, setdata] = useState(null);
	const [isLoading, setisLoading] = useState(true);
	const [animationRunning, setanimationRunning] = useState(false);

	const mapRef = useRef();

	// set locations color by database match

	const setLocation = () => {
		let realLocations = [];
		if (data) {
			let list = groups.rows[0].groups[0].countries;
			// colors from the data object
			let svgColors = groups.rows[0].groups[0].config.groups;
			data.map.locations.map(location => {
				let targetElement = list.find(
					state => state.node === location.id
				);
				let color;
				// set element color
				if (targetElement ) {
					const { group, range, name } = targetElement;
					// set color from db match
					color = svgColors[group - 1].color;
					realLocations.push({ ...location, fill: color, name, status: targetElement.status});
				} else {
					realLocations.push({
						...location,
						fill: svgColors[3].color,
						hideName: true,
					});
				}

				setLocations(realLocations);
			});
		}
	};

	useEffect(() => {
		setisLoading(true);
		const getdata = async () => {
			const responseMap = await fetch(groups.rows[0].groups[0].map);
			const map = await responseMap.json();
			if (map) setisLoading(false);
			setdata({ map: map });
		};

		if (groups) {
			getdata();
		}
	}, [groups]);

	useEffect(() => {
		if (data) {
			setLocation();
		}
	}, [data]);

	const svgClickHandler = (e, hideName = false) => {
		console.log("target", e)
		if(e.currentTarget.dataset.status === "INACTIVE") return ;
		let path = e.currentTarget.id;
		if (hideName) return null;
		if (!e.currentTarget.id && e.currentTarget.href) {
			path = e.target.getAttribute('href').split('#')[1];
		}

		history.push(`/${path}`);
	};
	const svhHoverHandler = (e, hideName) => {
		if(e.currentTarget.dataset.status === "INACTIVE") return ;
		if (hideName) return null;
		const newId = `#${e.currentTarget.id}`;
		mapRef.current.setAttribute('href', newId);

		e.currentTarget.classList.add('bounce');
	};

	const svhblurHandler = e => {
		if(e.currentTarget.dataset.status === "INACTIVE") return ;
		let element = e.currentTarget;
		let path = e.target;
		if (
			path.id === 'Path_965' ||
			path.id === 'Path_972' ||
			path.id === 'Path_826' ||
			path.id === 'Path_829' ||
			path.id === 'Path_962'
		) {
			if (animationRunning) return null;
			setanimationRunning(true);
			setTimeout(() => {
				setanimationRunning(false);
				mapRef.current.setAttribute('href', '');
				element.classList.remove('bounce');
			}, 2000);
			return;
		}
		
		mapRef.current.setAttribute('href', '');
		element.classList.remove('bounce');

		//setanimationRunning(true)
	};

	
	return (
		<div className="latam">
			<div className="container">
				<div className="row content">
					<div className="col-sm-7 left">
						<div
							id="country-tab"
							data-toggle="tab"
							href="#country"
							role="tab"
							aria-controls="country"
							aria-selected="false"
							style={{ cursor: 'pointer' }}
						>
							{isLoading ? (
								<div className="d-block d-sm-none mb-4">
									<MapsTextSkeleton title={true} count={1} />
								</div>
							) : (
								<div className="d-block d-sm-none mb-4 content right">
									<h2 className="d-block d-sm-none">
										{
											groups?.rows[0].groups[0].title
										}
									</h2>
									
									<hr
										className="d-block d-sm-none"
										style={{
											'--mt': '15px',
											'--mb': '15px',
										}}
									/>
									<div
										className=""
										dangerouslySetInnerHTML={{
											__html:
												groups?.rows[0].groups[0]
													.descriptions[0],
										}}
									></div>
								</div>
							)}
							{isLoading ? (
								<MapsSpinner />
							) : (
								<SvgLatamMap mapRef={mapRef} groups={groups} language={language} locations={locations} svgClickHandler={svgClickHandler} svhHoverHandler={svhHoverHandler} svhblurHandler={svhblurHandler} />
							)}
						</div>
					</div>
					<div className="col-sm-5 right">
						{isLoading ? (
							<React.Fragment>
								<MapsTextSkeleton title={true} count={8} />
								<MapsTextSkeleton title={true} count={1} />
								<MapsTextSkeleton title={true} count={1} />
							</React.Fragment>
						) : (
							<React.Fragment>
								<h2 className="d-none d-sm-block">
									{
										groups?.rows[0].groups[0].title
									}
								</h2>
								<hr
									style={{ '--mt': '15px', '--mb': '15px' }}
								/>
								<div
									className="d-none d-sm-block text-justify"
									dangerouslySetInnerHTML={{
										__html:
											groups?.rows[0].groups[0]
												.descriptions[0],
									}}
								></div>
								

								
								

								<ul>
									{groups &&
										groups.rows[0].groups[0].config.groups.map(
											(group, index) => {
												if (!group.text) return null;
												return (
													<li
														key={group.name + index}
														style={{
															'--color':
																group.color,
														}}
													>
														{group.text && group.text }
													</li>
												);
											}
										)} 
								</ul>

								<div
									className="text-justify"
									dangerouslySetInnerHTML={{
										__html:
											groups?.rows[0].groups[0]
												.descriptions[1],
									}}
								></div>
								<div className="more-detail">
									<div className="row w-100">
										<div className="col-6 p-0 pl-3">
											<button
												className="more-detail"
												data-toggle="modal"
												data-target="#exampleModalLong"
											>
												{
													language.text.button
														.more_details
												}
											</button>
										</div>
										<div className="col-6 p-0">
											<div className="d-table  ml-auto h-100">
												<div className="d-table-cell">
													<div className="share h-100">
														<p className="text">
															{
																language?.text
																	.body
																	.share_information
															}
														</p>
														<div className="oit-icon-group oit-icon-group-small icons-green">
															<SharedButtons
																url={`${location.pathname}`}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</React.Fragment>
						)}
					</div>
				</div>
			</div>
			<MoreDetails data={groups?.rows[0].groups[0].more_descriptions} />
		</div>
	);
};

export default Latinoamerica;
