import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styles from './mexico.module.css';

export default function BrasilTooltip({ hoverStateId, tooltipRef }) {
	return (
		<TransitionGroup>
			{hoverStateId && (
				<CSSTransition
					in={hoverStateId !== null}
					timeout={200}
					classNames="my-node"
				>
					<div className={styles.Tooltip} ref={tooltipRef}>
						<h4 className="mb-0">
							{hoverStateId[1].color && <span
								className={styles.Indicador}
								style={{
									backgroundColor: `${hoverStateId[1].color}`,
								}}
							></span>}
							{hoverStateId[1]?.name}
							{hoverStateId[1]?.range}
						</h4>
						{hoverStateId[1].range && (
							<small>
								Rango de riesgo de trabajo infantil:
								<span className="ml-1">
									{hoverStateId[1].range}
								</span>
							</small>
						)}
					</div>
				</CSSTransition>
			)}
		</TransitionGroup>
	);
}
