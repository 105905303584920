import React, { useEffect, useState } from 'react';

export default function UseSvgMatch(data) {
	const [states, setStates] = useState([]);
	const setLocation = () => {
		let realLocations = [];
		if (data) {
			console.log("svg match", data)
			let regions = data.structure.region;
			let { stroke, stroke_width } =
				data.config.tabs.child_labour_likelihood.config[0]
				console.log('stroke', stroke)
			// colors from the data object
			let svgColors = data.config.tabs.child_labour_likelihood.groups;
			data.map.locations.forEach(location => {
				let targetRegion = regions.find(district => {
					return district.node === location.id;
				});
				let color;
				// if macth
				if (targetRegion) {
					//item information

					console.log("target district", targetRegion)
					const { group, range } =
					targetRegion.tabs.child_labour_likelihood;
					// set color from db match
					color = svgColors[group - 1]?.color;
					realLocations.push({
						...location,
						fill: targetRegion.fill,
						range,
						group,
						stroke,
						stroke_width,
						name: targetRegion.name,
						status: targetRegion.status,
					});
				} else {
				}

				setStates(realLocations);
			});
		}
	};
	useEffect(() => {
		if (data) {
			setLocation();
		}
	}, [data]);

	return states;
}
