import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from './../../../../context/Languages';

// maps helpers
import SharedButtons from '../../../mapsHelpers/sharedbuttons/sharedButtons';
import DownloadList from '../../../mapsHelpers/downloadList/downloadList';

import client from '../../../../api/client';
import { useHttpArgentina } from '../../../../hooks/argentina/httpArgentina';

const RiskFactors = () => {
	const { language } = useLanguage();
	let location = useLocation();
	const [isLoading, data] = useHttpArgentina(
		'get_tab_argentina/',
		{tabcode:'risk_factors', lang:language?.lang},

		false,
		[language?.lang]
	);

		console.log(data)
	return (
		<div className="content">
			<div className="row">
				<div className="col-12 col-lg-7">
					<div className="table-responsive">
						<table className="table oit-table table-bars">
							<thead>
								<tr>
									<th colSpan="1" scope="col"></th>
									{data?.config.tabs.risk_factors.table.headers.titles.map(
										(header, index) => {
											return (
												<th
													scope="col"
													colSpan="3"
													key={header._id}
												>
													<h3 className="text-center">
														{header.description}
													</h3>
												</th>
											);
										}
									)}
								</tr>
								<tr>
									{data?.config.tabs.risk_factors.table.headers.subtitles.map(
										(subheader, index) => {
											return (
												<th
													scope="col"
													key={subheader._id}
												>
													{subheader.description}
												</th>
											);
										}
									)}
								</tr>
							</thead>
							<tbody>
								<tr></tr>
								{data &&
									data?.config.tabs.risk_factors.table.data
										.sort(function (a, b) {
											return (
												b.urbano.odds_ratio
													?.$numberDecimal -
												a.urbano.odds_ratio
													?.$numberDecimal
											);
										})
										.map((dataField, index, array) => {
											let oddsUrbano = array.reduce(
												(a, b) =>
													a +
													(+b.urbano.odds_ratio
														?.$numberDecimal |
														0),
												0
											);
											let oddsRural = array.reduce(
												(a, b) =>
													a +
													(+b.rural.odds_ratio
														?.$numberDecimal |
														0),
												0
											);

											// width for progress bar
											let widthUrbano =
												(dataField.urbano.odds_ratio
													?.$numberDecimal *
													100) /
												oddsUrbano;
											let widthRural =
												(dataField.rural.odds_ratio
													?.$numberDecimal *
													100) /
												oddsRural;

											// elimina la constante de la tabla
											if (index === array.length - 1) {
												return null;
											}
											if (!dataField?.urbano?.effect) {
												return null;
											}
											return (
												<tr key={dataField._id}>
													<td>
														{dataField.variable}
													</td>
													<td>
														<span
															className="text-danger font-weight-bolder"
															style={{
																fontSize:
																	'1rem',
															}}
														>
															{
																dataField.urbano
																	.effect
															}
														</span>
													</td>
													<td>
														{dataField.urbano.odds_ratio?.$numberDecimal.slice(
															0,
															3
														)}
													</td>
													<td>
														{dataField.urbano
															.odds_ratio && (
															<div className="progress">
																<div
																	className="progress-bar"
																	role="progressbar"
																	style={{
																		width: `${widthUrbano}%`,
																		backgroundColor:
																			dataField
																				.urbano
																				.color,
																	}}
																	aria-valuenow="15"
																	aria-valuemin="0"
																	aria-valuemax="247"
																></div>
															</div>
														)}
													</td>
													<td>
														<span
															className="text-danger font-weight-bolder"
															style={{
																fontSize:
																	'1rem',
															}}
														>
															{
																dataField.rural
																	.effect
															}
														</span>
													</td>
													<td>
														{dataField.rural.odds_ratio?.$numberDecimal.slice(
															0,
															3
														)}
													</td>
													<td>
														{dataField.rural
															.odds_ratio && (
															<div className="progress">
																<div
																	className="progress-bar"
																	role="progressbar"
																	style={{
																		width: `${widthRural}%`,
																		backgroundColor:
																			dataField
																				.rural
																				.color,
																	}}
																	aria-valuenow="15"
																	aria-valuemin="0"
																	aria-valuemax="247"
																></div>
															</div>
														)}
													</td>
												</tr>
											);
										})}
							</tbody>
						</table>
					</div>
					<div
						className="col-12 text-justify p-lg-0"
						dangerouslySetInnerHTML={{
							__html:
								data?.config.tabs.risk_factors.description[1],
						}}
					></div>
				</div>
				<div className="col-12 col-lg-5">
					<div
						className="col-12 text-justify"
						dangerouslySetInnerHTML={{
							__html:
								data?.config.tabs.risk_factors.description[0],
						}}
					></div>

					<div className="more-detail">
						<div className="row w-100">
							<div className="col-6 ml-auto p-0">
								<div className="d-table h-100">
									<div className="d-table-cell">
										<div className="share h-100">
											<p className="text">
												{
													language?.text.body
														.share_information
												}
											</p>
											<div className="oit-icon-group oit-icon-group-small icons-green">
												<SharedButtons
													url={`${location.pathname}`}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<DownloadList
							links={
								data?.config.tabs.risk_factors.more_descriptions
									.links
							}
							url={null}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RiskFactors;
