import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useLanguage } from '../../../../context/Languages';

// custon hooks
import { useHttp } from '../../../../hooks/http';
import useSvgMatch from './chilehooks/useSvgMatch';

// helpers

import SharedButtons from '../../../mapsHelpers/sharedbuttons/sharedButtons';

// components
import MapsSpinner from '../../../mapsHelpers/mapsSpinner/mapsSpinner';
import MapsTextSkeleton from '../../../mapsHelpers/mapsTextSkeleton/mapsTextSkeleton';
import DownloadList from '../../../mapsHelpers/downloadList/downloadList';
import GroupList from '../../../mapsHelpers/groupList/groupList';
import NationalTooltip from './nationalTooltip';
import Cluster from './clusterMap';
// css module
import styles from './costarica.module.css';
import ChileSvgMap from './chileSvgMap';

const ChildLabourLikelihood = () => {
	const { language } = useLanguage();
	let location = useLocation();
	const history = useHistory();
	const [isLoading, data] = useHttp(
		'get_tab_chile/',
		{ tabcode: 'child_labour_vulnerable', lang: language?.lang },

		true,
		[language?.lang]
	);
	const states = useSvgMatch(data);
	const [hoverStateId, setHoverStateId] = useState(null);
	// map ref
	const mapRef = useRef(null);
	// tooltip ref
	const tooltipRef = useRef(null);

	// set mouse event for tooltip, always tracking the mouse
	useEffect(() => {
		const mouseMove = mouse => {
			const tooltip = tooltipRef.current;
			const mapContainerPsition = mapRef.current.getBoundingClientRect();
			const { pageX, pageY } = mouse;
			if (!tooltip) {
				return;
			}
			let scrollTop =
				window.pageYOffset !== undefined
					? window.pageYOffset
					: (
							document.documentElement ||
							document.body.parentNode ||
							document.body
					  ).scrollTop;
			tooltip.style.top = `${
				pageY - mapContainerPsition.top - 300 - scrollTop
			}px`;
			tooltip.style.left = `${pageX - mapContainerPsition.left - 10}px`;
		};
		// for track the cursor
		document.addEventListener('mousemove', mouseMove);
		return () => {
			// remove cursdor event
			document.removeEventListener('mousemove', mouseMove);
		};
	}, []);

	const svhHoverHandler = (e, info) => {
		setHoverStateId([e.currentTarget.id, info]);
	};

	const svhblurHandler = e => {
		setHoverStateId(null);
	};

	// click
	const svgClickHandler = e => {
		history.push(`/chile/${e.currentTarget.id}/vulnerability-child-labour`);
	};

	return (
		<div className="row">
			<div className="col-sm-7 left">
				<div ref={mapRef} className="mb-3">
					<NationalTooltip
						hoverStateId={hoverStateId}
						tooltipRef={tooltipRef}
						data={data}
					/>
					{isLoading ? (
						<MapsSpinner />
					) : (
						data?.map && (
							<ChileSvgMap
								data={data}
								states={states}
								svgClickHandler={svgClickHandler}
								svhblurHandler={svhblurHandler}
								svhHoverHandler={svhHoverHandler}
							/>
						)
					)}
				</div>
			</div>
			<div className="col-sm-5 right">
				{isLoading ? (
					<MapsTextSkeleton title={true} count={6} />
				) : (
					<React.Fragment>
						<div
							className="mb-5 text-justify"
							dangerouslySetInnerHTML={{
								__html: data?.config.tabs
									.child_labour_vulnerable.description[0],
							}}
						></div>
						<GroupList
							groups={
								data?.config.tabs.child_labour_vulnerable.groups
							}
						/>
						<div
							className={styles.FadeIn}
							dangerouslySetInnerHTML={{
								__html: data?.config.tabs
									.child_labour_vulnerable.description[1],
							}}
						></div>
						<div
							className={styles.FadeIn}
							dangerouslySetInnerHTML={{
								__html: data?.config.tabs
									.child_labour_vulnerable.description[2],
							}}
						></div>
						<div className="more-detail">
							<div className="row w-100">
								<div className="col-6">
									<a
										href="javascript:void(0)"
										data-toggle="modal"
										data-target="#exampleModalLong2"
									>
										<button className="more-detail">
											{language.text.button.cluster_map}
										</button>
									</a>
								</div>
								<div className="col-6 p-0">
									<div className="d-table h-100">
										<div className="d-table-cell">
											<div className="share h-100">
												<p className="text">
													{
														language?.text.body
															.share_information
													}
												</p>
												<div className="oit-icon-group oit-icon-group-small icons-green">
													<SharedButtons
														url={`${location.pathname}`}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<DownloadList
							links={
								data?.config.tabs.child_labour_vulnerable
									.more_descriptions.links
							}
						/>
					</React.Fragment>
				)}
				<Cluster
				language={language}
					data={data?.config.tabs.child_labour_vulnerable.clusters}
				/>
			</div>
		</div>
	);
};

export default ChildLabourLikelihood;
