import React from 'react';

export default function RiskFactorTable({ table }) {
	if (!table) return null;

	const { headers, data } = table;
	return (
		<div className="table-responsive ">
			<table className="table oit-table table-bars">
				<thead>
					<tr>
						{headers.map((header, index) => {
							// if(index === 0){
							// 	return (
							// 		<React.Fragment key ={header.description + index}>
							// 		<th>{header.description}</th>
							// 		<th style={{ "--w": "20%" }}></th>
							// 		</React.Fragment>

							// 	)
							// }
							return (
								<th key={header.description + index}>
									{header.description}
								</th>
							);
						})}
					</tr>
				</thead>
				<tbody>
					{data
						.sort(function (a, b) {
							return (
								b?.odds_ratio?.$numberDecimal -
								a?.odds_ratio?.$numberDecimal
							);
						})
						.map((dataField, index, array) => {
							let odds = array.reduce((a, b) => {
								return (
									a +
									(b.odds_ratio
										? +b.odds_ratio.$numberDecimal
										: 0)
								);
							}, 0);
							
							let width =
								(dataField?.odds_ratio?.$numberDecimal * 100) /
								odds;
							// elimina la constante de la tabla
							if (index === array.length - 1) {
								return null;
							}

							return (
								<tr
									key={
										dataField?.odds_ratio?.$numberDecimal +
										index
									}
								>
									<td>{dataField.variable}</td>
									<td>
										<span
											className="text-danger font-weight-bolder"
											style={{ fontSize: '1rem' }}
										>
											{dataField.effect}
										</span>
									</td>
									<td>
										{dataField.odds_ratio
											? Number.isInteger(
													dataField?.odds_ratio
														?.$numberDecimal
											  )
												? dataField?.odds_ratio
														?.$numberDecimal
												: parseFloat(
														dataField?.odds_ratio
															.$numberDecimal
												  ).toFixed(1)
											: null}
									</td>
									<td>
										{dataField.odds_ratio && (
											<div className="progress">
												<div
													className="progress-bar"
													role="progressbar"
													style={{
														width: `${width}%`,
														backgroundColor:
															dataField.color,
													}}
													aria-valuenow="15"
													aria-valuemin="0"
													aria-valuemax="247"
												></div>
											</div>
										)}
									</td>
								</tr>
							);
						})}
				</tbody>
			</table>
		</div>
	);
}
