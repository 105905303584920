import React, { useEffect, useState } from 'react';

export default function useSvgMatchSubNacional(data, tab) {
	const [municipalities, setMunicipalities] = useState([]);
	const setLocation = () => {
		let realLocations = [];
		if (data) {
			let municipalities = data[0].municipalities;
			const { hover, stroke, stroke_width } = data[0].state[0].tabs[tab];
			// colors from the data object
			let svgColors = data[0].state[0].tabs[tab].groups;
			data.map.locations[0].g[0].g.map(location => {
				let targetState = municipalities.find(
					state => state.node === location.id
				);
				let color;
				// if macth
				if (targetState) {
					//item information
					const { group, range } = targetState.data[tab];

					// set color from db match
					if (group) {
						color = svgColors[group - 1].color;
					}
					realLocations.push({
						...location,
						name: targetState.name,
						hover,
						range,
						group,
						stroke,
						stroke_width,
						_id: targetState.id,
						fill: color ? color : 'transparent',
					});
				} 
			});
			setMunicipalities([...realLocations]);
		}
	};

	useEffect(() => {
		if (data) {
			setLocation();
		}
	}, [data]);

	return municipalities;
}
