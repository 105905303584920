import React from 'react';

export default function GroupList({ groups }) {
	return (
		<ul className="">
			{groups?.map((group, i) => {
				if (i === 3 && group.amount <= 0) return null;
				return (
					<li
						key={group._id}
						style={{
							'--color': group.color,
						}}
					>
						{group.text} {group.amount}
					</li>
				);
			})}
		</ul>
	);
}
