import React, { useEffect, useState } from 'react';
import client from '../../../../../api/client';
import { useLocation } from 'react-router-dom';
import { useLanguage } from './../../../../../context/Languages';

import DownloadList from '../../../../mapsHelpers/downloadList/downloadList';
import SharedButtons from '../../../../mapsHelpers/sharedbuttons/sharedButtons';
import { useHttpArgentina } from '../../../../../hooks/argentina/httpArgentina';

const RiskFactors = ({ state }) => {
	const { language } = useLanguage();
	let location = useLocation();
	// set data
	const [isLoading, data] = useHttpArgentina(
		`get_tab_argentina/${state}`,
		{tabcode:'risk_factors', lang:language?.lang},
		true,
		[state,language?.lang],
		true
	);
	
	return (
		<div className="content">
			<div className="row">
				<div className="col-12 col-lg-7">
					<div className="table-responsive mt-3">
						<table className="table oit-table table-bars">
							<thead>
								<tr>
									{data &&
										data[0].region[0].data.risk_factors
											.table.data[0].rural && (
											<th colspan="1" scope="col"></th>
										)}
									{data &&
										data[0].region[0].data.risk_factors.table.headers.titles.map(
											(header, index) => {
												let caba =
													data[0].region[0].data
														.risk_factors.table
														.data[0].rural;
												if (!caba && index > 0)
													return null;
												return (
													<th
														scope="col"
														colSpan={caba ? 3 : 4}
														key={header._id}
													>
														<h3 className="text-center">
															{header.description}
														</h3>
													</th>
												);
											}
										)}
								</tr>
								<tr>
									{data &&
										data[0].region[0].data.risk_factors.table.headers.subtitles.map(
											(header, index) => {
												let caba =
													data[0].region[0].data
														.risk_factors.table
														.data[0].rural;

												if (!caba && index > 3)
													return null;
												return (
													<th
														scope="col"
														key={
															header.description +
															index
														}
													>
														{header.description}
													</th>
												);
											}
										)}
								</tr>
							</thead>
							<tbody>
								{data &&
									data[0].region[0].data.risk_factors.table.data.map(
										(dataField, index, array) => {
											let oddsUrbano = array.reduce(
												(a, b) =>
													a +
													(+b.urbano.odds_ratio
														?.$numberDecimal |
														0),
												0
											);
											let oddsRural =
												dataField.rural &&
												array.reduce(
													(a, b) =>
														a +
														(+b.rural.odds_ratio
															?.$numberDecimal |
															0),
													0
												);

											// width for progress bar
											let widthUrbano =
												(dataField.urbano.odds_ratio
													?.$numberDecimal *
													100) /
												oddsUrbano;
											let widthRural =
												dataField.rural &&
												(dataField.rural.odds_ratio
													?.$numberDecimal *
													100) /
													oddsRural;

											// elimina la constante de la tabla
											if (index === array.length - 1) {
												return null;
											}
											if (!dataField?.urbano?.effect) {
												return null;
											}
											return (
												<tr key={dataField._id}>
													<td>
														{dataField.variable}
													</td>
													<td>
														<span
															className="text-danger font-weight-bolder"
															style={{
																fontSize:
																	'1rem',
															}}
														>
															{
																dataField.urbano
																	.effect
															}
														</span>
													</td>
													<td>
														{dataField.urbano.odds_ratio?.$numberDecimal.slice(
															0,
															3
														)}
													</td>
													<td>
														{dataField.urbano
															.odds_ratio && (
															<div className="progress">
																<div
																	className="progress-bar"
																	role="progressbar"
																	style={{
																		width: `${widthUrbano}%`,
																		backgroundColor:
																			dataField
																				.urbano
																				.color,
																	}}
																	aria-valuenow="15"
																	aria-valuemin="0"
																	aria-valuemax="247"
																></div>
															</div>
														)}
													</td>
													{dataField.rural && (
														<React.Fragment>
															<td>
																<span
																	className="text-danger font-weight-bolder"
																	style={{
																		fontSize:
																			'1rem',
																	}}
																>
																	{
																		dataField
																			.rural
																			.effect
																	}
																</span>
															</td>
															<td>
																{dataField.rural.odds_ratio?.$numberDecimal.slice(
																	0,
																	3
																)}
															</td>
															<td>
																{dataField.rural
																	.odds_ratio && (
																	<div className="progress">
																		<div
																			className="progress-bar"
																			role="progressbar"
																			style={{
																				width: `${widthRural}%`,
																				backgroundColor:
																					dataField
																						.rural
																						.color,
																			}}
																			aria-valuenow="15"
																			aria-valuemin="0"
																			aria-valuemax="247"
																		></div>
																	</div>
																)}
															</td>
														</React.Fragment>
													)}
												</tr>
											);
										}
									)}
							</tbody>
						</table>
					</div>

					<div className="row">
						{ data && <div
							className="col-12 p-3 text-justify"
							dangerouslySetInnerHTML={{
								__html:
								data[0].province[0].tabs.risk_factors
								.description &&
									data[0].province[0].tabs.risk_factors
										.description[1]
							}}
						></div>}
						</div>
				</div>
				<div className="col-12 col-lg-5">
					<div className="row">
					{ data &&	<div
							className="col-12 p-3 text-justify"
							dangerouslySetInnerHTML={{
								__html:
								data[0].province[0]?.tabs.risk_factors
										.description &&
									data[0].province[0]?.tabs.risk_factors
										.description[0],
							}}
						></div> }
					</div> 
					<div className="more-detail">
						<div className="row w-100">
							<div className="col-12 p-0">
								<div className="d-table ml-auto h-100">
									<div className="d-table-cell">
										<div className="share h-100">
											<p className="text">
												{
													language?.text.body
														.share_information
												}
											</p>
											<div className="oit-icon-group oit-icon-group-small icons-green">
												<SharedButtons
													url={`${location.pathname}`}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<DownloadList
							links={
								data &&
								data[0].province[0].tabs.risk_factors
									.more_descriptions.links
							}
							url={null}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RiskFactors;
