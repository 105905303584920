import React from 'react';

function MexicoFactorsGroups({ groups }) {
	return (
		<ul
			className=""
			style={{
				justifyContent: 'space-around',
				listStyle: 'none',
				display: 'flex',
				padding: '0',
				margin: 'none',
			}}
		>
			{groups?.map((group, i) => {
				return (
					<li
						key={group._id}
						style={{
							fontSize: '12px',
							margin: '5px',
							textAlign: 'center',
						}}
					>
						<span
							style={{
								backgroundColor: group.color,
								borderRadius: '50%',
								display: 'inline-block',
								width: '10px',
								height: '10px',
								marginRight: '5px',
							}}
						></span>
						{group.text.split(' ').length > 2 ? (
							<strong>
								{group.text.split(' ')[0]} <br></br>
								{group.text
									.split(' ')
									.slice(1)
									.join(' ')
									.replace(/\(|\)/g, '')}
							</strong>
						) : (
							<strong>
								{group.text} <br></br>
							</strong>
						)}
					</li>
				);
			})}
		</ul>
	);
}

export default React.memo(MexicoFactorsGroups);
