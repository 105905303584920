import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useLanguage } from './../../../../context/Languages';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// helpers
import SharedButtons from '../../../mapsHelpers/sharedbuttons/sharedButtons';
import DownloadList from '../../../mapsHelpers/downloadList/downloadList';
import MapsSpinner from '../../../mapsHelpers/mapsSpinner/mapsSpinner';
import Cluster from './clusterMap';

// css module
import styles from './mexico.module.css';
import './../../../../index.css';

import MapsTextSkeleton from '../../../mapsHelpers/mapsTextSkeleton/mapsTextSkeleton';
import { useHttpArgentina } from '../../../../hooks/argentina/httpArgentina';
import LikehoodMap from './likehoodMap';
import Tooltip from './tooltip';
import GroupList from '../../../mapsHelpers/groupList/groupList';

const ChildLabourLikelihood = () => {
	const { language } = useLanguage();
	const history = useHistory();
	let location = useLocation();
	const [isLoading, data] = useHttpArgentina(
		'get_tab_argentina/',
		{tabcode:'child_labour_likelihood', lang:language?.lang},

		true,
		[language?.lang]
	);
	const [states, setStates] = useState([]);
	const [hoverStateId, setHoverStateId] = useState(null);

	// map ref
	const mapRef = useRef();
	// tooltip ref
	const tooltipRef = useRef();

	// match svg location with backend
	const setLocation = () => {
		let realLocations = [];
		if (data) {
			let states = data.structure.provinces;
			let departments = data.structure.departments;
			let { groups } = data.config.tabs.child_labour_likelihood;
			let [
				provinceConfig,
				cantonConfig,
			] = data.config.tabs.child_labour_likelihood.config;
			data.map.locations.map(location => {
				let targetState = states.find(
					state => state.node === location.id
				);

				location['g'][0]['g'].map((cantonLocation, i) => {
					// find Canton
					let targetCanton = departments.find(
						canton => canton.node === cantonLocation.id
					);
					if (targetCanton) {
						// set color
						location['g'][0]['g'][i].fill =
							groups[
								targetCanton.data.child_labour_likelihood
									.province.group - 1
							].color;
					}
				});

				let color;
				// if macth
				if (targetState) {
					// info for province node
					//  targetState)
					let provinceInfoGroup =
						targetState.data.child_labour_likelihood.ranges;

					// set color from db match
					// color = svgColors[group - 1].color;
					realLocations.push({
						...location,
						fill: '#fff',
						name: targetState.name,
						provinceConfig,
						cantonConfig,
						provinceInfoGroup,
					});
				} else {
					// location)
				}
			});
			setStates(realLocations);
		}
	};

	// set mouse event for tooltip, always tracking the mouse
	useEffect(() => {
		const mouseMove = mouse => {
			const tooltip = tooltipRef.current;
			const mapContainerPsition = mapRef.current.getBoundingClientRect();
			const { pageX, pageY } = mouse;
			if (!tooltip) {
				return;
			}
			let scrollTop =
				window.pageYOffset !== undefined
					? window.pageYOffset
					: (
							document.documentElement ||
							document.body.parentNode ||
							document.body
					  ).scrollTop;
			tooltip.style.top = `${
				pageY - mapContainerPsition.top - 180 - scrollTop
			}px`;
			tooltip.style.left = `${pageX - mapContainerPsition.left - 10}px`;
		};
		// for track the cursor
		document.addEventListener('mousemove', mouseMove);
		return () => {
			// remove cursdor event
			document.removeEventListener('mousemove', mouseMove);
		};
	}, []);

	const svhHoverHandler = (e, info) => {
		setHoverStateId([e.currentTarget.id, info]);
	};

	const svhblurHandler = e => {
		setHoverStateId(null);
	};

	useEffect(() => {
		if (data) {
			setLocation();
		} 
	}, [data]);

	// click
	const svgClickHandler = e => {
		history.push(
			`/argentina/${e.currentTarget.id}/child-labour-likelihood`
		);
	};

	return (
		<div className="row">
			<div className="col-sm-7 left">
				<div ref={mapRef} className="mb-4">
					<Tooltip
						hoverStateId={hoverStateId}
						tooltipRef={tooltipRef}
						groups={
							data?.config.tabs.child_labour_likelihood.groups
						}
					/>

					{isLoading ? (
						<MapsSpinner />
					) : (
						<LikehoodMap
							locations={states}
							viewBox={data?.map.viewBox}
							svhHoverHandler={svhHoverHandler}
							svhblurHandler={svhblurHandler}
							svgClickHandler={svgClickHandler}
						/>
					)}
				</div>
			</div>

			<div className="col-sm-5 right">
				{isLoading ? (
					<MapsTextSkeleton title={true} count={5} />
				) : (
					<React.Fragment>
						<div
							className={[styles.FadeIn, "text-justify"].join(" ")}
							dangerouslySetInnerHTML={{
								__html:
									data?.config.tabs.child_labour_likelihood
										.description[0],
							}}
						>
							{/* En México hay 32 entidades federativas, de las cuales estan
						en: */}
						</div>

						<GroupList
							groups={
								data?.config.tabs.child_labour_likelihood.groups
							}
						/>

						<div
							className={[styles.FadeIn, "text-justify"].join(" ")}
							dangerouslySetInnerHTML={{
								__html:
									data?.config.tabs.child_labour_likelihood
										.description[1],
							}}
						></div>

						<div
							className={[styles.FadeIn, "text-justify"].join(" ")}
							dangerouslySetInnerHTML={{
								__html:
									data?.config.tabs.child_labour_likelihood
										.description[2],
							}}
						>
						</div>
						<div className="more-detail">
							<div className="row w-100">
								<div className="col-6"></div>
								<div className="col-6 p-0">
									<div className="d-table h-100">
										<div className="d-table-cell">
											<div className="share h-100">
												<p className="text">
													{
														language?.text.body
															.share_information
													}
												</p>
												<div className="oit-icon-group oit-icon-group-small icons-green">
													<SharedButtons
														url={`${location.pathname}`}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<DownloadList
							links={
								data?.config.tabs.child_labour_likelihood
									.more_descriptions.links
							}
							url="get_mexico_pdf/national_table"
						/>
					</React.Fragment>
				)}
			</div>
		</div>
	);
};

export default ChildLabourLikelihood;
