import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useLanguage } from './../../../../context/Languages';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// custon hooks
import { useHttp } from '../../../../hooks/http';

// helpers
import SharedButtons from '../../../mapsHelpers/sharedbuttons/sharedButtons';
import DownloadList from '../../../mapsHelpers/downloadList/downloadList';
import MapsSpinner from '../../../mapsHelpers/mapsSpinner/mapsSpinner';
import Cluster from './clusterMap';

// css module
import styles from './mexico.module.css';
import './../../../../index.css';

import MapsTextSkeleton from '../../../mapsHelpers/mapsTextSkeleton/mapsTextSkeleton';
import GroupList from '../../../mapsHelpers/groupList/groupList';
import useLikehoohSvgMatch from './hooks/useLikehoodSvgMatch';
import LikehoodMap from './maps/likehoodMap';
import BrasilTooltip from './brasilTooltip';

const ChildLabourLikelihood = () => {
	const { language } = useLanguage();
	let location = useLocation();
	const history = useHistory();
	const [isLoading, data] = useHttp(
		'get_tab_brasil/',
		{tabcode:'child_labour_likelihood', lang:language?.lang},

		true,
		[language?.lang]
	);
	const states = useLikehoohSvgMatch(data);
	const [hoverStateId, setHoverStateId] = useState(null);

	// map ref
	const mapRef = useRef();
	// tooltip ref
	const tooltipRef = useRef();

	// set mouse event for tooltip, always tracking the mouse
	useEffect(() => {
		const mouseMove = mouse => {
			const tooltip = tooltipRef.current;
			const mapContainerPsition = mapRef.current.getBoundingClientRect();
			const { pageX, pageY } = mouse;
			if (!tooltip) {
				return;
			}
			let scrollTop =
				window.pageYOffset !== undefined
					? window.pageYOffset
					: (
							document.documentElement ||
							document.body.parentNode ||
							document.body
					  ).scrollTop;
			tooltip.style.top = `${
				pageY - mapContainerPsition.top - 80 - scrollTop
			}px`;
			tooltip.style.left = `${pageX - mapContainerPsition.left - 10}px`;
		};
		// for track the cursor
		document.addEventListener('mousemove', mouseMove);
		return () => {
			// remove cursdor event
			document.removeEventListener('mousemove', mouseMove);
		};
	}, []);

	// hover handler
	const svhHoverHandler = (e, info) => {
		setHoverStateId([e.currentTarget.id, info]);
	};
	// blur handler
	const svhblurHandler = e => {
		setHoverStateId(null);
	};
	// click handler
	const svgClickHandler = e => {
		history.push(`/brasil/${e.currentTarget.id}/child-labour-likelihood`);
	};

	return (
		<div className="row">
			<div className="col-sm-7 left">
				<div ref={mapRef} className="mb-4">
					<BrasilTooltip
						hoverStateId={hoverStateId}
						tooltipRef={tooltipRef}
					/>
					{isLoading ? (
						<MapsSpinner />
					) : (
						data?.map?.viewBox &&
						states.length > 0 && (
							<LikehoodMap
								data={data}
								states={states}
								svgClickHandler={svgClickHandler}
								svhHoverHandler={svhHoverHandler}
								svhblurHandler={svhblurHandler}
								viewBox={data?.map?.viewBox}
							/>
						)
					)}
				</div>
			</div>

			<div className="col-sm-5 right">
				{isLoading ? (
					<MapsTextSkeleton title={true} count={5} />
				) : (
					<React.Fragment>
						<div
							className={[styles.FadeIn, "text-justify"].join(" ")}
							dangerouslySetInnerHTML={{
								__html:
									data?.config.tabs.child_labour_likelihood
										.description[0],
							}}
						>
							{/* En México hay 32 entidades federativas, de las cuales estan
						en: */}
						</div>
						<GroupList
							groups={
								data?.config.tabs.child_labour_likelihood.groups
							}
						/>
						<div
							className={[styles.FadeIn, "text-justify"].join(" ")}
							dangerouslySetInnerHTML={{
								__html:
									data?.config.tabs.child_labour_likelihood
										.description[1],
							}}
						>
						
						</div>
						<div className="more-detail">
							<div className="row w-100">
								<div className="col-6">
									<a
										href="javascript:void(0)"
										data-toggle="modal"
										data-target="#exampleModalLong2"
									>
										<button className="more-detail">
											MAPA DE CLUSTERS
										</button>
									</a>
								</div>
								<div className="col-6 p-0">
									<div className="d-table h-100">
										<div className="d-table-cell">
											<div className="share h-100">
												<p className="text">
													{
														language?.text.body
															.share_information
													}
												</p>
												<div className="oit-icon-group oit-icon-group-small icons-green">
													<SharedButtons
														url={`${location.pathname}`}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<DownloadList
							links={
								data?.config.tabs.child_labour_likelihood
									.more_descriptions.links
							}
							url="get_brasil_pdf/national_table"
						/>
					</React.Fragment>
				)}
			</div>
			<Cluster
				data={data?.config.tabs.child_labour_likelihood.clusters}
			/>
		</div>
	);
};

export default ChildLabourLikelihood;
