import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from './../../../../../context/Languages';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// custon hooks
import { useHttp } from './../../../../../hooks/http';
// maps helpers
import SharedButtons from '../../../../mapsHelpers/sharedbuttons/sharedButtons';
import MapsSpinner from '../../../../mapsHelpers/mapsSpinner/mapsSpinner';

// css module
import styles from '../mexico.module.css';
import './../../../../../index.css';

import MapsTextSkeleton from '../../../../mapsHelpers/mapsTextSkeleton/mapsTextSkeleton';
import UseSvgMatch from '../hooks/useSvgMatch';
import ChildLabourMap from '../maps/childLabourMap';
import useSvgMatchSubNacional from '../hooks/useSvgMatchSubNacional';
import Tooltip from './tooltip';

const ChildLabour = ({ state }) => {
	const { language } = useLanguage();
	let location = useLocation();
	const [isLoading, data] = useHttp(
		`get_tab_brasil/${state}`,
		{tabcode:'child_labour', lang:language?.lang},

		true,
		[state,language?.lang],
		true
	);
	const municipalities = useSvgMatchSubNacional(data, 'child_labour');
	const [hoverStateId, setHoverStateId] = useState(null);

	// map ref
	const mapRef = useRef(null);
	// tooltip ref
	const tooltipRef = useRef(null);

	// set mouse event for tooltip, always tracking the mouse
	useEffect(() => {
		const mouseMove = mouse => {
			const tooltip = tooltipRef.current;
			const mapContainerPsition = mapRef.current.getBoundingClientRect();
			const { pageX, pageY } = mouse;
			if (!tooltip) {
				return;
			}
			let scrollTop =
				window.pageYOffset !== undefined
					? window.pageYOffset
					: (
							document.documentElement ||
							document.body.parentNode ||
							document.body
					  ).scrollTop;
			tooltip.style.top = `${
				pageY - mapContainerPsition.top - 80 - scrollTop
			}px`;
			tooltip.style.left = `${pageX - mapContainerPsition.left - 10}px`;
		};
		// for track the cursor
		document.addEventListener('mousemove', mouseMove);
		return () => {
			// remove cursdor event
			document.removeEventListener('mousemove', mouseMove);
		};
	}, []);

	const svhHoverHandler = (e, info) => {
		setHoverStateId([e.currentTarget.id, info]);
	};

	const svhblurHandler = e => {
		setHoverStateId(null);
	};

	return (
		<div className="row">
			<div className="col-sm-7 left">
				<div ref={mapRef}>
					<Tooltip
						hoverStateId={hoverStateId}
						tooltipRef={tooltipRef}
					/>
					{isLoading ? (
						<MapsSpinner />
					) : (
						data?.map?.viewBox && (
							<ChildLabourMap
								viewBox={data?.map?.viewBox}
								states={municipalities}
								svgClickHandler={null}
								svhHoverHandler={svhHoverHandler}
								svhblurHandler={svhblurHandler}
							/>
						)
					)}
				</div>
			</div>
			<div class="col-sm-5 right">
				{isLoading ? (
					<MapsTextSkeleton title={true} count={5} />
				) : (
					<React.Fragment>
						{data && (
							<div
								className={styles.FadeIn}
								dangerouslySetInnerHTML={{
									__html:
										data[0].state[0].tabs.child_labour
											.description,
								}}
							></div>
						)}

						<div class="row w-100 more-detail">
							<div class="col-12 p-0">
								<div class="d-table h-100">
									<div class="d-table-cell">
										<div class="d-flex h-100 share">
											<p class="text">
												{
													language?.text.body
														.share_information
												}
											</p>
											<div class="oit-icon-group icons-green">
												<SharedButtons
													url={`${location.pathname}`}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</React.Fragment>
				)}
			</div>
		</div>
	);
};

export default ChildLabour;
