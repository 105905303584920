import React from 'react';
import childs from './../../../assets/images/childs-3.svg';
import { useLanguage } from './../../../context/Languages';

export default function MoreDetails({ data }) {
	const { language } = useLanguage();

	if (!data) {
		return null;
	}

	return (
		<div
			className="modal p-0 fade sidebar-modal"
			id="exampleModalLong"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="exampleModalLongTitle"
			aria-hidden="true"
		>
			<div className="modal-dialog m-0 ml-auto" role="document">
				<div className="modal-content">
					<div className="modal-body">
						<div>
							<div className="row nav ">
								<div className="col-sm-4 left p-0">
									<a
										data-dismiss="modal"
										href="index.html"
										className="back-button"
									>
										{' '}
										<i className="fas fa-arrow-left"></i>{' '}
										&nbsp; {language?.text.button.back_map}
									</a>
								</div>
								<div className="col-sm-8 right p-0"></div>
							</div>
							<div className="content">
								<div className="row">
									<div
										className="col-sm-7 text-justify more-details"
										dangerouslySetInnerHTML={{
											__html: data.text
										}}
									></div>
									<div className="col-sm-5">
										<div className="downloads">
											<ul className="downloads-list ">
												{language?.text.continent.latinoamerica.more_descriptions.links.map(
													(link, index, array) => {
														if (!link.url)
															return null;
														return (
															<div
																key={link._id}
																className={
																	index ===
																	array.length -
																		1
																		? ''
																		: 'mb-3'
																}
															>
																<a
																	href={
																		link.url
																	}
																	key={
																		link._id
																	}
																	target="_blank"
																>
																	<li
																		className="downloads-list-item"
																		style={{
																			flexDirection:
																				'column',
																			height:
																				'auto',
																		}}
																	>
																		<h3
																			className="downloads-list-item-title"
																			style={{
																				lineHeight:"20px",
																				alignSelf:
																					'normal',
																					
																			}}
																		>
																			{
																				link.title
																			}
																		</h3>
																	</li>
																</a>
															</div>
														);
													}
												)}
											</ul>
										</div>
										<div className="text-center">
											<img
												style={{
													width: '100%',
													marginTop: '-70px',
												}}
												className="image"
												src={childs}
												alt="childs"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
