import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Latinoamerica from './views/america/';
import { GroupsProvider } from './context/Groups';
import { LanguageProvider } from './context/Languages';

import Layout from './views/layouts/Layout';

export default function Routes() {
	return (
		<Router>
			<LanguageProvider>
				<GroupsProvider>
					<Layout>
						<Switch>
							<Route path="/">
								<Latinoamerica />
							</Route>
						</Switch>
					</Layout>
				</GroupsProvider>
			</LanguageProvider>
		</Router>
	);
}
