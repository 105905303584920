import React, { useState, useEffect } from 'react';

export default function useSetLocation(data) {
	const [locations, setLocations] = useState([]);
	const setLocation = () => {
		let realLocations = [];
		if (data) {
			let municipalities = data[0].communes;
			const {
				stroke,
				stroke_width,
			} = data[0].region[0].tabs.child_labour_vulnerable;
			// colors from the data object
			let svgColors =
				data[0].region[0].tabs.child_labour_vulnerable.groups;
			data.map.locations.map(location => {
				let targetState = municipalities.find(
					state =>
						state.node === location.id ||
						state.node === location['data-name']
				);
				let color;
				// set element color
				if (targetState) {
					const {
						group,
						range,
					} = targetState.data.child_labour_vulnerable;

					// set color from db match
					if (!group) {
						color = 'white';
					} else {
						color = svgColors[group - 1].color;
					}

					realLocations.push({
						...location,
						name: targetState.name,
						color,
						fill: color,
						range,
						group,
						stroke,
						stroke_width,
						_id: targetState.id,
					});
				} 

				setLocations(realLocations);
			});
		}
	};

	useEffect(() => {
		if (data) {
			setLocation();
		}
	}, [data]);

	return locations;
}
