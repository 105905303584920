import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useLanguage } from './../../../../context/Languages';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// custon hooks
import { useHttp } from '../../../../hooks/http';

// helpers
import SharedButtons from '../../../mapsHelpers/sharedbuttons/sharedButtons';
import DownloadList from '../../../mapsHelpers/downloadList/downloadList';
import MapsSpinner from '../../../mapsHelpers/mapsSpinner/mapsSpinner';
import {
	groupBullet,
	rangeHelper,
} from '../../../mapsHelpers/costaRicaTooltipHelper/costaRicaTooltiphelper';

// css module
import styles from './mexico.module.css';
import './../../../../index.css';

import MapsTextSkeleton from '../../../mapsHelpers/mapsTextSkeleton/mapsTextSkeleton';
import LikehoodGroups from '../../../../components/likehoodGroups';
import UseSvgMatch from './hooks/useSvgMatch';

const ChildLabourLikelihood = () => {
	const { language } = useLanguage();
	let location = useLocation();
	const history = useHistory();
	const [states, setStates] = useState([]);
	const [isLoading, data] = useHttp(
		'get_tab_jamaica/',
		{ tabcode: 'child_labour_likelihood', lang: language?.lang },

		true,
		[language?.lang]
	);
	const constituencies = UseSvgMatch(data);
	const [hoverStateId, setHoverStateId] = useState(null);
	// console.log('data child labour', data);
	// map ref
	const mapRef = useRef();
	// tooltip ref
	const tooltipRef = useRef();

	// set mouse event for tooltip, always tracking the mouse
	useEffect(() => {
		const mouseMove = mouse => {
			const tooltip = tooltipRef.current;
			const mapContainerPsition = mapRef.current.getBoundingClientRect();
			const { pageX, pageY } = mouse;
			if (!tooltip) {
				return;
			}
			let scrollTop =
				window.pageYOffset !== undefined
					? window.pageYOffset
					: (
							document.documentElement ||
							document.body.parentNode ||
							document.body
					  ).scrollTop;
			tooltip.style.top = `${
				pageY - mapContainerPsition.top - 180 - scrollTop
			}px`;
			tooltip.style.left = `${pageX - mapContainerPsition.left - 10}px`;
		};
		// for track the cursor
		document.addEventListener('mousemove', mouseMove);
		return () => {
			// remove cursdor event
			document.removeEventListener('mousemove', mouseMove);
		};
	}, []);

	// set locations
	const setLocation = () => {
		let realLocations = [];
		if (data) {
			let {
				county,
				constituency: constituencies,
				parish: parishs,
			} = data.structure;
			let [districtConfig, stateConfig] =
				data.config.tabs.child_labour_likelihood.config;
			// colors from the data object

			let svgColors = data.config.tabs.child_labour_likelihood.groups;
			data.map.locations.map((location, i) => {
				// console.log("location map", location)
				let parishInfo = [];
				let targetCounty = county.find(region => {
					return region.node === location.id;
				});
				if (targetCounty) {
					location.name = targetCounty?.name;
				}
				// go down to districts level
				// console.log("parishs", parishs)
				location.g[0].g.forEach((parish, parishIndex) => {
					let targetParish = parishs.find(p => p.node === parish.id);
					if (targetParish) {
						parishInfo[parishIndex] =
							targetParish.data.child_labour_likelihood.ranges;
						location.g[0].g[parishIndex].name = targetParish.name;

						location.g[0].g[parishIndex].g.forEach(
							(constituency, constituencyIndex) => {
								let targetConstituency = constituencies.find(
									c => c.node === constituency.id
								);

								// if macth
								if (targetConstituency) {
									//item information
									const { group, range } =
										targetConstituency.data
											.child_labour_likelihood;

									// set color from db match
									let color;
									if (group) {
										color = svgColors[group - 1].color;
									} else {
										color = 'white';
									}
									// assign color
									location.g[0].g[parishIndex].g[
										constituencyIndex
									].name = targetConstituency.name;
									location.g[0].g[parishIndex].g[
										constituencyIndex
									].fill = color;
									location.g[0].g[parishIndex].g[
										constituencyIndex
									].group = group;
									location.g[0].g[parishIndex].g[
										constituencyIndex
									].color = color;
									location.g[0].g[parishIndex].g[
										constituencyIndex
									].range = range;
								} else {
									location.g[0].g[parishIndex].g[
										constituencyIndex
									].fill = 'white';
								}
							}
						);
					}
				});

				if (targetCounty) {
					realLocations.push({
						...location,
						// name: targetRegion.name,
						stateConfig,
						districtConfig,
						parishInfo,
					});
				} else {
					// console.log(
					// 	'ESTO NO ESTA HACIENDO MATCH SE SUPNE QUE SERIA UNA REGION',
					// 	location
					// );
				}
			});
			setStates([...realLocations]);
		}
	};

	// hover handler

	const svgHoverHandler = (e, info) => {
		console.log('info', info);
		setHoverStateId([e.currentTarget.id, info]);
	};

	const svgblurHandler = e => {
		setHoverStateId(null);
	};

	const svgClickHandler = e => {
		console.log("click", e)
		history.push(
			`/jamaica/${e.currentTarget.id}/child-labour-likelihood`
		);
	};

	useEffect(() => {
		if (data) {
			setLocation();
		}
	}, [data]);
	return (
		<div className="row">
			<div className="col-sm-7 left">
				<div ref={mapRef} className="mb-4">
					<TransitionGroup>
						{hoverStateId && (
							<CSSTransition
								in={hoverStateId}
								timeout={200}
								classNames="my-node"
							>
								<div
									className={styles.Tooltip}
									ref={tooltipRef}
								>
									<h4 className="mb-0">
										{hoverStateId[1]?.name}
									</h4>
									<p style={{ margin: '2px' }}>
										<small className="mb-2">
											{groupBullet(
												data?.config.tabs
													.child_labour_likelihood
													.groups[0]
											)}{' '}
											{
												data?.config.tabs
													.child_labour_likelihood
													.groups[0].text
											}
											{rangeHelper(
												hoverStateId[1].info[0].range
											)}
										</small>
									</p>
									<p style={{ margin: '2px' }}>
										<small className="mb-2">
											{groupBullet(
												data?.config.tabs
													.child_labour_likelihood
													.groups[1]
											)}{' '}
											{
												data?.config.tabs
													.child_labour_likelihood
													.groups[1].text
											}
											{rangeHelper(
												hoverStateId[1].info[1].range
											)}
										</small>
									</p>
									<p style={{ margin: '2px' }}>
										<small className="mb-2">
											{groupBullet(
												data?.config.tabs
													.child_labour_likelihood
													.groups[2]
											)}{' '}
											{
												data?.config.tabs
													.child_labour_likelihood
													.groups[2].text
											}
											{rangeHelper(
												hoverStateId[1].info[2].range
											)}
										</small>
									</p>
								</div>
							</CSSTransition>
						)}
					</TransitionGroup>

					{isLoading ? (
						<MapsSpinner />
					) : (
						data?.map.viewBox && (
							<svg
								className={styles.FadeIn}
								xmlns="http://www.w3.org/2000/svg"
								viewBox={data?.map.viewBox}
							>
								{states?.map(location => {
									return (
										<g
											id={location.id}
											key={location.id}
											transform={location.transform}
										>
											{/* {console.log('location', location)} */}
											{location.g[0].g.map(
												(parish, parishIndex) => {
													// console.log("location.parishInfo[parishIndex]", location.parishInfo[parishIndex])
													return (
														<g
														onClick={svgClickHandler}
															onMouseLeave={
																svgblurHandler
															}
															onMouseEnter={e =>
																svgHoverHandler(
																	e,
																	{
																		name: parish.name,
																		info: [
																			...location
																				.parishInfo[
																				parishIndex
																			],
																		],
																	}
																)
															}
															style={{
																cursor: 'pointer',
															}}
															id={parish.id}
														>
															{parish.g.map(
																parishGroup => {
																	return (
																		<g>
																			{parishGroup.paths.map(
																				p => {
																					return (
																						<path
																							key={
																								parishGroup.id
																							}
																							stroke={
																								location
																									.districtConfig
																									.stroke
																							}
																							strokeWidth={
																								location
																									.districtConfig
																									.stroke_width
																							}
																							fill={
																								parishGroup.fill
																							}
																							d={
																								p.d
																							}
																							transform={
																								parishGroup.transform
																							}
																						/>
																					);
																				}
																			)}
																		</g>
																	);
																}
															)}
														</g>
													);
												}
											)}
											{location.g[1].paths.map(path => (
												<g>
													<path
														id={path.id}
														key={path.id}
														stroke={
															location.stateConfig
																.stroke
														}
														strokeWidth={
															location.stateConfig
																.stroke_width
														}
														fill={'none'}
														d={path.d}
														transform={
															path.transform
														}
													/>
												</g>
											))}
										</g>
									);
								})}
							</svg>
						)
					)}
				</div>
			</div>

			<div className="col-sm-5 right">
				{isLoading ? (
					<MapsTextSkeleton title={true} count={5} />
				) : (
					data && (
						<div className={styles.FadeIn}>
							<LikehoodGroups
								child_labour_likelihood={
									data?.config.tabs.child_labour_likelihood
								}
							/>

							<div className="more-detail">
								<div className="row w-100">
									<div className="col-6"></div>
									<div className="col-6 p-0">
										<div className="d-table h-100">
											<div className="d-table-cell">
												<div className="share h-100">
													<p className="text">
														{
															language?.text.body
																.share_information
														}
													</p>
													<div className="oit-icon-group oit-icon-group-small icons-green">
														<SharedButtons
															url={`${location.pathname}`}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<DownloadList
								links={
									data?.config.tabs.child_labour_likelihood
										.more_descriptions.links
								}
								url="get_colombia_pdf/national_table"
							/>
						</div>
					)
				)}
			</div>
		</div>
	);
};

export default ChildLabourLikelihood;
