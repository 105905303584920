import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../../../../../context/Languages';

// custon hooks
import { useHttp } from '../../../../../hooks/http';
import useSvgMatchSubNacional from '../chilehooks/useSvgMatchSubNacional';

// components
import RegularSvgMap from '../../../../mapsHelpers/regularSvgMap/regularSvgMap';
import SharedButtons from '../../../../mapsHelpers/sharedbuttons/sharedButtons';
import MapsTextSkeleton from '../../../../mapsHelpers/mapsTextSkeleton/mapsTextSkeleton';

// css module
import MapsSpinner from '../../../../mapsHelpers/mapsSpinner/mapsSpinner';
import DownloadList from '../../../../mapsHelpers/downloadList/downloadList';
import Tooltip from '../tooltip';
import GroupList from '../../../../mapsHelpers/groupList/groupList';

const VulnerabilityToChildLabor = ({ state }) => {
	const { language } = useLanguage();
	let location = useLocation();
	const [isLoading, data] = useHttp(
		`get_tab_chile/${state}`,

		{ tabcode: 'child_labour_vulnerable', lang: language?.lang },

		true,
		[state, language?.lang],
		true
	);
	const municipalities = useSvgMatchSubNacional(data);
	const [hoverStateId, setHoverStateId] = useState(null);

	// map ref
	const mapRef = useRef(null);
	// tooltip ref
	const tooltipRef = useRef(null);

	// set mouse event for tooltip, always tracking the mouse
	useEffect(() => {
		const mouseMove = mouse => {
			const tooltip = tooltipRef.current;
			const mapContainerPsition = mapRef.current.getBoundingClientRect();
			const { pageX, pageY } = mouse;
			if (!tooltip) {
				return;
			}
			let scrollTop =
				window.pageYOffset !== undefined
					? window.pageYOffset
					: (
							document.documentElement ||
							document.body.parentNode ||
							document.body
					  ).scrollTop;
			tooltip.style.top = `${
				pageY - mapContainerPsition.top - 100 - scrollTop
			}px`;
			tooltip.style.left = `${pageX - mapContainerPsition.left - 10}px`;
		};
		// for track the cursor
		document.addEventListener('mousemove', mouseMove);
		return () => {
			// remove cursdor event
			document.removeEventListener('mousemove', mouseMove);
		};
	}, []);

	const svhHoverHandler = (e, info) => {
		setHoverStateId([e.currentTarget.id, info]);
	};

	const svhblurHandler = e => {
		setHoverStateId(null);
	};

	return (
		<div className="row">
			<div className="col-sm-7 left">
				<div ref={mapRef}>
					<Tooltip
						hoverStateId={hoverStateId}
						tooltipRef={tooltipRef}
						groups={
							data &&
							data[0].region[0].tabs.child_labour_vulnerable
								.groups
						}
					/>
					{isLoading ? (
						<MapsSpinner />
					) : (
						data?.map.viewBox && (
							<RegularSvgMap
								data={data}
								municipalities={municipalities}
								svhblurHandler={svhblurHandler}
								svhHoverHandler={svhHoverHandler}
							/>
						)
					)}
				</div>
			</div>
			<div className="col-sm-5 right">
				{isLoading ? (
					<MapsTextSkeleton title={true} count={6} />
				) : (
					<React.Fragment>
						{data && (
							<div
								className="mb-5 text-justify"
								dangerouslySetInnerHTML={{
									__html: data[0].region[0].tabs
										.child_labour_vulnerable.description[0],
								}}
							></div>
						)}

						<GroupList
							groups={
								data &&
								data[0].region[0].tabs.child_labour_vulnerable
									.groups
							}
						/>

						{data && (
							<div
								className="mb-5 text-justify"
								dangerouslySetInnerHTML={{
									__html: data[0].region[0].tabs
										.child_labour_vulnerable.description[1],
								}}
							></div>
						)}
						{data && (
							<div
								className="mb-5 text-justify"
								dangerouslySetInnerHTML={{
									__html: data[0].region[0].tabs
										.child_labour_vulnerable.description[2],
								}}
							></div>
						)}

						<div className="more-detail">
							<div className="row w-100">
								<div className="col-6 ml-auto p-0">
									<div className="d-table  h-100">
										<div className="d-table-cell">
											<div className="share h-100">
												<p className="text">
													{
														language?.text.body
															.share_information
													}
												</p>
												<div className="oit-icon-group oit-icon-group-small icons-green">
													<SharedButtons
														url={`${location.pathname}`}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<DownloadList
							links={
								data &&
								data[0].region[0].tabs.child_labour_vulnerable
									.more_descriptions.links
							}
						/>
					</React.Fragment>
				)}
			</div>
		</div>
	);
};

export default VulnerabilityToChildLabor;
