import React from 'react';
import heart from './../../assets/images/corazon.svg';
import { useLanguage } from './../../context/Languages';

const heartStyles = {
	backgroundColor: '#f0625f',
	backgroundImage:
		'linear-gradient(0deg, #f0625f 0%, #2eb6e8 30%, #bfd62c 66%, #fce24e 100%)',
};

const logosSizes = ['44px', '44px', '56px', '66px' ]
const Footer = () => {
	const { language } = useLanguage();
	return (
		<React.Fragment>
			<footer>
				<div className=" content">
					<div className="row brands-container">
						<div className="col-12 brands d-flex align-items-center">
							{language?.static.footer.map((logo, index) => (
								<img
									key={logo}
									src={logo}
									style={{
										height: logosSizes[index]
									}}
								/>
							))}
						</div>
					</div>

					<div className="">
						{/* <ul className="links content-footer oit">
							{language?.text.footer.legal.map((legalitem, i) => {
								return (
									<li
										key={legalitem + i}
										className="item-footer oit"
									>
										<a
											className="item-link oit"
											href="#"
											key={legalitem._id}
										>
											{legalitem}
										</a>
									</li>
								);
							})}
						</ul> */}
						<div className="text">
							<div className="container">
								<p className="paragraph text-justify">
									{language?.text.footer.disclaimer}
								</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
			<div class=" text-center ">
				<p class="font-bold p-1">
					Crafted with
					<img
						src={heart}
						style={{
							display: 'inline-block',
							margin: '0 10px',
							width: '20px',
						}}
						alt="programate.pe heart"
					/>
					by{' '}
					<a className=" text-dark" href="http://programate.pe/">
						Prográmate.pe
					</a>
				</p>
			</div>
		</React.Fragment>
	);
};
export default Footer;
