import React from 'react';
import styles from './mexico.module.css';

function LikehoodMap({
	locations,
	viewBox,
	svhHoverHandler,
	svhblurHandler,
	svgClickHandler,
}) {
	return (
		<svg
			className={styles.FadeIn}
			xmlns="http://www.w3.org/2000/svg"
			viewBox={viewBox}
		>
			{locations.map(location => {
				return (
					<g
						id={location.id}
						fill="white"
						key={location.id}
						style={{ cursor: 'pointer' }}
						onMouseLeave={svhblurHandler}
						onMouseEnter={e =>
							svhHoverHandler(e, {
								info: location.provinceInfoGroup,
								name: location.name,
							})
						}
						onClick={svgClickHandler}
					>
						<g id={location.g[1].id}>
							{location.g[1].paths.map(contornoProvincia => {
								return (
									<path
										key={contornoProvincia.id}
										d={contornoProvincia.d}
										stroke={location.provinceConfig.stroke}
										strokeWidth={
											location.provinceConfig.stroke_width
										}
									/>
								);
							})}
						</g>

						<g id={location.g[0].id}>
							{location.g[0].g.map(group => {
								return (
									<g key={group.id} id={group.id}>
										{group.paths?.map(path => {
											return (
												<path
													key={path.id}
													id={path.id}
													d={path.d}
													fill={group.fill}
													stroke={
														location.cantonConfig
															.stroke
													}
													strokeWidth={
														location.cantonConfig
															.stroke_width
													}
												/>
											);
										})}
									</g>
								);
							})}
						</g>
					</g>
				);
			})}
		</svg>
	);
}

export default React.memo(LikehoodMap, (propsPrev, nextProps) => {
	if (propsPrev.locations.length === nextProps.locations.length) {
		return true;
	}

	return false;
});
