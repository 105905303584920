import React from 'react';
import ChildLabour from './ChildLabour';
import ChildLabourLikelihood from './ChildLabourLikelihood';
import RiskFactors from './RiskFactors';
import ComplementaryFactors from './ComplementaryFactors';
import { useLanguage } from '../../../../context/Languages';
import { useHistory, useLocation } from 'react-router-dom';

import { Link } from 'react-router-dom';
import { useGroups } from '../../../../context/Groups';

const Colombia = () => {
	let { groups, selectedCountry } = useGroups();
	const { language } = useLanguage();
	let countryName = groups?.rows[0].groups[0].countries.find(
		country => country.node === selectedCountry
	).name;
	let location = useLocation();
	let history = useHistory();
	// const [tab, setTab] = useState('');

	const clickHandler = path => {
		history.push(`/colombia/${path}`);
	};
	return (
		<div className="country">
			<div className="row nav container">
				<div className="col-12 col-md-2 col-sm-4 text-dark left">
					<Link to="/" className="back-button">
						<i className="fas fa-arrow-left"></i> &nbsp;
						{language?.text.nav.back_main}
					</Link>
				</div>
				<div className="col-12 col-md-10 right mt-3 mt-md-0">
					<h3 className="title text-left text-md-right">
						{language?.text.model.MIRTI}
					</h3>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-12 tabs">
						<div className="row">
							<div className=" col-sm-12 col-md-12 col-lg-2 mb-md-2 tab-left text-center text-md-left">
								<span className="title">{countryName}</span>
							</div>
							<div className="col-sm-12 col-lg-10 tab-right">
								<ul
									className="d-none d-sm-flex nav nav-tabs"
									id="myTab"
									role="tablist"
								>
									{/* <li className="nav-item">
										<a
											onClick={() =>
												clickHandler('child-labour')
											}
											className={[
												'nav-link',
												location.pathname.split(
													'/'
												)[2] === 'child-labour'
													? 'active'
													: '',
											].join(' ')}
											id="child-labour-tab"
											data-toggle="tab"
											href="#child-labour"
											role="tab"
											aria-controls="child-labour"
											aria-selected="false"
										>
											{
												language?.text.tab.colombia
													.country[0]
											}
										</a>
									</li>
									 */}

									<li className="nav-item">
										<a
											onClick={() =>
												clickHandler(
													'child-labour-likehood'
												)
											}
											className={[
												'nav-link',
												location.pathname.split(
													'/'
												)[2] === 'child-labour-likehood'
													? 'active'
													: '',
											].join(' ')}
											id="child-labour-likehood-tab"
											data-toggle="tab"
											href="#child-labour-likehood"
											role="tab"
											aria-controls="child-labour-likehood"
											aria-selected="true"
										>
											{
												language?.text.tab.colombia
													.country[1]
											}
										</a>
									</li>
									<li className="nav-item">
										<a
											onClick={() =>
												clickHandler('risk-factors')
											}
											className={[
												'nav-link',
												location.pathname.split(
													'/'
												)[2] === 'risk-factors'
													? 'active'
													: '',
											].join(' ')}
											id="risk-factors-tab"
											data-toggle="tab"
											href="#risk-factors"
											role="tab"
											aria-controls="risk-factors"
											aria-selected="false"
										>
											{
												language?.text.tab.colombia
													.country[2]
											}
										</a>
									</li>
									<li className="nav-item">
										<a
											onClick={() =>
												clickHandler(
													'associated-factors'
												)
											}
											className={[
												'nav-link',
												location.pathname.split(
													'/'
												)[2] === 'associated-factors'
													? 'active'
													: '',
											].join(' ')}
											id="associated-factors-tab"
											data-toggle="tab"
											href="#associated-factors"
											role="tab"
											aria-controls="associated-factors"
											aria-selected="false"
										>
											{
												language?.text.tab.colombia
													.country[3]
											}
										</a>
									</li>
								</ul>
								<div className="d-block d-sm-none btn-group btn-group-oit">
									<button
										type="button"
										className="btn btn-secondary dropdown-toggle w-100"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										Opciones
									</button>
									<div className="dropdown-menu">
										{/* <a
											onClick={() =>
												clickHandler('child-labour')
											}
											className={[
												'dropdown-item',
												location.pathname.split(
													'/'
												)[2] === 'child-labour'
													? 'active'
													: '',
											].join(' ')}
											id="child-labour-tab"
											data-toggle="tab"
											href="#child-labour"
											role="tab"
											aria-controls="child-labour"
											aria-selected="false"
										>
											{
												language?.text.tab.colombia
													.country[0]
											}
										</a>
										 */}
										<a
											onClick={() =>
												clickHandler(
													'child-labour-likehood'
												)
											}
											className={[
												'dropdown-item',
												location.pathname.split(
													'/'
												)[2] === 'child-labour-likehood'
													? 'active'
													: '',
											].join(' ')}
											id="child-labour-likehood-tab"
											data-toggle="tab"
											href="#child-labour-likehood"
											role="tab"
											aria-controls="child-labour-likehood"
											aria-selected="true"
										>
											{
												language?.text.tab.colombia
													.country[1]
											}
										</a>
										<a
											onClick={() =>
												clickHandler('risk-factors')
											}
											className={[
												'dropdown-item',
												location.pathname.split(
													'/'
												)[2] === 'risk-factors'
													? 'active'
													: '',
											].join(' ')}
											id="risk-factors-tab"
											data-toggle="tab"
											href="#risk-factors"
											role="tab"
											aria-controls="risk-factors"
											aria-selected="false"
										>
											{
												language?.text.tab.colombia
													.country[2]
											}
										</a>
										<a
											onClick={() =>
												clickHandler(
													'associated-factors'
												)
											}
											className={[
												'dropdown-item',
												location.pathname.split(
													'/'
												)[2] === 'associated-factors'
													? 'active'
													: '',
											].join(' ')}
											id="associated-factors-tab"
											data-toggle="tab"
											href="#associated-factors"
											role="tab"
											aria-controls="associated-factors"
											aria-selected="false"
										>
											{
												language?.text.tab.colombia
													.country[3]
											}
										</a>
									</div>
								</div>
							</div>
						</div>

						<div
							className="tab-content content p-3  mt-5"
							id="myTabContent"
						>
							{/* <div
								className={[
									'tab-pane fade',
									location.pathname.split('/')[2] ===
									'child-labour'
										? 'active show'
										: '',
								].join(' ')}
								id="child_labour"
								role=" tabpanel"
								aria-labelledby="child-labour-tab"
							>
								<ChildLabour />
							</div> */}
							<div
								className={[
									'tab-pane fade',
									location.pathname.split('/')[2] ===
									'child-labour-likehood'
										? 'active show'
										: '',
								].join(' ')}
								id="child-labour-likehood"
								role="tabpanel"
								aria-labelledby="child-labour-likehood-tab"
							>
								<ChildLabourLikelihood />
							</div>
							<div
								className={[
									'tab-pane fade',
									location.pathname.split('/')[2] ===
									'risk-factors'
										? 'active show'
										: '',
								].join(' ')}
								id="risk-factors"
								role="tabpanel"
								aria-labelledby="risk-factors-tab"
							>
								<RiskFactors />
							</div>
							<div
								className={[
									'tab-pane fade',
									location.pathname.split('/')[2] ===
									'associated-factors'
										? 'active show'
										: '',
								].join(' ')}
								id="associated-factors"
								role="tabpanel"
								aria-labelledby="associated-factors-tab"
							>
								<ComplementaryFactors />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Colombia;
