import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { TooltipRange } from '../../../../mapsHelpers/costaRicaTooltipHelper/costaRicaTooltiphelper';
import styles from '../mexico.module.css';

export default function Tooltip({ hoverStateId, tooltipRef }) {
	return (
		<TransitionGroup>
			{hoverStateId && (
				<CSSTransition
					in={hoverStateId !== null}
					timeout={200}
					classNames="my-node"
				>
					<div className={styles.Tooltip} ref={tooltipRef}>
						<h4 className="mb-0">
							<span
								className={styles.Indicador}
								style={{
									backgroundColor: `${hoverStateId[1].color}`,
								}}
							></span>
							{hoverStateId[1]?.name}
						</h4>
						{hoverStateId[1]?.range && (
							<TooltipRange
								range={hoverStateId[1]?.range}
								group={hoverStateId[1]?.group}
							/>
						)}
					</div>
				</CSSTransition>
			)}
		</TransitionGroup>
	);
}
