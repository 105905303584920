import React from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../../../../context/Languages';

// custon hooks
import { useHttp } from '../../../../hooks/http';

// maps helpers
import SharedButtons from '../../../mapsHelpers/sharedbuttons/sharedButtons';
import DownloadList from '../../../mapsHelpers/downloadList/downloadList';

// components
import RiskFactorTable from '../../../mapsHelpers/riskFactorTable/riskFactorTable';

const RiskFactors = () => {
	const { language } = useLanguage();
	let location = useLocation();
	const [isLoading, data] = useHttp(
		'get_tab_mexico/',
		{tabcode:'risk_factors', lang:language?.lang},
		false,
		[language?.lang]
	);

	return (
		<div className="content">
			<div className="row">
				<div className="col-lg-7">
					<RiskFactorTable
						table={data?.config.tabs.risk_factors.table}
					/>
					<div
						className="col-12 text-justify p-lg-0"
						dangerouslySetInnerHTML={{
							__html:
								data?.config.tabs.risk_factors.description[1],
						}}
					></div>
				</div>
				<div className="col-lg-5">
					<div
						className="col-12 text-justify p-lg-0"
						dangerouslySetInnerHTML={{
							__html:
								data?.config.tabs.risk_factors.description[0],
						}}
					></div>

					<div className="more-detail">
						<div className="row w-100">
							<div className="col-6 ml-auto p-0">
								<div className="d-table h-100">
									<div className="d-table-cell">
										<div className="share h-100">
											<p className="text">
												{
													language?.text.body
														.share_information
												}
											</p>
											<div className="oit-icon-group oit-icon-group-small icons-green">
												<SharedButtons
													url={`${location.pathname}`}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<DownloadList
							links={
								data?.config.tabs.risk_factors.more_descriptions
									.links
							}
							url={null}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RiskFactors;
