import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from './../../../../../context/Languages';
import SharedButtons from '../../../../mapsHelpers/sharedbuttons/sharedButtons';

// custon hooks
import { useHttp } from './../../../../../hooks/http';

//maps helpers
import DownloadList from '../../../../mapsHelpers/downloadList/downloadList';
import MapsTextSkeleton from '../../../../mapsHelpers/mapsTextSkeleton/mapsTextSkeleton';
import MapsSpinner from '../../../../mapsHelpers/mapsSpinner/mapsSpinner';

// css module
import styles from './../peru.module.css';
import './../../../../../index.css';

import './states.css';
import FactorsGroups from '../../../../mapsHelpers/complementaryFactorsGroups/FactorsGroups';

export default function ComplementaryFactors({ state }) {
	let location = useLocation();
	const { language } = useLanguage();
	// const [municipalities, setMunicipalities] = useState([]);
	// const [isLoadingChildLabourLikehood, dataChildLabourLikehood] = useHttp(
	// 	`get_tab_peru/${state}`,
	// 	{ tabcode: 'child_labour_likehood', lang: language?.lang },

	// 	false,
	// 	[state, language?.lang],
	// 	true
	// );
	const [isLoading, data] = useHttp(
		`get_tab_ecuador/${state}`,
		{ tabcode: 'complementary_factors', lang: language?.lang },
		false,
		[state, language?.lang],
		true
	);

	console.log('complementary_factors', data);

	return (
		<React.Fragment>
			<div className="row mt-3">
				<div className="col-12 col-lg-7 ">
					<div className="table-responsive">
						<table className="table oit-table">
							<thead>
								<tr>
									{data &&
										data[0].zones[0].tabs.complementary_factors.table.headers.map(
											(header, index) => {
												return (
													<th
														key={
															header.description +
															index
														}
														colSpan={1}
													>
														{header.description}
														<br />
														{header.value &&
															`(${header.value})`}
													</th>
												);
											}
										)}
								</tr>
							</thead>
							<tbody>
								{data &&
									data[0].zones[0].tabs.complementary_factors.table.data.map(
										(dataField, index) => {
											return dataField.rows.map(
												(row, rowIndex) => {
													return (
														<tr
															key={`${dataField._id}-${rowIndex}`}
														>
															{rowIndex == 0 ? (
																<td
																	style={{
																		verticalAlign:
																			'middle',
																	}}
																	rowSpan={
																		dataField
																			.rows
																			.length
																	}
																>
																	{
																		dataField.category
																	}
																</td>
															) : null}
															<td
																style={{
																	textAlign:
																		'left',
																}}
															>
																{
																	row.description
																}
															</td>
															{row.values.map(
																(field, i) => {
																	return (
																		<td
																			key={`${rowIndex}-${i}`}
																		>
																			{
																				field
																			}
																		</td>
																	);
																}
															)}
														</tr>
													);
												}
											);
										}
									)}
							</tbody>
						</table>
					</div>
					<div
						className=" text-justify"
						dangerouslySetInnerHTML={{
							__html:
								data &&
								data[0].zones[0].tabs.complementary_factors
									.description[1],
						}}
					></div>
				</div>
				<div className="col-12 col-lg-5">
					<div
						className=" text-justify"
						dangerouslySetInnerHTML={{
							__html:
								data &&
								data[0].zones[0].tabs.complementary_factors
									.description[0],
						}}
					></div>

					<div
						className="text-justify"
						dangerouslySetInnerHTML={{
							__html:
								data &&
								data[0].zones[0].tabs.complementary_factors
									.description[2],
						}}
					></div>
					<div className="more-detail">
						<div className="row w-100">
							<div className="col-6 p-0"></div>
							<div className="col-6 p-0">
								<div className="d-table ml-auto h-100">
									<div className="d-table-cell">
										<div className="share h-100">
											<p className="text">
												{
													language?.text.body
														.share_information
												}
											</p>
											<div className="oit-icon-group oit-icon-group-small icons-green">
												<SharedButtons
													url={`${location.pathname}`}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<DownloadList
							links={
								data &&
								data[0].zones[0].tabs.complementary_factors
									.more_descriptions.links
							}
							url={null}
						/>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
