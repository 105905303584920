import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useLanguage } from './../../../../context/Languages';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// custon hooks
import { useHttp } from '../../../../hooks/http';

// helpers
import SharedButtons from '../../../mapsHelpers/sharedbuttons/sharedButtons';
import DownloadList from '../../../mapsHelpers/downloadList/downloadList';
import MapsSpinner from '../../../mapsHelpers/mapsSpinner/mapsSpinner';
import Cluster from './clusterMap';

// css module
import styles from './mexico.module.css';
import './../../../../index.css';

import MapsTextSkeleton from '../../../mapsHelpers/mapsTextSkeleton/mapsTextSkeleton';
import GroupList from '../../../mapsHelpers/groupList/groupList';

const ChildLabourLikelihood = () => {
	const { language } = useLanguage();
	let location = useLocation();
	const history = useHistory();
	const [isLoading, data] = useHttp(
		`get_tab_mexico/`,
		{ tabcode: `child_labour_likelihood`, lang: language?.lang },
		true,
		[language?.lang]
	);
	const [states, setStates] = useState([]);
	const [hoverStateId, setHoverStateId] = useState(null);

	// map ref
	const mapRef = useRef();
	// tooltip ref
	const tooltipRef = useRef();

	// match svg location with backend
	const setLocation = () => {
		let realLocations = [];
		if (data) {
			let states = data.structure.states;
			let { hover, stroke, stroke_width } =
				data.config.tabs.child_labour_likelihood;
			// colors from the data object
			let svgColors = data.config.tabs.child_labour_likelihood.groups;
			data.map.locations.map(location => {
				let targetState = states.find(
					state => state.node === location.id
				);
				let color;
				// if macth
				if (targetState) {
					//item information
					const { group, range } =
						targetState.data.child_labour_likelihood;

					// set color from db match
					color = svgColors[group - 1].color;
					realLocations.push({
						...location,
						fill: color,
						hover,
						range,
						group,
						stroke,
						stroke_width,
						name: targetState.name,
					});
				}

				setStates(realLocations);
			});
		}
	};

	// set mouse event for tooltip, always tracking the mouse
	useEffect(() => {
		const mouseMove = mouse => {
			const tooltip = tooltipRef.current;
			const mapContainerPsition = mapRef.current.getBoundingClientRect();
			const { pageX, pageY } = mouse;
			if (!tooltip) {
				return;
			}
			let scrollTop =
				window.pageYOffset !== undefined
					? window.pageYOffset
					: (
							document.documentElement ||
							document.body.parentNode ||
							document.body
					  ).scrollTop;
			tooltip.style.top = `${
				pageY - mapContainerPsition.top - 80 - scrollTop
			}px`;
			tooltip.style.left = `${pageX - mapContainerPsition.left - 10}px`;
		};
		// for track the cursor
		document.addEventListener('mousemove', mouseMove);
		return () => {
			// remove cursdor event
			document.removeEventListener('mousemove', mouseMove);
		};
	}, []);

	// hover handler
	const svhHoverHandler = (e, info) => {
		setHoverStateId([e.currentTarget.id, info]);
	};
	// blur handler
	const svhblurHandler = e => {
		setHoverStateId(null);
	};
	// click handler
	const svgClickHandler = e => {
		history.push(`/mexico/${e.currentTarget.id}/child-labour-likelihood`);
	};

	useEffect(() => {
		if (data) {
			setLocation();
		}
	}, [data]);

	return (
		<div className="row">
			<div className="col-sm-7 left">
				<div ref={mapRef} className="mb-4">
					<TransitionGroup>
						{hoverStateId && (
							<CSSTransition
								in={hoverStateId}
								timeout={200}
								classNames="my-node"
							>
								<div
									className={styles.Tooltip}
									ref={tooltipRef}
								>
									<h4 className="mb-0">
										<span
											className={styles.Indicador}
											style={{
												backgroundColor: `${hoverStateId[1].color}`,
											}}
										></span>
										{hoverStateId[1]?.name}
										{hoverStateId[1]?.range}
									</h4>
									<p className="ml-3 mb-0">
										<small>
											{
												data?.config.tabs.child_labour_likelihood.groups[
													hoverStateId[1]?.group - 1
												]?.text.split(':')[0]
											}
										</small>
									</p>
								</div>
							</CSSTransition>
						)}
					</TransitionGroup>

					{isLoading ? (
						<MapsSpinner />
					) : (
						data?.map.viewBox && (
							<svg
								className={styles.FadeIn}
								xmlns="http://www.w3.org/2000/svg"
								viewBox={data.map.viewBox}
							>
								{states.map(location => {
									return (
										<g
											className={styles.g}
											id={location.id}
											key={location.id}
											transform={location.transform}
											onMouseLeave={svhblurHandler}
											onMouseEnter={e =>
												svhHoverHandler(e, {
													name: location.name,
													range: location.range,
													group: location.group,
													color: location.fill,
												})
											}
											style={{ cursor: 'pointer' }}
											onClick={svgClickHandler}
										>
											{location.paths.map(path => (
												<path
													id={path.id}
													key={path.id}
													stroke={location.stroke}
													strokeWidth={
														location.stroke_width
													}
													fill={location.fill}
													d={path.d}
													transform={path.transform}
												/>
											))}
										</g>
									);
								})}
							</svg>
						)
					)}
				</div>
			</div>

			<div className="col-sm-5 right">
				{isLoading ? (
					<MapsTextSkeleton title={true} count={5} />
				) : (
					<React.Fragment>
						<div
							className={styles.FadeIn}
							dangerouslySetInnerHTML={{
								__html: data?.config.tabs
									.child_labour_likelihood.description[0],
							}}
						>
							{/* En México hay 32 entidades federativas, de las cuales estan
						en: */}
						</div>
						<GroupList
							groups={
								data?.config.tabs.child_labour_likelihood.groups
							}
						/>
						<div
							className={styles.FadeIn}
							dangerouslySetInnerHTML={{
								__html: data?.config.tabs
									.child_labour_likelihood.description[1],
							}}
						></div>
						<div
							className={styles.FadeIn}
							dangerouslySetInnerHTML={{
								__html: data?.config.tabs
									.child_labour_likelihood.description[2],
							}}
						></div>
						<div className="more-detail">
							<div className="row w-100">
								<div className="col-6">
									<a
										href="javascript:void(0)"
										data-toggle="modal"
										data-target="#exampleModalLong2"
									>
										<button className="more-detail">
											{language.text.button.cluster_map}
										</button>
									</a>
								</div>
								<div className="col-6 p-0">
									<div className="d-table h-100">
										<div className="d-table-cell">
											<div className="share h-100">
												<p className="text">
													{
														language?.text.body
															.share_information
													}
												</p>
												<div className="oit-icon-group oit-icon-group-small icons-green">
													<SharedButtons
														url={`${location.pathname}`}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<DownloadList
							links={
								data?.config.tabs.child_labour_likelihood
									.more_descriptions.links
							}
							url="get_mexico_pdf/national_table"
						/>
					</React.Fragment>
				)}
			</div>
			<Cluster
				language={language}
				data={data?.config.tabs.child_labour_likelihood.clusters}
			/>
		</div>
	);
};

export default ChildLabourLikelihood;
