import React from 'react';

// tooltip range helper
export const rangeHelper = range => {
	// 12.65656-18.4545454
	if (!range) return 'n/a';

	let [rango1, rango2] = range.split('-');

	if (isNaN(Number(rango1, 10))) return range;
	let newRange = [
		Number(rango1, 10).toFixed(1),
		Number(rango2, 10).toFixed(1),
	].join(' - ');

	return newRange;
};

export const groupBullet = group => {
	return (
		<span
			style={{
				display: 'inline-block',
				width: '10px',
				height: '10px',
				borderRadius: '50%',
				color: 'red',
				backgroundColor: `${group?.color}`,
			}}
		></span>
	);
};

export function TooltipRange({ range, group }) {
	
	return (
		<>
			<p className="ml-3 mb-0">
				<small>
					{ `${group} ${rangeHelper(range)}`}
				</small>
			</p>
		</>
	);
}
