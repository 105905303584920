import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useLanguage } from './../../../../context/Languages';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// custon hooks
import { useHttp } from '../../../../hooks/http';

// helpers
import SharedButtons from '../../../mapsHelpers/sharedbuttons/sharedButtons';
import DownloadList from '../../../mapsHelpers/downloadList/downloadList';
import MapsSpinner from '../../../mapsHelpers/mapsSpinner/mapsSpinner';
import Cluster from './clusterMap';

// css module
import styles from './peru.module.css';
import './../../../../index.css';

import MapsTextSkeleton from '../../../mapsHelpers/mapsTextSkeleton/mapsTextSkeleton';
import GroupList from '../../../mapsHelpers/groupList/groupList';

const ChildLabourLikelihood = () => {
	const { language } = useLanguage();
	console.log('language', language);
	let location = useLocation();
	const history = useHistory();
	const [isLoading, data] = useHttp(
		'get_tab_ecuador/',
		{ tabcode: 'child_labour_likelihood', lang: language?.lang },
		true,
		[language?.lang]
	);
	const [states, setStates] = useState([]);
	const [hoverStateId, setHoverStateId] = useState(null);

	// map ref
	const mapRef = useRef();
	// tooltip ref
	const tooltipRef = useRef();

	// match svg location with backend
	const setLocation = () => {
		let realLocations = [];
		if (data) {
			let { zones, provinces, canton } = data.structure;
			let [districtConfig, stateConfig] =
				data.config.tabs.child_labour_likelihood.config;
			// colors from the data object
			let svgColors = data.config.tabs.child_labour_likelihood.groups;
			data.map.locations[0].g.map((location, i) => {
				let targetZone = zones.find(zone => {

					return zone.node === location.id;
				});
				if (targetZone) {
					location.name = targetZone?.name;
				}
				// go down to districts level
				location.g.map((region, regionIndex) => {
					region.g[0].g.map((cantonElement, index) => {
						let targetCanton = canton.find(d => {
							return d.node === `cantones_${cantonElement.id}`;
						});

						// if macth
						if (targetCanton) {
							//item information
							const { group, range } =
								targetCanton.data.child_labour_likelihood;

							// set color from db match
							let color;
							if (group) {
								color = svgColors[group - 1].color;
							} else {
								color = 'white';
							}
							// assign color
							location.g[regionIndex].g[0].g[index].fill = color;
						} else if (cantonElement.id !== 'contorno') {
							location.g[regionIndex].g[0].g[index].fill =
								'white';
						} else if (!targetCanton) {
							console.log('no match district', cantonElement.id);
						}
					});
				});

				if (targetZone) {
					realLocations.push({
						...location,
						// name: targetRegion.name,
						stateConfig,
						districtConfig,
					});
				} else {
					console.log(
						'ESTO NO ESTA HACIENDO MATCH SE SUPNE QUE SERIA UNA REGION',
						location
					);
				}
			});
			console.log('realLocations', realLocations);
			setStates([...realLocations]);
		}
	};

	// set mouse event for tooltip, always tracking the mouse
	useEffect(() => {
		const mouseMove = mouse => {
			const tooltip = tooltipRef.current;
			const mapContainerPsition = mapRef.current.getBoundingClientRect();
			const { pageX, pageY } = mouse;
			if (!tooltip) {
				return;
			}
			let scrollTop =
				window.pageYOffset !== undefined
					? window.pageYOffset
					: (
							document.documentElement ||
							document.body.parentNode ||
							document.body
					  ).scrollTop;
			tooltip.style.top = `${
				pageY - mapContainerPsition.top - 80 - scrollTop
			}px`;
			tooltip.style.left = `${pageX - mapContainerPsition.left - 10}px`;
		};
		// for track the cursor
		document.addEventListener('mousemove', mouseMove);
		return () => {
			// remove cursdor event
			document.removeEventListener('mousemove', mouseMove);
		};
	}, []);

	// hover handler
	const svhHoverHandler = (e, info) => {
		setHoverStateId([e.currentTarget.id, info]);
	};
	// blur handler
	const svhblurHandler = e => {
		setHoverStateId(null);
	};
	// click handler
	const svgClickHandler = e => {
		history.push(`/ecuador/${e.currentTarget.id}/child-labour-likelihood`);
	};

	useEffect(() => {
		if (data) {
			setLocation();
		}
	}, [data]);

	return (
		<div className="row">
			<div className="col-sm-7 left">
				<div ref={mapRef} className="mb-4">
					<TransitionGroup>
						{hoverStateId && (
							<CSSTransition
								in={hoverStateId}
								timeout={200}
								classNames="my-node"
							>
								<div
									className={styles.Tooltip}
									ref={tooltipRef}
								>
									<h4 className="mb-0">
										{hoverStateId[1]?.name}
										{hoverStateId[1]?.range}
									</h4>
									{hoverStateId[1].range && (
										<small>
											Rango de riesgo de trabajo infantil:
											<span className="ml-1">
												{hoverStateId[1].range}
											</span>
										</small>
									)}
								</div>
							</CSSTransition>
						)}
					</TransitionGroup>

					{isLoading ? (
						<MapsSpinner />
					) : (
						data?.map.viewBox && (
							<svg
								className={styles.FadeIn}
								xmlns="http://www.w3.org/2000/svg"
								viewBox={data?.map.viewBox}
							>
								{states?.map(location => {
									return (
										<g
											id={location.id}
											key={location.id}
											transform={location.transform}
											onMouseLeave={svhblurHandler}
											onMouseEnter={e =>
												svhHoverHandler(e, {
													name: location.name,
													range: location.range,
												})
											}
											style={{ cursor: 'pointer' }}
											onClick={svgClickHandler}
										>
											{location.g.map(province => {
												return province.g.map(
													cantonesGroup => {
														if (
															cantonesGroup.id.includes(
																'contorno'
															)
														)
															return null;
														return cantonesGroup.g.map(
															canton => {
																return (
																	<g>
																		{canton.paths?.map(
																			p => (
																				<path
																					id={
																						canton.id
																					}
																					key={
																						canton.id
																					}
																					stroke={
																						location
																							.districtConfig
																							.stroke
																					}
																					strokeWidth={
																						location
																							.districtConfig
																							.stroke_width
																					}
																					fill={
																						canton.fill
																					}
																					d={
																						p.d
																					}
																					transform={
																						canton.transform
																					}
																				/>
																			)
																		)}
																	</g>
																);
															}
														);
													}
												);
											})}
											{location.g.map(province => {
												return province.g.map(
													strokeGroup => {
														if (
															!strokeGroup.id.includes(
																'contorno'
															)
														)
															return null;

														return (
															<g>
																{strokeGroup.paths?.map(
																	p => (
																		<path
																			
																			key={
																				strokeGroup.id
																			}
																			stroke={
																				location
																					.stateConfig
																					.stroke
																			}
																			strokeWidth={
																				location
																					.stateConfig
																					.stroke_width
																			}
																			fill={
																				"transparent"
																			}
																			d={
																				p.d
																			}
																			transform={
																				strokeGroup.transform
																			}
																		/>
																	)
																)}
															</g>
														);
													}
												);
											})}

											{/* {location.g[1].paths.map(path => (
												<g>
													<path
														id={path.id}
														key={path.id}
														stroke={
															location.stateConfig
																.stroke
														}
														strokeWidth={
															location.stateConfig
																.stroke_width
														}
														fill={'transparent'}
														d={path.d}
														transform={
															path.transform
														}
													/>
												</g>
											))} */}
										</g>
									);
								})}
							</svg>
						)
					)}
				</div>
			</div>

			<div className="col-sm-5 right">
				{isLoading ? (
					<MapsTextSkeleton title={true} count={5} />
				) : (
					<React.Fragment>
						<div
							className={[styles.FadeIn, 'text-justify'].join(
								' '
							)}
							dangerouslySetInnerHTML={{
								__html: data?.config.tabs
									.child_labour_likelihood.description[0],
							}}
						></div>
						<GroupList
							groups={
								data?.config.tabs.child_labour_likelihood.groups
							}
						/>

						<div
							className={[styles.FadeIn, 'text-justify'].join(
								' '
							)}
							dangerouslySetInnerHTML={{
								__html: data?.config.tabs
									.child_labour_likelihood.description[1],
							}}
						></div>
						<div
							className={[styles.FadeIn, 'text-justify'].join(
								' '
							)}
							dangerouslySetInnerHTML={{
								__html: data?.config.tabs
									.child_labour_likelihood.description[2],
							}}
						></div>
						<div className="more-detail">
							<div className="row w-100">
								{/* <div className="col-6">
									<a
										role="button"
										data-toggle="modal"
										data-target="#exampleModalLong2"
									>
										<button className="more-detail">
											{language.text.button.cluster_map}
										</button>
									</a>
								</div> */}
								<div className="col-6 p-0">
									<div className="d-table h-100">
										<div className="d-table-cell">
											<div className="share h-100">
												<p className="text">
													{
														language?.text.body
															.share_information
													}
												</p>
												<div className="oit-icon-group oit-icon-group-small icons-green">
													<SharedButtons
														url={`${location.pathname}`}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<DownloadList
							links={
								data?.config.tabs.child_labour_likelihood
									.more_descriptions.links
							}
							url="get_mexico_pdf/national_table"
						/>
					</React.Fragment>
				)}
			</div>
			<Cluster
				data={data?.config.tabs.child_labour_likelihood.clusters}
			/>
		</div>
	);
};

export default ChildLabourLikelihood;
