import React from 'react';
import { rangeHelper } from '../../../mapsHelpers/costaRicaTooltipHelper/costaRicaTooltiphelper';
import styles from './costarica.module.css';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

export default function Tooltip({ hoverStateId, tooltipRef, groups }) {
	console.log('tooltip groups', groups);
	console.log('tooltip hoverStateId', hoverStateId);
	return (
		<>
			<TransitionGroup>
				{hoverStateId && (
					<CSSTransition
						in={hoverStateId !== null}
						timeout={200}
						classNames="my-node"
					>
						<div className={styles.Tooltip} ref={tooltipRef}>
							<h4 className="mb-0">
								<span
									className={styles.Indicador}
									style={{
										backgroundColor: `${hoverStateId[1].color}`,
									}}
								></span>
								{hoverStateId[1]?.name}
							</h4>
							{hoverStateId[1].range && (
								<p className="ml-3 mb-0">
									<small>
										{`${
											groups[hoverStateId[1]?.group - 1]
												.text
										} ${rangeHelper(
											hoverStateId[1].range
										)}`}
									</small>
								</p>
							)}
						</div>
					</CSSTransition>
				)}
			</TransitionGroup>
		</>
	);
}
