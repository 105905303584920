import React, { useState, useEffect } from 'react';
import { useGroups } from '../../../../context/Groups';

import { Switch, Route, useRouteMatch, useLocation, Redirect } from 'react-router-dom';
import Colombia from '../colombia';
import State from '../colombia/states';

const RoutesMexico = () => {
	const { setCountry, selectedContinent } = useGroups();
	
	let { path } = useRouteMatch();
	let location = useLocation();

	useEffect(() => {
		setCountry(path.split('/')[1]);
	}, [selectedContinent]);

	useEffect(() => {
		return () => {
			setCountry(null);
		};
	}, []);

	return (
		<Switch>
			<Route path={`${path}/child-labour`}>
				<Colombia/>
			</Route>
			
			<Route path={`${path}/child-labour-likehood`}>
				<Colombia />
			</Route>
			
			<Route path={`${path}/risk-factors`}>
				<Colombia />
			</Route>

			<Route path={`${path}/associated-factors`}>
				<Colombia />
			</Route>

		
			<Route path={`${path}/:state/child-labour`}>
				<State />
			</Route>
			<Route path={`${path}/:state/child-labour-likehood`}>
				<State />
			</Route>
			<Route path={`${path}/:state/risk-factors`}>
				<State />
			</Route>
			<Route path={`${path}/:state/associated-factors`}>
				<State />
			</Route>
			<Route path={`${path}/:state`}>
				<Redirect to={`${path}/${location.pathname.split("/")[2]}/child-labour-likehood`} />
			</Route>

			<Route path={`${path}`}>
				<Redirect to={`${path}/child-labour-likehood`} />
			</Route>

			<Route path='*'>
				<h1>404</h1>
			</Route>
		</Switch>
	);
};

export default RoutesMexico;
