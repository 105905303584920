import React from 'react';
import Skeleton from 'react-loading-skeleton';


export default function MapsTextSkeleton({ count, title }) {
	return (
		<div className="col-12">
			{title && (
				<h1>
					<Skeleton />
				</h1>
			)}
			<p>
				<Skeleton count={count} />
			</p>
		</div>
	);
}
