import {useState, useEffect} from 'react'
import client from '../../api/client';


export const useHttpArgentina = (url, params, fetchMap=false, dependencies, isState=false) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    
    useEffect(() => {
		setIsLoading(true)
		const fetchData = async () => {
            let responseMap;
            let map;

            try {
                
                const response = await client.get(url, {
                    params: { ...params},
                    headers: { 'Content-Type': 'application/json' },
                    });
                
                      // if no maps for request
                    if(response && !fetchMap){
                        setIsLoading(false);
                        setData(response.data);
                        return;
                    }
                    
                    if(isState){

                            responseMap = await fetch(response.data[0].province[0].map);
    
                        }else{
                            responseMap = await fetch(response.data.config.tabs[params.tabcode].map);
                        } 
                    map = await responseMap.json();
                    // set data
                    if (map) setIsLoading(false);
    
                    setData({ ...response.data, map: map }); 

            } catch (error) {
                setIsLoading(false);
                
            }
            
		};

        fetchData()
        
	}, dependencies);

    return [isLoading, data];
}