import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from './../../../../../context/Languages';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// custon hooks
import { useHttp } from './../../../../../hooks/http';

//maps helpers
import SharedButtons from '../../../../mapsHelpers/sharedbuttons/sharedButtons';
import MapsSpinner from '../../../../mapsHelpers/mapsSpinner/mapsSpinner';
import DownloadList from '../../../../mapsHelpers/downloadList/downloadList';

// css module
import styles from '../mexico.module.css';
import './../../../../../index.css';
import MapsTextSkeleton from '../../../../mapsHelpers/mapsTextSkeleton/mapsTextSkeleton';
import GroupList from '../../../../mapsHelpers/groupList/groupList';
import { TooltipRange } from '../../../../mapsHelpers/costaRicaTooltipHelper/costaRicaTooltiphelper';

const ChildLabourLikelihood = ({ state }) => {
	let location = useLocation();
	const { language } = useLanguage();
	const [municipalities, setMunicipalities] = useState([]);
	const [isLoading, data] = useHttp(
		`get_tab_mexico/${state}`,
		{tabcode:'child_labour_likehood', lang:language?.lang},
		true,
		[state, language?.lang],
		true
	);
	const [hoverStateId, setHoverStateId] = useState(null);
	// map ref
	const mapRef = useRef(null);
	// tooltip ref
	const tooltipRef = useRef(null);

	// match svg location with backend
	const setLocation = () => {
		let realLocations = [];
		if (data) {
			let municipalities = data[0].municipalities;
			const {
				hover,
				stroke,
				stroke_width,
			} = data[0].state[0].tabs.child_labour_likelihood;
			// colors from the data object
			let svgColors =
				data[0].state[0].tabs.child_labour_likelihood.groups;
			data.map.locations.map(location => {
				let targetState = municipalities.find(
					state => state.node === location.id
				);
				let color;
				// if macth
				if (targetState) {
					//item information
					const {
						group,
						range,
					} = targetState.data.child_labour_likelihood;

					// set color from db match
					if (group) {
						color = svgColors[group - 1].color;
					}
					realLocations.push({
						...location,
						name: targetState.name,
						hover,
						range,
						stroke,
						group,
						stroke_width,
						_id: targetState.id,
						fill: color ? color : '#fff',
					});
				}

				setMunicipalities(realLocations);
			});
		}
	};

	// set mouse event for tooltip, always tracking the mouse
	useEffect(() => {
		const mouseMove = mouse => {
			const tooltip = tooltipRef.current;
			const mapContainerPsition = mapRef.current.getBoundingClientRect();
			const { pageX, pageY } = mouse;
			if (!tooltip) {
				return;
			}
			let scrollTop =
				window.pageYOffset !== undefined
					? window.pageYOffset
					: (
							document.documentElement ||
							document.body.parentNode ||
							document.body
					  ).scrollTop;
			tooltip.style.top = `${
				pageY - mapContainerPsition.top - 95 - scrollTop
			}px`;
			tooltip.style.left = `${pageX - mapContainerPsition.left - 10}px`;
		};
		// for track the cursor
		document.addEventListener('mousemove', mouseMove);
		return () => {
			// remove cursdor event
			document.removeEventListener('mousemove', mouseMove);
		};
	}, []);

	const svhHoverHandler = (e, info) => {
		setHoverStateId([e.currentTarget.id, info]);
	};

	const svhblurHandler = e => {
		setHoverStateId(null);
	};

	useEffect(() => {
		if (data) {
			setLocation();
		}
	}, [data]);

	return (
		<div className="row">
			<div className="col-sm-7 left">
				<TransitionGroup>
					{hoverStateId && (
						<CSSTransition
							in={hoverStateId}
							timeout={200}
							classNames="my-node"
						>
							<div className={styles.Tooltip} ref={tooltipRef}>
								<h4 className="mb-0">
									<span
										className={styles.Indicador}
										style={{
											backgroundColor: `${hoverStateId[1].color}`,
										}}
									></span>
									{hoverStateId[1]?.name}
								</h4>
								{hoverStateId[1]?.range && (
									<TooltipRange
										group={data[0].state[0].tabs.child_labour_likelihood.groups[hoverStateId[1]?.group - 1].text}
										range={hoverStateId[1]?.range}
									/>
								)}
							</div>
						</CSSTransition>
					)}
				</TransitionGroup>
				<div ref={mapRef}>
					{isLoading ? (
						<MapsSpinner />
					) : (
						data?.map.viewBox && (
							<svg
								className={styles.FadeIn}
								xmlns="http://www.w3.org/2000/svg"
								viewBox={data.map.viewBox}
							>
								{municipalities.map(location => {
									return (
										<g
											id={location.id}
											key={location.id}
											transform={location.transform}
											onMouseLeave={svhblurHandler}
											style={{ cursor: 'pointer' }}
											onMouseEnter={e =>
												svhHoverHandler(e, {
													name: location.name,
													range: location.range,
													color: location.fill,
													group: location.group,
												})
											}
											style={{ cursor: 'pointer' }}
										>
											{location.paths.map((path, i) => (
												<path
													key={path.id + i}
													id={path.id}
													stroke={location.stroke}
													strokeWidth={
														location.stroke_width
													}
													fill={location.fill}
													d={path.d}
													transform={path.transform}
												/>
											))}
										</g>
									);
								})}
							</svg>
						)
					)}
				</div>
			</div>
			<div class="col-sm-5 right">
				{isLoading ? (
					<MapsTextSkeleton title={true} count={5} />
				) : (
					<React.Fragment>
						<div
							className={[styles.FadeIn, "text-justify"].join(" ")}
							dangerouslySetInnerHTML={{
								__html:
									data &&
									data[0].state[0].tabs
										.child_labour_likelihood.description[0],
							}}
						></div>
						<GroupList
							groups={
								data &&
								data[0].state[0].tabs.child_labour_likelihood
									.groups
							}
						/>

						<div
							className={[styles.FadeIn, "text-justify"].join(" ")}
							dangerouslySetInnerHTML={{
								__html:
									data &&
									data[0].state[0].tabs
										.child_labour_likelihood.description[1],
							}}
						></div>
						<div
							className={[styles.FadeIn, "text-justify"].join(" ")}
							dangerouslySetInnerHTML={{
								__html:
									data &&
									data[0].state[0].tabs
										.child_labour_likelihood.description[2],
							}}
						></div>

						<div class="more-detail">
							<div class="row">
								<div class="col-6"></div>
								<div class="col-6">
									<div class="d-table h-100">
										<div class="d-table-cell">
											<div class="share h-100">
												<p class="text">
													{
														language?.text.body
															.share_information
													}
												</p>
												<div class="oit-icon-group oit-icon-group-small icons-green">
													<SharedButtons
														url={`${location.pathname}`}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<DownloadList
							links={
								data &&
								data[0].state[0].tabs.child_labour_likelihood
									.more_descriptions.links
							}
							url={`get_mexico_pdf/national_table/${state}`}
						/>
					</React.Fragment>
				)}
			</div>
		</div>
	);
};

export default ChildLabourLikelihood;
