import React, { useEffect } from 'react';
import VulnerabilityToChildLabor from './VulnerabilityToChildLabor';
import VulneravilityDimensions from './VulneravilityDimensions';
import ComplementaryFactors from './ComplementaryFactors';

import { useHistory, useLocation } from 'react-router-dom';

import { Link, useParams } from 'react-router-dom';
import { useGroups } from '../../../../../context/Groups';
import { useLanguage } from '../../../../../context/Languages';

const Cantones = () => {
	let history = useHistory();
	const { language } = useLanguage();
	let location = useLocation();

	const { setAstate, selectedState, countrystates } = useGroups();
	let { state } = useParams();

	const clickHandler = path => {
		history.push(`/chile/${state}/${path}`);
	};

	//set active state when the list is loaded
	useEffect(() => {
		setAstate(state);
	}, [state, countrystates]);

	// set to null when unmount
	useEffect(() => {
		return () => {
			setAstate(null);
		};
	}, []);

	return (
		<div className="city">
			<div className="row nav container">
				<div className="col-12 col-md-2 col-sm-4 text-dark left">
					<Link to="/chile" className="back-button">
						<i className="fas fa-arrow-left"></i> &nbsp;
						{language?.text.nav.back_main}
					</Link>
				</div>
				<div className="col-12 col-md-10 right mt-3 mt-md-0">
					<h3 className="title text-left text-md-right">
						{language?.text.model.IVTI}
					</h3>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-12 tabs">
						<div className="row">
							<div className="col-sm-12 col-md-12 col-lg-2 mb-md-2 tab-left text-center text-md-left">
								<span className="title">{selectedState}</span>
							</div>
							<div className="col-sm-12 col-md-10 tab-right">
								<ul
									className="d-none d-sm-flex nav nav-tabs"
									id="myTab"
									role="tablist"
								>
									<li className="nav-item">
										<a
											onClick={() =>
												clickHandler(
													'vulnerability-child-labour'
												)
											}
											className={[
												'nav-link',
												location.pathname.split(
													'/'
												)[3] ===
												'vulnerability-child-labour'
													? 'active'
													: '',
											].join(' ')}
											id="vulnerability-child-labour-tab"
											data-toggle="tab"
											href="#vulnerability-child-labour"
											role="tab"
											aria-controls="vulnerability-child-labour"
											aria-selected="false"
										>
											{
												language?.text.tab.costa_rica
													.territory[0]
											}
										</a>
									</li>
									<li className="nav-item">
										<a
											onClick={() =>
												clickHandler(
													'vulnerability-dimension'
												)
											}
											className={[
												'nav-link',
												location.pathname.split(
													'/'
												)[3] ===
												'vulnerability-dimension'
													? 'active'
													: '',
											].join(' ')}
											id="vulnerability-dimension-tab"
											data-toggle="tab"
											href="#vulnerability-dimension"
											role="tab"
											aria-controls="vulnerability-dimension"
											aria-selected="true"
										>
											{
												language?.text.tab.costa_rica
													.territory[1]
											}
										</a>
									</li>
									<li className="nav-item">
										<a
											onClick={() =>
												clickHandler(
													'complementary-factors'
												)
											}
											className={[
												'nav-link',
												location.pathname.split(
													'/'
												)[3] === 'complementary-factors'
													? 'active'
													: '',
											].join(' ')}
											id="complementary-factors-tab"
											data-toggle="tab"
											href="#complementary-factors"
											role="tab"
											aria-controls="complementary-factors"
											aria-selected="false"
										>
											{
												language?.text.tab.costa_rica
													.territory[2]
											}
										</a>
									</li>
								</ul>
								<div className="d-block d-sm-none btn-group btn-group-oit">
									<button
										type="button"
										className="btn btn-secondary dropdown-toggle w-100"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										Opciones
									</button>
									<div className="dropdown-menu">
										<a
											onClick={() =>
												clickHandler(
													'vulnerability-child-labour'
												)
											}
											className={[
												'dropdown-item',
												location.pathname.split(
													'/'
												)[3] ===
												'vulnerability-child-labour'
													? 'active'
													: '',
											].join(' ')}
											id="vulnerability-child-labour-tab"
											data-toggle="tab"
											href="#vulnerability-child-labour-tab"
											role="tab"
											aria-controls="vulnerability-child-labour"
											aria-selected="false"
										>
											{
												language?.text.tab.costa_rica
													.territory[0]
											}
										</a>
										<a
											onClick={() =>
												clickHandler(
													'vulnerability-dimension'
												)
											}
											className={[
												'dropdown-item',
												location.pathname.split(
													'/'
												)[3] ===
												'vulnerability-dimension'
													? 'active'
													: '',
											].join(' ')}
											id="vulnerability-dimension-tab"
											data-toggle="tab"
											href="#vulnerability-dimension-tab"
											role="tab"
											aria-controls="vulnerability-dimension-tab"
											aria-selected="true"
										>
											{
												language?.text.tab.costa_rica
													.territory[1]
											}
										</a>
										<a
											onClick={() =>
												clickHandler(
													'complementary-factors'
												)
											}
											className={[
												'dropdown-item',
												location.pathname.split(
													'/'
												)[3] === 'complementary-factors'
													? 'active'
													: '',
											].join(' ')}
											id="complementary-factors-tab"
											data-toggle="tab"
											href="#complementary-factors-tab"
											role="tab"
											aria-controls="complementary-factors-tab"
											aria-selected="false"
										>
											{
												language?.text.tab.costa_rica
													.territory[2]
											}
										</a>
									</div>
								</div>
							</div>
						</div>

						<div
							className="tab-content content p-3 mt-5"
							id="myTabContent"
						>
							<div
								className={[
									'tab-pane fade',
									location.pathname.split('/')[3] ===
									'vulnerability-child-labour'
										? 'active show'
										: '',
								].join(' ')}
								id="home"
								role="tabpanel"
								aria-labelledby="home-tab"
							>
								<VulnerabilityToChildLabor state={state} />
							</div>

							<div
								className={[
									'tab-pane fade',
									location.pathname.split('/')[3] ===
									'vulnerability-dimension'
										? 'active show'
										: '',
								].join(' ')}
								id="profile"
								role="tabpanel"
								aria-labelledby="profile-tab"
							>
								<VulneravilityDimensions state={state} />
							</div>
							<div
								className={[
									'tab-pane fade',
									location.pathname.split('/')[3] ===
									'complementary-factors'
										? 'active show'
										: '',
								].join(' ')}
								id="contact2"
								role="tabpanel"
								aria-labelledby="contact-tab"
							>
								<div className="content">
									<ComplementaryFactors state={state} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default React.memo(Cantones);
