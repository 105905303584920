import React from 'react';
import GroupList from '../views/mapsHelpers/groupList/groupList';

export default function LikehoodGroups({ child_labour_likelihood }) {
	return (
		<React.Fragment>
			<div
				className="text-justify"
				dangerouslySetInnerHTML={{
					__html: child_labour_likelihood.description[0],
				}}
			></div>

			<GroupList groups={child_labour_likelihood.groups} />

			<div
			className="text-justify"
				dangerouslySetInnerHTML={{
					__html: child_labour_likelihood.description[1],
				}}
			></div>
			<div
			className="text-justify"
				dangerouslySetInnerHTML={{
					__html: child_labour_likelihood.description[2],
				}}
			></div>
		</React.Fragment>
	);
}
