import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styles from './mexico.module.css';
import {
	groupBullet,
	rangeHelper,
} from '../../../mapsHelpers/costaRicaTooltipHelper/costaRicaTooltiphelper';

export default function Tooltip({ hoverStateId, tooltipRef, groups }) {
	return (
		<TransitionGroup>
			{hoverStateId && (
				<CSSTransition
					in={hoverStateId !== null}
					timeout={200}
					classNames="my-node"
				>
					<div className={styles.Tooltip} ref={tooltipRef}>
						<h4 className="mb-0">{hoverStateId[1]?.name}</h4>
						<p style={{ margin: '2px' }}>
							<small className="mb-2">
								{groupBullet(groups[0])} {groups[0].text}
								{rangeHelper(hoverStateId[1].info[0].range)}
							</small>
						</p>
						<p style={{ margin: '2px' }}>
							<small className="mb-2">
								{groupBullet(groups[1])} {groups[1].text}
								{rangeHelper(hoverStateId[1].info[1].range)}
							</small>
						</p>
						<p style={{ margin: '2px' }}>
							<small className="mb-2">
								{groupBullet(groups[2])} {groups[2].text}
								{rangeHelper(hoverStateId[1].info[2].range)}
							</small>
						</p>
					</div>
				</CSSTransition>
			)}
		</TransitionGroup>
	);
}
