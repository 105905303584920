import React from 'react'

export const SvgLatamMap = ({locations, groups, mapRef, language, svhHoverHandler, svgClickHandler, svhblurHandler}) => {
    return (
        <svg
        className="FadeIn"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 689.91 726.16"
    >
        {locations.map(location => {
            return (
                <g
                    data-status={location.status}
                    id={location.id}
                    key={location.id}
                    onMouseEnter={e =>
                        svhHoverHandler(
                            e,
                            location.hideName
                        )
                    }
                    onClick={e =>
                        svgClickHandler(
                            e,
                            location.hideName
                        )
                    }
                    onAnimationEnd={svhblurHandler}
                    className="g"
                >
                    {location.paths.map(
                        (path, i) => {
                            if (
                                path.transform &&
                                !location.hideName
                            ) {
                                return (
                                    <text
                                        key={
                                            path.transform
                                        }
                                        transform={
                                            path.transform
                                        }
                                        style={{
                                            fontSize:
                                                '20px',
                                            fontFamily:
                                                'font-family: Nunito-Bold, Nunito;font-weight: 700',
                                        }}
                                        className="text-svg"
                                    >
                                        {
                                            location
                                                .name
                                        }
                                    </text>
                                );
                            }

                            if (path.points) {
                                return (
                                    <polygon
                                        points={
                                            path.points
                                        }
                                        style={{
                                            fill:
                                                location.fill,
                                        }}
                                    />
                                );
                            }

                            return (
                                <path
                                    id={path.id}
                                    key={
                                        path.id + i
                                    }
                                    stroke={
                                        groups
                                            ?.rows[0]
                                            .groups[0]
                                            .stroke
                                    }
                                    strokeWidth={
                                        groups
                                            ?.rows[0]
                                            .groups[0]
                                            .stroke_width
                                    }
                                    fill={
                                        location.fill
                                    }
                                    d={path.d}
                                />
                            );
                        }
                    )}
                </g>
            );
        })}

        <use
            href=""
            ref={mapRef}
            onClick={svgClickHandler}
        />
    </svg>
    )
}
