import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../../../../../context/Languages';
import SharedButtons from '../../../../mapsHelpers/sharedbuttons/sharedButtons';
import client from '../../../../../api/client';

// hooks
import { useHttpArgentina } from '../../../../../hooks/argentina/httpArgentina';

//maps helpers
import DownloadList from '../../../../mapsHelpers/downloadList/downloadList';

// css module
import styles from './../mexico.module.css';
import './states.css';

import useStatematch from '../../../../../hooks/argentina/svgmatch';
import MapsSpinner from '../../../../mapsHelpers/mapsSpinner/mapsSpinner';
import FactorsGroups from '../../../../mapsHelpers/complementaryFactorsGroups/FactorsGroups';

export default function ComplementaryFactors({ state }) {
	
	
	let location = useLocation();
	const { language } = useLanguage();
	const [isLoadingChildLabour, dataChildLabour] = useHttpArgentina(
		`get_tab_argentina/${state}`,
		{tabcode:'child_labour_likelihood', lang:language?.lang},
		true,
		[state, language?.lang],
		true
	);
	const [isLoading, dataComplementaryFactors] = useHttpArgentina(
		`get_tab_argentina/${state}`,
		{tabcode:'complementary_factors', lang:language?.lang},
		true,
		[state, language?.lang],
		true
	);
	const mapChildLabour = useStatematch(
		dataChildLabour,
		'child_labour_likelihood'
	);
	const maprelativeChildLabour = useStatematch(
		dataComplementaryFactors,
		'complementary_factors'
	);


	return (
		<React.Fragment>
			<div className="row">
				<div
					className="col-12 text-justify"
					dangerouslySetInnerHTML={{
						__html:
						dataComplementaryFactors &&
						dataComplementaryFactors[0].province[0].tabs.complementary_factors
								.description[0],
					}}
				></div> 
			</div>

			{(dataComplementaryFactors && dataComplementaryFactors[0].departments[0].data.complementary_factors) && <div className="row">
				<div className="col-sm-12 col-md-12 col-lg-6">
					<div
						className="map-card "
						style={{
							margin: 'auto',
							position: 'relative',
						}}
					>
						<p className="text-center mb-0">
							<strong>
							{dataComplementaryFactors &&
								dataComplementaryFactors[0].province[0].tabs.complementary_factors.title }
							</strong>
						</p>

						{isLoadingChildLabour ? (
							<MapsSpinner />
						) : (
							maprelativeChildLabour && (
								<svg
									className={styles.FadeIn}
									xmlns="http://www.w3.org/2000/svg"
									viewBox="-150 -50 1200 903"
								>
									{maprelativeChildLabour.map(location => {
										return (
											<g
												id={location.id}
												key={location.id}
												transform={location.transform}
											>
												{location.paths.map(
													(path, i) => (
														<path
															key={path.id + i}
															id={path.id}
															stroke={
																location.stroke
															}
															strokeWidth={
																location.stroke_width
															}
															fill={location.fill}
															d={path.d}
															transform={
																path.transform
															}
														/>
													)
												)}
											</g>
										);
									})}
								</svg>
							)
						)}
						<FactorsGroups
							groups={
								dataComplementaryFactors &&
								dataComplementaryFactors[0].province[0].tabs
									.complementary_factors.groups
							}
						/>
					</div>
				</div>
				<div className="col-sm-12 col-md-12 col-lg-6">
					<div
						className="map-card "
						style={{
							margin: 'auto',
							position: 'relative',
						}}
					>
						<p className="text-center">
							<strong>
							{dataComplementaryFactors &&
								dataComplementaryFactors[0].province[0].tabs.complementary_factors.second_title }
							</strong>
						</p>

						{isLoadingChildLabour ? (
							<MapsSpinner />
						) : (
							mapChildLabour && (
								<svg
									className={styles.FadeIn}
									xmlns="http://www.w3.org/2000/svg"
									viewBox="-150 -50 1200 903"
								>
									{mapChildLabour.map(location => {
										return (
											<g
												id={location.id}
												key={location.id}
												transform={location.transform}
											>
												{location.paths.map(
													(path, i) => (
														<path
															key={path.id + i}
															id={path.id}
															stroke={
																location.stroke
															}
															strokeWidth={
																location.stroke_width
															}
															fill={location.fill}
															d={path.d}
															transform={
																path.transform
															}
														/>
													)
												)}
											</g>
										);
									})}
								</svg>
							)
						)}
						<FactorsGroups
							groups={
								dataChildLabour &&
								dataChildLabour[0].province[0].tabs
									.child_labour_likelihood.groups
							}
						/>
					</div>
				</div>
			</div>}

			<div class="row mt-3">
				<div class="col-12 col-lg-7">
					<div class="table-responsive">
						<table class="table oit-table">
							<thead>
								<tr>
									{dataComplementaryFactors &&
										dataComplementaryFactors[0].province[0].tabs.complementary_factors.table.headers.map(
											(header, index) => {
												return (
													<th
														key={
															header.description +
															index
														}
														style={{
															'--w': `${
																index === 0
																	? '80%'
																	: ''
															}`,
														}}
													>
														{header.description}{' '}
														<br />{' '}
														{header.value &&
															`(${header.value})`}
													</th>
												);
											}
										)}
								</tr>
							</thead>
							<tbody>
								{dataComplementaryFactors &&
									dataComplementaryFactors[0].province[0].tabs.complementary_factors.table.data.map(
										(dataField, index) => {
											return (
												<tr
													key={
														dataField.description +
														index
													}
												>
													<td>
														{dataField.description}
													</td>
													{dataField.values.map(
														(field, index) => {
															if (!field) {
																return (
																	<td></td>
																);
															}
															return (
																<td
																	key={
																		dataField._id +
																		index
																	}
																>
																	{
																		Number.parseFloat(field.$numberDecimal).toFixed(1)
																	}
																</td>
															);
														}
													)}
												</tr>
											);
										}
									)}
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-12 col-lg-5">
					<div className="text-justify"
						dangerouslySetInnerHTML={{
							__html:
								dataComplementaryFactors &&
								dataComplementaryFactors[0].province[0].tabs.complementary_factors
									.description[1],
						}}
					></div>
					<div class="more-detail">
						<div class="row w-100">
							<div class="col-6 p-0"></div>
							<div class="col-6 p-0">
								<div class="d-table ml-auto h-100">
									<div class="d-table-cell">
										<div class="share h-100">
											<p class="text">
												{
													language?.text.body
														.share_information
												}
											</p>
											<div class="oit-icon-group oit-icon-group-small icons-green">
												<SharedButtons
													url={`${location.pathname}`}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<DownloadList
							links={
								dataComplementaryFactors &&
								dataComplementaryFactors[0].province[0].tabs.complementary_factors
									.more_descriptions.links
							}
							url={null}
						/>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
