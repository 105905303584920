import React from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from './../../../../context/Languages';

// custon hooks
import { useHttp } from '../../../../hooks/http';

// maps helpers
import SharedButtons from '../../../mapsHelpers/sharedbuttons/sharedButtons';
import DownloadList from '../../../mapsHelpers/downloadList/downloadList';

// components

const RiskFactors = () => {
	const { language } = useLanguage();
	let location = useLocation();
	const [isLoading, data] = useHttp(
		'get_tab_guatemala/',
		{ tabcode: 'risk_factors', lang: language?.lang },

		false,
		[language?.lang]
	);
	return (
		<div className="content">
			<div className="row">
				<div className="col-12 col-lg-7">
					<div className="table-responsive">
						<table className="table oit-table table-bars">
							<thead>
								<tr>
									{data?.config.tabs.risk_factors.table.headers.subtitles.map(
										(subheader, index) => {
											return (
												<th
													scope="col"
													key={subheader._id}
												>
													{subheader.description}
												</th>
											);
										}
									)}
								</tr>
							</thead>
							<tbody>
								<tr></tr>
								{data &&
									data?.config.tabs.risk_factors.table.data
									.sort(function (a, b) {
										return (
											Number(
												b?.odds_ratio
													?.$numberDecimal
											) -
											Number(
												a?.odds_ratio
													?.$numberDecimal
											)
										);
									}).map(
										(dataField, index, array) => {
											let odds = array.reduce(
												(a, b) =>
													a +
													(+b.odds_ratio
														?.$numberDecimal |
														0),
												0
											);
											let width =
												(dataField.odds_ratio
													?.$numberDecimal *
													100) /
												odds;
											return (
												<tr key={dataField._id}>
													<td>
														{dataField.variable}
													</td>
													<td>
														<span
															className="text-danger font-weight-bolder"
															style={{
																fontSize:
																	'1rem',
															}}
														>
															{dataField.effect}
														</span>
													</td>
													<td>
														{dataField.odds_ratio?.$numberDecimal.slice(
															0,
															3
														)}
													</td>
													<td>
														{dataField.odds_ratio && (
															<div className="progress">
																<div
																	className="progress-bar"
																	role="progressbar"
																	style={{
																		width: width,
																		backgroundColor:
																			dataField.color,
																	}}
																	aria-valuenow="15"
																	aria-valuemin="0"
																	aria-valuemax="247"
																></div>
															</div>
														)}
													</td>
												</tr>
											);
										}
									)}
							</tbody>
						</table>
					</div>
					<div
						className="col-12 text-justify p-lg-0"
						dangerouslySetInnerHTML={{
							__html: data?.config.tabs.risk_factors
								.description[1],
						}}
					></div>
				</div>
				<div className="col-12 col-lg-5">
					<div
						className="col-12 text-justify"
						dangerouslySetInnerHTML={{
							__html: data?.config.tabs.risk_factors
								.description[0],
						}}
					></div>
					<div
						className="col-12 text-justify p-lg-0"
						dangerouslySetInnerHTML={{
							__html: data?.config.tabs.risk_factors
								.description[2],
						}}
					></div>

					<div className="more-detail">
						<div className="row w-100">
							<div className="col-6 ml-auto p-0">
								<div className="d-table h-100">
									<div className="d-table-cell">
										<div className="share h-100">
											<p className="text">
												{
													language?.text.body
														.share_information
												}
											</p>
											<div className="oit-icon-group oit-icon-group-small icons-green">
												<SharedButtons
													url={`${location.pathname}`}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<DownloadList
							links={
								data?.config.tabs.risk_factors.more_descriptions
									.links
							}
							url={null}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RiskFactors;
