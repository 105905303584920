import React, { useEffect } from 'react';
import ChildLabour from './ChildLabour';
import ChildLabourLikelihood from './ChildLabourLikelihood';
import RiskFactors from './RiskFactors';
import ComplementaryFactors from './complementaryFactors';
import { useLanguage } from '../../../../../context/Languages';

import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';

import { Link, useParams } from 'react-router-dom';
import { useGroups } from '../../../../../context/Groups';

const States = () => {
	let history = useHistory();
	const { language } = useLanguage();
	let location = useLocation();

	const { setAstate, selectedState, countrystates } = useGroups();
	let { state } = useParams();

	const clickHandler = path => {
		history.push(`/guatemala/${state}/${path}`);
	};

	//set active state when the list is loaded
	useEffect(() => {
		console.log('setAstate', setAstate)
		setAstate(state);
	}, [state, countrystates]);

	// set to null when unmount
	useEffect(() => {
		return () => {
			setAstate(null);
		};
	}, []);

	return (
		<div className="city">
			<div className="row nav container">
				<div className="col-12 col-md-2 col-sm-4 text-dark left">
					<Link to="/guatemala" className="back-button">
						<i className="fas fa-arrow-left"></i> &nbsp;
						{language?.text.nav.back_main}
					</Link>
				</div>
				<div className="col-12 col-md-10 right mt-3 mt-md-0">
					<h3 className="title text-left text-md-right">
						{language?.text.model.MIRTI}
					</h3>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-12 tabs">
						<div className="row">
							<div
								className=" col-sm-12 col-md-12 col-lg-2 mb-md-2 tab-left text-center text-md-left"
								style={{ fontSize: '25px !important' }}
							>
								<span className="title">
									{(() => {
										if (selectedState) {
											let longitud = selectedState.split(
												' '
											);
											if (longitud.length > 3) {
												let newLongitud = longitud
													.slice(0, 3)
													.join(' ')
													.concat(' ...');
												return newLongitud;
											}
											return selectedState;
										}
									})()}
								</span>
							</div>
							<div className="col-sm-12 col-lg-10 tab-right">
								<ul
									className="d-none d-sm-flex nav nav-tabs"
									id="myTab"
									role="tablist"
								>
									{/* <li className="nav-item">
										<a
											onClick={() =>
												clickHandler('child-labour')
											}
											className={[
												'nav-link',
												location.pathname.split(
													'/'
												)[3] === 'child-labour'
													? 'active'
													: '',
											].join(' ')}
											id="child-labour-tab"
											data-toggle="tab"
											href="#child-labour"
											role="tab"
											aria-controls="child-labour"
											aria-selected="false"
										>
											{
												language?.text.tab.guatemala
													.country[0]
											}
										</a>
									</li>
									 */}
									<li className="nav-item">
										<a
											onClick={() =>
												clickHandler(
													'child-labour-likehood'
												)
											}
											className={[
												'nav-link',
												location.pathname.split(
													'/'
												)[3] === 'child-labour-likehood'
													? 'active'
													: '',
											].join(' ')}
											id="child-labour-likehood-tab"
											data-toggle="tab"
											href="#child-labour-likehood"
											role="tab"
											aria-controls="child-labour-likehood"
											aria-selected="true"
										>
											{
												language?.text.tab.guatemala
													.country[1]
											}
										</a>
									</li>
									<li className="nav-item">
										<a
											onClick={() =>
												clickHandler('risk-factors')
											}
											className={[
												'nav-link',
												location.pathname.split(
													'/'
												)[3] === 'risk-factors'
													? 'active'
													: '',
											].join(' ')}
											id="risk-factors-tab"
											data-toggle="tab"
											href="#risk-factors"
											role="tab"
											aria-controls="risk-factors"
											aria-selected="false"
										>
											{
												language?.text.tab.guatemala
													.country[2]
											}
										</a>
									</li>
									<li className="nav-item">
										<a
											onClick={() =>
												clickHandler(
													'associated-factors'
												)
											}
											className={[
												'nav-link',
												location.pathname.split(
													'/'
												)[3] === 'associated-factors'
													? 'active'
													: '',
											].join(' ')}
											id="complementary-factors-tab"
											data-toggle="tab"
											href="#complementary-factors"
											role="tab"
											aria-controls="complementary-factors"
											aria-selected="false"
										>
											{
												language?.text.tab.guatemala
													.country[3]
											}
										</a>
									</li>
								</ul>
								<div className="d-block d-sm-none btn-group btn-group-oit">
									<button
										type="button"
										className="btn btn-secondary dropdown-toggle w-100"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										Opciones
									</button>
									<div className="dropdown-menu">
										{/* <a
											onClick={() =>
												clickHandler('child-labour')
											}
											className={[
												'dropdown-item',
												location.pathname.split(
													'/'
												)[3] === 'child-labour'
													? 'active'
													: '',
											].join(' ')}
											id="child-labour"
											data-toggle="tab"
											href="#child-labour"
											role="tab"
											aria-controls="child-labour"
											aria-selected="false"
										>
											{
												language?.text.tab.guatemala
													.country[0]
											}
										</a>
										 */}
										<a
											onClick={() =>
												clickHandler(
													'child-labour-likehood'
												)
											}
											className={[
												'dropdown-item',
												location.pathname.split(
													'/'
												)[3] === 'child-labour-likehood'
													? 'active'
													: '',
											].join(' ')}
											id="child-labour-likehood-tab"
											data-toggle="tab"
											href="#child-labour-likehood"
											role="tab"
											aria-controls="child-labour-likehood"
											aria-selected="true"
										>
											{
												language?.text.tab.guatemala
													.country[1]
											}
										</a>
										<a
											onClick={() =>
												clickHandler('risk-factors')
											}
											className={[
												'dropdown-item',
												location.pathname.split(
													'/'
												)[3] === 'risk-factors'
													? 'active'
													: '',
											].join(' ')}
											id="risk-factors-tab"
											data-toggle="tab"
											href="#risk-factors"
											role="tab"
											aria-controls="risk-factors"
											aria-selected="false"
										>
											{
												language?.text.tab.guatemala
													.country[2]
											}
										</a>
										<a
											onClick={() =>
												clickHandler(
													'associated-factors'
												)
											}
											className={[
												'dropdown-item',
												location.pathname.split(
													'/'
												)[3] === 'associated-factors'
													? 'active'
													: '',
											].join(' ')}
											id="associated-factors-tab"
											data-toggle="tab"
											href="#associated-factors"
											role="tab"
											aria-controls="associated-factors"
											aria-selected="false"
										>
											{
												language?.text.tab.guatemala
													.country[3]
											}
										</a>
									</div>
								</div>
							</div>
						</div>

						<div
							className="tab-content content p-3 mt-5"
							id="myTabContent"
						>
							{/* <div
								className={[
									'tab-pane fade',
									location.pathname.split('/')[3] ===
									'child-labour'
										? 'active show'
										: '',
								].join(' ')}
								id="child_labour"
								role="tabpanel"
								aria-labelledby="child-labour-tab"
							>
								<ChildLabour state={state} />
							</div> */}

							<div
								className={[
									'tab-pane fade',
									location.pathname.split('/')[3] ===
									'child-labour-likehood'
										? 'active show'
										: '',
								].join(' ')}
								id="profile"
								role="tabpanel"
								aria-labelledby="profile-tab"
							>
								<ChildLabourLikelihood state={state} />
							</div>
							<div
								className={[
									'tab-pane fade',
									location.pathname.split('/')[3] ===
									'risk-factors'
										? 'active show'
										: '',
								].join(' ')}
								id="contact"
								role="tabpanel"
								aria-labelledby="contact-tab"
							>
								<RiskFactors state={state} />
							</div>
							<div
								className={[
									'tab-pane fade',
									location.pathname.split('/')[3] ===
									'associated-factors'
										? 'active show'
										: '',
								].join(' ')}
								id="contact2"
								role="tabpanel"
								aria-labelledby="contact-tab"
							>
								<div className="content">
									<ComplementaryFactors state={state} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default React.memo(States);
