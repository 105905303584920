import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../../../../../context/Languages';

// custon hooks
import { useHttp } from '../../../../../hooks/http';

import SharedButtons from '../../../../mapsHelpers/sharedbuttons/sharedButtons';
import DownloadList from '../../../../mapsHelpers/downloadList/downloadList';

const VulneravilityDimensions = ({ state }) => {
	const { language } = useLanguage();
	let location = useLocation();
	const [isLoading, data] = useHttp(
		`get_tab_chile/${state}`,
		{ tabcode: 'vulnerability_dimensions', lang: language?.lang },
		false,
		[state, language?.lang],
		true
	);

	return (
		<div className="content">
			<div className="row">
				<div className="source col-12">
					<div
						className="col-12 text-justify"
						dangerouslySetInnerHTML={{
							__html:
								data &&
								data[0].region[0].tabs.vulnerability_dimensions
									.description[0],
						}}
					></div>
				</div>
				<div className="col-12">
					<div className="table-responsive">
						<table className="table oit-table">
							<thead>
								<tr>
									{data &&
										data[0].region[0].tabs.vulnerability_dimensions.table.headers.map(
											(header, index) => {
												return (
													<th
														style={{
															padding: '1rem',
														}}
														key={header._id}
													>
														{header.description}
													</th>
												);
											}
										)}
								</tr>
							</thead>
							<tbody>
								{console.log('data', data)}
								{data &&
									data[0].region[0].data.vulnerability_dimensions.map(
										(dataField, i) => {
											console.log('dataField', dataField);
											let keys = [
												...Object.keys(dataField),
											].slice(1);
											const element2 = keys.splice(
												6,
												1
											)[0];
											const element5 = keys.splice(
												4,
												1
											)[0];
											keys.splice(1, 0, element2);
											keys.splice(4, 0, element5);
											let { groups } =
												data[0].region[0].tabs
													.child_labour_vulnerable;
											console.log('key', keys);
											return (
												<tr key={dataField._id}>
													{keys.map((key, index) => {
														if (index === 1) {
															return (
																<React.Fragment
																	key={
																		key +
																		index
																	}
																>
																	<td
																		style={{
																			padding:
																				'1rem',
																			textTransform:
																				'capitalize',
																		}}
																	>
																		<strong>
																			{
																				dataField.cantons
																			}
																		</strong>
																	</td>
																	{/* <td
																		style={{
																			textAlign:
																				'center',
																			verticalAlign:
																				'middle',
																		}}
																	>
																		<span
																			style={{
																				display:
																					'inline-block',
																				width: '10px',
																				height: '10px',
																				border: 'solid 1px #c2c2c2',

																				borderRadius:
																					'50%',
																				backgroundColor: `${
																					groups[
																						dataField[
																							key
																						] -
																							1
																					]
																						?.color
																				}`,
																			}}
																		></span>
																	</td> */}
																</React.Fragment>
															);
														}
														return (
															<td
																key={
																	key + index
																}
																style={{
																	textAlign:
																		'center',
																	verticalAlign:
																		'middle',
																}}
															>
																<span
																	style={{
																		display:
																			'inline-block',
																		width: '10px',
																		height: '10px',
																		borderRadius:
																			'50%',
																		border: 'solid 1px #c2c2c2',
																		backgroundColor: `${
																			groups[
																				dataField[
																					key
																				] -
																					1
																			]
																				?.color
																		}`,
																	}}
																></span>
															</td>
														);
													})}
												</tr>
											);
										}
									)}
							</tbody>
						</table>
					</div>
				</div>
				<div className="col-12 right">
					<div
						className="col-12 col-lg-6 mt-lg-4 "
						style={{
							marginTop: 0,
							padding: 5,

							border: '1px solid #D5D5D5',
							background: '#EEEEEE',
							borderRadius: 8,
						}}
					>
						<ul
							style={{
								display: 'flex',
								paddingLeft: '0',
								justifyContent: 'space-around',
								alignContent: 'center',
								marginBottom: '0',
							}}
						>
							{data &&
								data[0].region[0].tabs.child_labour_vulnerable.groups.map(
									(group, i) => {
										return (
											<li
												key={group._id}
												style={{
													'--color': group.color,
													paddingLeft: '35px',
													flex: ' 1 0 auto',
													position: 'relative',
													marginTop: 24,
												}}
											>
												<span
													style={{
														fontSize: '16px',
													}}
												>
													{group.label}
												</span>
											</li>
										);
									}
								)}
						</ul>
					</div>
					<div className="source col-12">
						<div
							className="col-12 text-justify"
							dangerouslySetInnerHTML={{
								__html:
									data &&
									data[0].region[0].tabs
										.vulnerability_dimensions
										.description[1],
							}}
						></div>
					</div>
					<div className="more-detail col-12 col-lg-6 p-0">
						<DownloadList
							links={
								data &&
								data[0].region[0].tabs.vulnerability_dimensions
									.more_descriptions.links
							}
						/>
					</div>
				</div>
				<div className="row w-100">
					<div className="col-12 p-0">
						<div className="d-table h-100 ml-auto">
							<div className="d-table-cell ">
								<div className="share h-100">
									<p className="text">
										{language?.text.body.share_information}
									</p>
									<div className="oit-icon-group oit-icon-group-small icons-green">
										<SharedButtons
											url={`${location.pathname}`}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VulneravilityDimensions;
