import React from 'react';

export default function RegularSvgMap({
	data,
	municipalities,
	svhblurHandler,
	svhHoverHandler,
	svgClickHandler,
}) {
	const clickHandler = e => {
		if (svgClickHandler) {
			svgClickHandler(e);
		}
	};
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox={data.map.viewBox}>
			{municipalities.map((location, i) => {
				return (
					<g
						id={location.id}
						key={location.id}
						style={{ cursor: 'pointer' }}
						transform={location.transform}
						fill={'transparent'}
						onMouseLeave={svhblurHandler}
						onClick={e =>
							clickHandler({ ...e, status: location.status })
						}
						onMouseEnter={e =>
							svhHoverHandler(e, {
								name: location.name,
								range: location.range,
								color: location.fill,
								group: location.group,
							})
						}
					>
						{location.paths.map((path, i) => (
							<path
								key={path.id + i}
								stroke={location.stroke}
								strokeWidth={location.stroke_width}
								fill={location.fill}
								d={path.d}
								transform={path.transform}
							/>
						))}
					</g>
				);
			})}
		</svg>
	);
}
