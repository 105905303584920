import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../../../../../context/Languages';

// custon hooks
import { useHttp } from '../../../../../hooks/http';

import SharedButtons from '../../../../mapsHelpers/sharedbuttons/sharedButtons';
import DownloadList from '../../../../mapsHelpers/downloadList/downloadList';

const VulneravilityDimensions = ({ state }) => {
	const { language } = useLanguage();
	let location = useLocation();
	const [isLoading, data] = useHttp(
		`get_tab_paraguay/${state}`,
		{tabcode:'vulnerability_dimensions', lang:language?.lang},

		true,
		[state,language?.lang],
		true
	);
	return (
		<div class="content">
			<div class="row">
				<div class="col-12 source">
						<div
							class=""
							dangerouslySetInnerHTML={{
								__html:
									data &&
									data[0].department[0].tabs
										.vulnerability_dimensions.description[0]
							}}
						></div>
				</div>

				<div class="col-12">
					<div class="table-responsive">
						<table class="table oit-table">
							<thead>
								<tr>
									{data &&
										data[0].department[0].tabs.vulnerability_dimensions.table.headers.map(
											(header, index) => {
												return (
													<th style={{padding:"1rem"}}>
														{header.description}
													</th>
												);
											}
										)}
								</tr>
							</thead>
							<tbody>
								{data &&
									data[0].department[0].data.vulnerability_dimensions.map(
										dataField => {
											let keys = Object.keys(
												dataField
											).slice(2);
											let {
												groups,
											} = data[0].department[0].tabs.child_labour_vulnerable;
											return (
												<tr key={dataField._id}>
													{keys.map((key, index) => {
														if (index === 0) {
															return (
																<React.Fragment>
																	<td>
																		<strong>
																			{
																				dataField.district
																			}
																		</strong>
																	</td>
																	<td
																		style={{
																			textAlign:
																				'center',
																			verticalAlign:
																				'middle',
																			padding:"1rem",
																		}}
																	>
																		<span
																			style={{
																				display:
																					'inline-block',
																				width:
																					'10px',
																				height:
																					'10px',
																				borderRadius:
																					'50%',
																				backgroundColor: `${
																					groups[
																						dataField[
																							key
																						] -
																							1
																					]
																						?.color
																				}`,
																			}}
																		></span>
																	</td>
																</React.Fragment>
															);
														}
														return (
															<td
																style={{
																	textAlign:
																		'center',
																	verticalAlign:
																		'middle',
																}}
															>
																<span
																	style={{
																		display:
																			'inline-block',
																		width:
																			'10px',
																		height:
																			'10px',
																		borderRadius:
																			'50%',
																		backgroundColor: `${
																			groups[
																				dataField[
																					key
																				] -
																					1
																			]
																				?.color
																		}`,
																	}}
																></span>
															</td>
														);
													})}
												</tr>
											);
										}
									)}
							</tbody>
						</table>
					</div>
				</div>
				
				<div class="col-12 right">
					<div
						className="col-12 col-lg-6 mt-lg-4 "
						style={{
							marginTop: 0,
							padding: 5,
							border: '1px solid #D5D5D5',
							background: '#EEEEEE',
							borderRadius: 8,
						}}
					>
						<ul
							style={{
								display: 'flex',
								justifyContent: 'space-around',
								alignContent: 'center',
								marginLeft: '-30px',
								marginBottom: '0',
							}}
						>
							{data &&
								data[0].department[0].tabs.child_labour_vulnerable.groups.map(
									group => {
										return (
											<li
												key={group._id}
												style={{
													'--color': group.color,
													paddingLeft: '35px',
													flex: ' 1 0 auto',
													position: 'relative',
													marginTop: 24,
												}}
											>
												<span style={{
														fontSize:"16px"
													}}>{group.label}</span>
											</li>
										);
									}
								)}
						</ul>
					</div>
					<div class="col-12 source">
						<div
							class=""
							dangerouslySetInnerHTML={{
								__html:
									data &&
									data[0].department[0].tabs
										.vulnerability_dimensions.description[1]
							}}
						></div>
				</div>
					<div class="more-detail col-12 col-lg-6 p-0">
						<DownloadList
							links={
								data &&
								data[0].department[0].tabs
									.vulnerability_dimensions.more_descriptions
									.links
							}
						/>

						
					</div>
				</div>
				
					<div class="row w-100">
							<div class="col-12 p-0">
								<div class="ml-auto d-table h-100">
									<div class="d-table-cell">
										<div class="share h-100">
											<p class="text">
												{
													language?.text.body
														.share_information
												}
											</p>
											<div class="oit-icon-group oit-icon-group-small icons-green">
												<SharedButtons
													url={`${location.pathname}`}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
					</div>
			</div>
		</div>
	);
};

export default VulneravilityDimensions;
