import React from 'react';
import styles from './mexico.module.css';

function LikehoodMap({
	locations,
	viewBox,
	svhHoverHandler,
	svhblurHandler,
	svgClickHandler,
}) {
	console.log("locations", locations)
	return (
		<svg
			className={styles.FadeIn}
			xmlns="http://www.w3.org/2000/svg"
			viewBox={viewBox}
		>
			{locations.map(location => {
				return (
					<g
						id={location.id}
						fill="white"
						key={location.id}
						style={{ cursor: 'pointer' }}
						onMouseLeave={svhblurHandler}
						onMouseEnter={e =>
							svhHoverHandler(e, {
								info: location.provinceInfoGroup,
								name: location.name,
							})
						}
						onClick={svgClickHandler}
					>
					{location.paths?.map(path => {
						// console.log("path", path.fill)
											return (
												<path
													key={path.id}
													id={path.id}
													d={path.d}
													fill={path.fill}
													stroke={
														location
															.stroke
													}
													strokeWidth={
														location
															.stroke_width
													}
												/>
											);
										})}

						
					</g>
				);
			})}
		</svg>
	);
}

export default React.memo(LikehoodMap, (propsPrev, nextProps) => {
	if (propsPrev.locations.length === nextProps.locations.length) {
		return true;
	}

	return false;
});
