import React from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from './../../../../context/Languages';

// custon hooks
import { useHttp } from '../../../../hooks/http';

// maps helpers
import SharedButtons from '../../../mapsHelpers/sharedbuttons/sharedButtons';
import MapsSpinner from '../../../mapsHelpers/mapsSpinner/mapsSpinner';
import MapsTextSkeleton from '../../../mapsHelpers/mapsTextSkeleton/mapsTextSkeleton';

import mapsSpinner from '../../../mapsHelpers/mapsSpinner/mapsSpinner';
import DownloadList from '../../../mapsHelpers/downloadList/downloadList';
const Complementaryfactors = () => {
	const { language } = useLanguage();
	let location = useLocation();
	const [isLoading, data] = useHttp(
		'get_tab_chile/',
		{tabcode:'complementary_factors', lang:language?.lang},
		false,
		[language?.lang]
	);

	return (
		<div className="content">
			<div className="row">
				<div className="col-lg-7">
					{isLoading ? (
						<MapsSpinner />
					) : (
						<div className="table-responsive">
							<table className="table oit-table">
								<thead>
									<tr>
										{data?.config.tabs.complementary_factors.table.headers.map(
											(headerField, index) => {
												return (
													<th
														key={headerField._id}
														style={{
															color: 'black',
														}}
													>
														{
															headerField.description
														}{' '}
														<br />{' '}
														{headerField.value &&
															`(${headerField.value})`}
													</th>
												);
											}
										)}
									</tr>
								</thead>
								<tbody>
									{data?.config.tabs.complementary_factors.table.data.map(
										(dataField, index) => {
											return (
												<tr key={dataField._id}>
													<td>
														{dataField.description}
													</td>
													{dataField.values.map(
														(value, index) => (
															<td
																key={
																	value?.$numberDecimal +
																	index
																}
															>
																{value?.$numberDecimal
																	? value?.$numberDecimal
																	: ''}
															</td>
														)
													)}
												</tr>
											);
										}
									)}
								</tbody>
							</table>
						</div>
					)}
				</div>
				<div className="col-lg-5">
					{isLoading ? (
						<MapsTextSkeleton title={true} count={6} />
					) : (
						<React.Fragment>
							<div
								className="text-justify"
								dangerouslySetInnerHTML={{
									__html:
										data?.config.tabs.complementary_factors
											.description[0],
								}}
							></div>

							<div className="more-detail">
								<div className="row w-100">
									<div className="col-6 p-0"></div>
									<div className="col-6 p-0">
										<div className="d-table ml-auto h-100">
											<div className="d-table-cell">
												<div className="share h-100">
													<p className="text">
														{
															language?.text.body
																.share_information
														}
													</p>
													<div className="oit-icon-group oit-icon-group-small icons-green">
														<SharedButtons
															url={`${location.pathname}`}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<DownloadList
									links={
										data?.config.tabs.complementary_factors
											.more_descriptions.links
									}
								/>
							</div>
						</React.Fragment>
					)}
				</div>
			</div>
		</div>
	);
};

export default Complementaryfactors;
