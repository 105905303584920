import React from 'react';

export default function clusterMap({ data, language }) {
	if (!data) {
		return null;
	}
	return (
		<div
			className="modal p-0 fade sidebar-modal"
			id="exampleModalLong2"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="exampleModalLongTitle"
			aria-hidden="true"
		>
			<div className="modal-dialog m-0 ml-auto" role="document">
				<div className="modal-content">
					<div className="modal-body">
						<div>
							<div className="row nav">
								<div className="col-sm-4 left p-0">
									<a
										data-dismiss="modal"
										href="index.html"
										className="back-button"
									>
										<i className="fas fa-arrow-left"></i>
										{" "}{language.text.button.cluster_map_button}
									</a>
								</div>
								<div className="col-sm-8 right p-0"></div>
							</div>

							<div className="content">
								<div className="row">
									<div className="col-12">
										<h1 className="content-map-title">
										{language.text.button.cluster_map}
										</h1>
									</div>
									<div className="col-sm-7 left">
										<div className="content-map">
											<img
												className="w-100 mapa mb-3"
												src={data.image}
												alt="cluster"
											/>
										</div>
									</div>
									<div
										className="col-sm-5 right text-justify"
										dangerouslySetInnerHTML={{
											__html: data.description,
										}}
									></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
