import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
	rangeHelper,
	groupBullet,
} from '../../../mapsHelpers/costaRicaTooltipHelper/costaRicaTooltiphelper';

// css module
import styles from './costarica.module.css';

export default function NationalTooltip({ hoverStateId, tooltipRef, data }) {
	return (
		<TransitionGroup>
			{hoverStateId && (
				<CSSTransition
					in={hoverStateId !== null}
					timeout={200}
					classNames="my-node"
				>
					<div className={styles.Tooltip} ref={tooltipRef}>
						<h4 className="mb-2">
							<strong>{hoverStateId[1].name}</strong>
						</h4>
						{hoverStateId[1].info.map((element, i) =>{
							if(i === 6 && element.amount === 0) return;
							return (
								<p style={{ margin: '2px' }}>
							<small className="mb-2">
								{groupBullet(
									data?.config.tabs.child_labour_vulnerable
										.groups[i]
								)}{' '}
								
								{element.text} {element.amount}
							</small>
						</p>
							)
						})}
					</div>
				</CSSTransition>
			)}
		</TransitionGroup>
	);
}
